$icon-font: 'tv-de-schakel';
$default-font: 'Source Sans Pro', Arial, Helvetica, sans-serif !default;
$alternative-font: 'Merriweather', 'Palatino Linotype', 'Book Antiqua', Palatino, serif !default;

$fw-extra-light: 200;
$fw-light: 400;
$fw-regular: 400;
$fw-semibold: 600;
$fw-bold: 700;
$fw-black: 900;

$base-font-size: 15px;
$base-line-height: 1.55;
$header-line-height: 1.3;
$text-intro-weight: $fw-semibold !default;
$text-intro-size: 17px !default;
$text-intro-line-height: 1.5 !default;

$small-base-font-size: 13px;
$small-base-line-height: 1.6;
$small-header-line-height: 1.3;
$small-text-intro-weight: $fw-semibold !default;
$small-text-intro-size: 15px !default;
$small-text-intro-line-height: 1.5 !default;

$ul-icon-size: 1.3em;

$header-size: (
  h1: 40,
  h2: 28,
  h3: 22,
  h4: 20,
  h5: 18,
  h6: 16,
);
$small-header-size: (
  h1: 28,
  h2: 21,
  h3: 20,
  h4: 18,
  h5: 16,
  h6: 16,
);

$header-font-family: $alternative-font !default;
$header-font-weight: $fw-regular !default;
$header-font-style: normal !default;

$body-font-family: $default-font !default;
$body-font-weight: $fw-regular !default;
$body-font-style: normal !default;

$link-text-decoration: none !default;
$link-text-hover-decoration: underline !default;
$link-text-weight: $fw-regular !default;
