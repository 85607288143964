@include breakpoint(medium, 'up') {
  .top-menu .region-top-menu,
  .hoofdmenu {
    @include transition(transform);
  }

  .logo-column {
    @include transition(width);
  }

  .header {
    @include transition(padding, height, border-bottom-color, background-color);
    border-bottom: 1px solid transparent;
  }

  .scrolled {
    .top-menu .region-top-menu {
      transform: translateY(-200%);
    }
    .hoofdmenu {
      transform: translateY(-55%);
    }

    .logo-column {
      width: 160px;
    }

    .header {
      padding: 5px 0 10px;
      height: 50px;
      border-bottom-color: $green-light;
    }
  }
}
