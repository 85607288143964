// Base colors
$react-green: #55a514 !default;
$white: #fff !default;
$black: #000 !default;

$green: #527145 !default;
// $green-gray: #bdc6b5 !default;
$green-gray: #eef0ec !default;
$green-dark-gray: rgba(82,113,69,0.4) !default;
$green-medium: #8dac7f !default;
$green-light: #f1fbd3 !default;
$red: #c60f13 !default;
$red-dark: #A53441 !default;
$red-light: #CF8888 !default;
$blue: #94b8d1 !default;
$blue-dark: #396B8d !default;
$blue-blue: #579bd9 !default;
$orange: #e3a26b !default;

$gray: #efefef !default;
$gray-light: #f0f0f0 !default;
$gray-dark: #e6edf2 !default;
$gray-darker: #73838f !default;

$primary-color: $green !default;
$secondary-color: $blue !default;
$tertiary-color: $gray !default;

// Assign colors
// - Typography
$text-color: $black !default;
$text-intro-color: $black !default;
$header-font-color: $primary-color !default;
$body-font-color: $text-color !default;

$footer-header-color: $gray-darker !default;
$footer-text-color: #333 !default;
$footer-link-color: $footer-text-color !default;

// - Admin
$admin-menu-background: $react-green !default;
$admin-menu-background-active: lighten($admin-menu-background, 5%) !default;
$admin-menu-background-hover: #333 !default;
$admin-menu-color: $white !default;
$admin-submenu-background: #333 !default;
$admin-submenu-color: $white !default;
$user-login-background: $gray !default;
$user-login-border: 1px solid $gray-dark !default;

// - Links
$link-color: $primary-color !default;
$link-color-hover: $link-color !default;
$link-color-active: $link-color !default;

// - Menu-items
$menu-link-color: $text-color !default;
$menu-link-color-hover: $primary-color !default;
$menu-link-color-active: $menu-link-color-hover !default;

// - Buttons
$button-color: $blue !default;
$button-color-hover: $orange !default;
$button-color-active: $green-medium !default;

// - Messages
$alert-color: $red !default;
$warning-color: #f08a24 !default;
$success-color: $green !default;

// - Social
$facebook-color: #3b5998 !default;
$twitter-color: #55acee !default;
$linkedin-color: #0077b5 !default;
$googleplus-color: #dd4b39 !default;
$pinterest-color: #cc2127 !default;
$youtube-color: #cd201f !default;
