//================================================
//=== SEARCH RESULTS =============================
//================================================
.page-zoeken {
  background: $green-gray;

  .content form {
    @extend %clearfix;

    background-color: $white;
    padding: 2em 1em;
    margin-bottom: 4em;

    .form-item {
      margin: 0;
      width: calc(100% - 160px - 20px);
      float: left;
      clear: none;
      vertical-align: middle;

      label {
        display: none;
      }

      .form-text {
        margin: 0;
      }
    }

    .form-submit {
      float: right;
      clear: none;
      vertical-align: middle;
      margin: 0;
      font-size: 18px;
    }
  }

  .breadcrumbs {
    .easy-breadcrumb_segment-separator,
    .easy-breadcrumb_segment {
      &:last-of-type {
        display: none;
      }
    }
  }
}

.search-results {
  margin: 0 !important;
}

.search-info {
  display: none;
}

.search-result {
  background-color: $white;
  padding: 2em 1em;
  margin-bottom: 10px !important;
}

.search-results .search-snippet-info {
  padding-left: 0;
  font-size: 13px;

  p {
    font-size: inherit;
  }
}

.search-api-page-results {
  float: left;
  width: 100%;

  h2 + ul {
    display: none;
  }
}
