@charset 'UTF-8';

@font-face {
  font-family: $icon-font;
  src:url('fonts/#{$icon-font}.eot');
  src:url('fonts/#{$icon-font}.eot?#iefix') format('embedded-opentype'),
    url('fonts/#{$icon-font}.woff') format('woff'),
    url('fonts/#{$icon-font}.ttf') format('truetype'),
    url('fonts/#{$icon-font}.svg##{$icon-font}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  content: attr(data-icon);
}

[data-icon]:before,
[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: $icon-font !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // WARNING: Do not overwrite!
  vertical-align: -15%;
}


// When updating the icon font, only change the lines below:
// =========================================================

.icon-lock:before {
  content: "\e000";
}
.icon-search:before {
  content: "\e001";
}
.icon-react-online-icon:before {
  content: "\e002";
}
.icon-arrow-right:before {
  content: "\e003";
}
.icon-back:before {
  content: "\e004";
}
.icon-quote:before {
  content: "\e005";
}
