//================================================
////=== FOOTER =====================================
//================================================
.footer {
  margin: 100px 0 0;
  padding: 60px 0;
  // border-top: 1px solid $gray-dark;
  background: $primary-color;

  @include breakpoint(medium) {
    margin-top: 0;
  }

  a,
  p {
    color: $footer-text-color;
    padding: 0;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $footer-header-color;
    font-size: 16px;
  }

  ul {
    list-style-position: inside;
    list-style-type: none;

    li {
      color: $footer-text-color;

      a {
        color: $footer-link-color;

        &:before {
          display: inline-block;
          margin-right: .3em;
          text-decoration: none;
        }

        &.facebook:before {
          @include extend-icon('facebook');
          color: $facebook-color;
        }

        &.twitter:before {
          @include extend-icon('twitter');
          color: $twitter-color;
        }

        &.linkedin:before {
          @include extend-icon('linkedin');
          color: $linkedin-color;
        }

        &.googleplus:before {
          @include extend-icon('googleplus');
          color: $googleplus-color;
        }

        &.pinterest:before {
          @include extend-icon('pinterest');
          color: $pinterest-color;
        }

        &.youtube:before {
          @include extend-icon('youtube');
          color: $youtube-color;
        }
      }
    }
  }
}

//Footer bottom
.footer-bottom {
  padding: 5px 0 65px;
  background: $white;

  @include breakpoint(medium) {
    padding-bottom: 20px;
  }

  .block {
    margin: 0;
  }

  ul {
    margin: 7px 0 0;
    list-style-position: inside;

    li {
      list-style-type: none;
      margin-left: 2em;
      display: inline-block;

      &:first-child {
        margin: 0;
        padding: 0;
      }

      a,
      a.active {
        color: $gray-darker;
        font-weight: $fw-light;
      }

      a:hover {
        text-decoration: underline;
      }

    }
  }
}

//Home only
.front .footer {
  border: 0;
}

.react-online {
  color: $gray-darker;
  font-size: 20px;
  float: right;
  display: inline-block;

  &:hover {
    color: $react-green;
    text-decoration: none;
  }
}

@include breakpoint(medium) {
  .footer {
    h3,
    h4 {
      padding-bottom: 0;
    }

    .region-footerblok-1 ul li {
      width: 100%;
    }
  }

  .footer-bottom {
    .columns.left {
      float: left;
    }

    .columns.right {
      float: right;
    }

    ul {
      li {
        float: none;
        display: block;
        padding: 0;
        // margin: 0 0 0 20px;
        margin: 0;
        border: 0;

        a {
          line-height: 24px;
        }
      }
    }
  }

  //React logo
  .react-online-media {
    text-indent: -9999px;
    line-height: 30px;
  }

  span.icon-rnm-react {
    text-indent: 0;
  }
}

/*Standaard styling Drupal overwrite*/
.footer-bottom .menu__item.is-leaf,
.footer-bottom ul li a {
  list-style-image: none;
  list-style-type: none;
}

// .footer .region-footerblok-1 .entity-bean .field-item p {
//   // height: 82px;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//
//   img {
//     display: inline-block;
//     vertical-align: middle;
//     // margin-right: 20px;
//     // max-width: 20%;
//
//     // @include breakpoint(small) {
//     //   margin-right: 5px;
//     // }
//     height: auto !important;
//     max-height: 80px;
//     width: auto !important;
//   }
//
//   @include breakpoint(medium) {
//     margin-bottom: 20px;
//   }
// }

.footer .footer-block-half {
  width: 50% !important;
  float: left !important;
}
