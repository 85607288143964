//================================================
//=== HEADER =====================================
//================================================
.content-top {
  margin-top: 0;
}

.header {
  padding: 20px 0 10px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 110;
  background-color: $white;
  height: $header-height;

  @include breakpoint(medium) {
    height: auto;
    padding: 10px 0 20px;
  }
} 

#page {
  padding-top: $header-height;

  .slider {
    margin-top: -$header-height;

    @include breakpoint(medium) {
      margin-top: 0;
    }
  }
}

.logo {
  position: relative;

  &,
  a,
  img {
    display: block;
  }

  .logo-img {
    opacity: 1;
  }

  .logo-img-silhouette {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
  }
}

// Zoekveld
.block.zoekveld {
  #search-api-page-search-form-search, 
  #search-api-page-search-form-zoeken, 
  #search-api-page-search-form-search-engine {
    position: relative;
  }

  input[type='text'] {
    border: 1px solid $gray-dark;
    // border: 1px solid $white;
    box-shadow: none;
    border-radius: 0 $button-border-radius $button-border-radius 0;
    padding-left: 10px;
    padding-right: 35px;
    margin-bottom: 0;
    border-left: 0 !important;
    background-color: $white;
  }

  #edit-keys-1,
  #edit-keys-2,
  input.form-autocomplete {
    background-image: url('../images/ajax-loader.gif') right 50px no-repeat;
  }

  #edit-keys-1.throbbing,
  #edit-keys-2.throbbing,
  input.throbbing {
    background-position: 84% 1ll0px;
  }

  input[type='submit'] {
    font-family: $icon-font;
    border: 0;
    position: absolute;
    top: 0;
    right: 0;
    color: $primary-color;
    font-size: 20px;
    // background: $white;
    background: none;
    padding: 4px 13px;
    margin: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .form-text {
    float: right;
    min-width: 130px;
  }
}

.block.zoekveld input[type='text'],
.login-btn {
  padding-top: .3em;
  padding-bottom: .3em;
  font-size: 13px;
  font-weight: $fw-regular;
  display: inline-block;
  line-height: 1.5;
  height: 2.2em;

  .btn-text {
    line-height: inherit;
  }
}

.has-slider {
  .header {
    background-color: rgba(255, 255, 255, 0);
  }

  .logo {
    .logo-img {
      opacity: 0;
      @include transition(opacity);
    }

    .logo-img-silhouette {
      opacity: 1;
      @include transition(opacity);
    }
  }

  .block.zoekveld {
    input[type='text'] {
      border: 1px solid $white;
      @include transition(border-color);
    }
  }

  .region-top-menu a,
  .hoofdmenu ul li a {
    color: $white;
    @include transition(color);
  }

  .language-switcher .language.active a {
    color: rgba(255, 255, 255, 0.5) !important;
  }
}

#autocomplete {
  border: 1px solid $gray-dark;
  border-top: 0;
  width: 300px!important;
}

#autocomplete ul li {
  padding: 5px 10px;
  border-bottom: 1px solid $gray-dark;
}

#autocomplete ul li:hover {
  background: $gray-dark;
  cursor: pointer;
}

@include breakpoint(medium) {
  .header {
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:after {
        content: none;
      }
    }

    .logo-column {
      // float: left;
      width: auto;
      max-width: 60%;
    }

    .menus {
      // float: right;
      width: auto;
    }

    .top-menu,
    .mobile-menu-wrapper {
      display: inline-block;
    }
  }

  .login-btn span {
    display: none;
  }

  .login-btn {
    display: block;
    background: none;
    border: 0;
    border-radius: 0;
    color: $secondary-color !important;
    padding: 0;
    font-size: 25px;
    margin: 0 20px 0 0;
    height: auto;

    &:before {
      display: block;
      color: inherit;
      margin: 60% 0 0;
      padding: 0;
    }

    &:hover {
      background: none !important;
    }
  }

  .block.zoekveld input[type='text'] {
    float: none;
    padding-left: 1em;
    border-radius: $input-border-radius !important;
    border-left: 1px solid $gray-dark !important;
  }

  .language-switcher {
    text-align: center;

    .language {
      display: inline-block;

      a {
      }
    }
  }
}

.header-image-wrapper {
  @include aspect-ratio(1124, 540, 'header-image');
  max-height: 491px;
  overflow: hidden;
}
