//================================================
//=== FOTOALBUM ==================================
//================================================

/*OVERVIEW PAGINA*/
.fotoalbum-overview {
  // border-top: 1px solid #f1f1f1;
  // padding-top: 30px;
  // float: left;
  // width: 100%;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 0 15px;
      float: left;
      margin-bottom: 30px;
      width: 25%;
      list-style: none;

      a {
        overflow: hidden;
      }

      img {
        width: 100%;
        transition: all 0.4s ease;
      }

      &:hover img {
        transform: scale(1.1);
      }

    }
  }
}
@include breakpoint(large) {
  .fotoalbum-overview ul li,
  .fotoalbum-sectie ul li {
    width: 50%;
  }
}
@include breakpoint(medium) {
  .fotoalbum-sectie ul li:nth-child(odd) {
    padding-left: 0px !important;
  }

  .fotoalbum-sectie ul li:nth-child(even) {
    padding-right: 0px !important;
  }

  .sectie-item .fotoalbum .content {
    width: 100%!important;
  }

  .sectie-item .formulier .content {
    width: 100%!important;
  }

  .sectie-item .webform-component-fieldset {
    width: 100%!important;
  }

  .sectie-item .view-fotoalbum {
    width: 100%;
  }
}

.fotoalbum-overview .thumb.col a,
.fotoalbum-sectie .thumb.col a,
a.popup {
  position: relative;
  display: block;
  line-height: 0;
  overflow: hidden;

  &:hover {
    // opacity: .8;
    img {
      transform: scale(1.1);
    }
  }

  img {
    position: relative;
    z-index: 0;
    transform: scale(1);

    @include transition(transform);
  }

  span.icon {
    background: url('../images/enlarge_icon_crop.png') no-repeat;
    position: absolute;
    right: 8px;
    bottom: 8px;
    display: block;
    z-index: 1;
    height: 12px;
    width: 13px;
  }

}
