/*================================================
=== BLOG --=======================================
================================================*/

/* Blog overview */
.section-nieuws,
.section-neuigkeiten,
.section-nachrichten,
.section-nouvelles,
.section-news {
  &.node-type-page {
    background: $green-gray;
  }
}

.blog-item {
  float: left;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  border-bottom: 1px solid #ececec;
  padding-bottom: 30px;

  .col {
    width: 100%;
    float: right;
  }

  .titel {
    padding-bottom: 10px;
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 8px;
    margin-top: 0;

    a {
      font-size: 21px;
      line-height: 25px;
    }
  }

  .auteur,
  .categorie,
  .datum,
  .tags {
    font-size: 13px;
    width: auto !important;

    a {
      color: #999;
    }
  }

  .thumb-persoon {
    float: left;
    position: relative;
    width: 70px;
    height: 70px;
  }

  .content {
    margin-top: 5px;
    border-top: 1px solid #ececec;
    padding-top: 10px;
  }

  .lees-meer a,
  .node-readmore a {
    float: left;
    text-decoration: none;
    font-weight: normal;

    &:hover {
      text-decoration: underline;
    }
  }

  .icon-font {
    font-family: $icon-font;
    font-size: 15px;
    font-weight: normal;
    float: left;
    padding-right: 5px;
  }
}

.view-tags a.active {
  background: #333;
  color: $white;
}

.view-categorie a.active {
  font-weight: bold;
}

/* Blog overview met afbeelding*/
.blog-item.has-image {
  .col {
    width: 65%;
  }

  .thumb.col {
    float: left;
    width: 30%;
    margin-right: 5%;
  }
}

.news-filter {
  position: absolute;
  right: $column-gutter / 2;
  top: 6px;

  @include breakpoint(small) {
    position: relative;
    right: auto;
    top: auto;
  }

  .views-exposed-widget  {
    padding: 0;

    a {
      @extend .btn;

      padding: 6px 20px !important;
      font-size: 15px !important;
      margin-left: 10px;

      @include breakpoint(small) {
        padding: 6px 10px !important;
        margin-left: 0;
        margin-right: 5px;
        min-width: 0 !important;
      }
    }
  }

  .views-submit-button {
    padding: 0;
    float: none;
    width: auto;
  }

  .ajax-progress {
    position: absolute;
    left: -20px;
    top: 6px;
  }
}

.categorie-teeltactualiteit {
  &:before {
    content: '';
    background: url('../images/teeltactualiteit.svg') center/cover no-repeat;
    position: absolute;
    height: 32px;
    width: 32px;
    top: 10px;
    left: 10px;
  }

  .card-date {
    color: $red-light;
  }

  .card-title {
    color: $red-dark;
  }

  .card-label {
    background-color: $red-light;
  }
}

.categorie-algemeen,
.categorie-nieuws {
  .card-date {
    color: $green-dark-gray;
  }

  .card-title {
    color: $green;
  }

  .card-label {
    background-color: $green-dark-gray;
    display: none;
  }
}

.teeltactualiteit-icon {
  // float: right;
  // height: 0;
  // transform: translate(-18px, -50px);
  // position: relative;
  z-index: 110;
  position: fixed;
  bottom: 10px;
  right: 10px;

  @include breakpoint(small) {
    bottom: auto;
    left: auto;
    right: 110px;
    top: 25px;

    .admin-menu & {
      top: 82px;
    }
  }

  a,
  img {
    display: block;
  }

  img {
    animation: 2s infinite alternate pulse;

    @include breakpoint(small) {
      animation: none;
    }
  }
}

@keyframes pulse {
  from {
    transform: scale(1.4);
  }

  to {
    transform: scale(1);
  }
}

/* Blog Detail */
.blog-detail {
  .news-date .field-item {
    display: inline-block;
    background-color: $white;
    padding: 0 9px;
    line-height: 30px;
    margin: 0 0 15px;
    font-size: 13px;
    color: $secondary-color;
    font-weight: $fw-semibold;
    border: 1px solid $gray;
    position: relative;
    z-index: 2;

    em {
      font-style: normal;
    }
  }

  .blog-item {
    border: none;
    margin: 0 !important;
    padding-bottom: 10px;
    float: left;
    width: 100%;

    h1 {
      margin-top: 0;
    }

    .thumb {
      margin-top: 10px;
      float: left;
      width: 100%;

      img {
        width: 100%;
      }
    }

    .content {
      margin-top: 0;
      padding-top: 0;
      border: 0;
    }

    .auteur,
    .categorie,
    .datum,
    .tags {
      float: left;
      margin-top: 25px;
      margin-right: 20px;
      color: #999;
    }
  }

  .auteur {
    margin-left: 10px;
  }

  .share-this {
    float: right;
  }

  // .back-to-overview {
  //   float: left;
  //   width: 100%;
  //   border-top: 0;
  //   padding-top: 10px;
  // }
}

/* MASONRY */

.view-blog.view-id-blog.view-display-id-block_5,
.view-id-cases {
  margin: -10px -10px 50px;
}

.view-blog.view-id-blog.view-display-id-block_5 .view-content {}

.masonry-item {
  // width: 33.3%;
  float: left;
  margin: 0 0 $column-gutter;
  padding: 0;

  item-wrapper {
    padding: 10px;

    item-inner {
      padding: 0;
      position: relative;
      background: white;
      overflow: hidden;
      transition: all 200ms ease-in;
    }
  }

  .item-link {
    display: block;
    width: 100%;
    height: 100%;

    &:hover,
    .item-inner {
      box-shadow: 0 0 1px #00a1e5;
      -webkit-box-shadow: 0 0 1px #d9e7ec;
      -moz-box-shadow: 0 0 1px #00a1e5;
    }
  }

  .datum {
    display: block;
    color: #999;
  }

  .titel {
    font-size: 32px;
    line-height: 36px;
    font-weight: 100;
    padding: 10px 0;
    display: block;
    padding: 20px 40px 10px;
  }

  .content {
    padding: 0 40px 20px;
    display: block;
  }

  .thumb {
    display: block;

    img {}

    .small img {
      float: left;
    }
  }

  .tags {
    li {
      list-style: none;
    }

    ul li {
      float: left;
      padding: 0;
      margin-right: 3px;
      border: 1px solid #80b8f1;
      color: #80b8f1;
      padding: 3px 6px;
      border-radius: 5px;
    }
  }
}

.masonry-item.extended {
  width: 66.6%;

  .item-inner {
    background: $white;
  }

  .datum {
    color: $white;
  }

  .tags li {
    color: $white;
    border: 1px solid $white;
  }
}

.masonry-item.quotes {
  .item-inner {
    background: #2ccefd;
  }

  .content {
    font-size: 22px;
    line-height: 25px;
    font-weight: 100;
    color: $white;
    padding: 20px 40px;
  }

  .quote-klant {
    float: right;
    clear: both;
    font-size: 18px;
    font-weight: 100;
    color: $white;
    padding: 0 40px;
  }

  .quote-functie {
    float: right;
    clear: both;
    font-weight: 100;
    font-size: 13px;
    padding-top: 3px;
    color: $white;
    padding: 5px 40px 20px;
  }
}
@include breakpoint(large) {
  .masonry-item {
    width: 50%;
  }

  .masonry-item.extended {
    width: 100%;
  }
}
@include breakpoint(medium) {
  .masonry-item {
    width: 100%;
  }

  .masonry-item.extended {
    width: 100%;
  }
}

/*================================================
=== Comments
================================================*/
.comment-form {

  float: left;
  width: 100%;
  border-top: 2px solid #efefef;
  padding: 30px 0 0;
  margin: 15px 0 0;

  .description,
  .form-item-homepage {
    display: none;
  }

  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #7d7979;
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #7d7979;
    opacity: 1;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #7d7979;
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #7d7979;
  }

  .columns {
    padding: 0 15px !important;
  }

  .row {
    margin: 0 -15px !important;
  }

  label {
    //display:none;

    .page-comment-edit & {
      display: block;
    }
  }

  .form-item-subject {
    display: none;
  }
}

.comments {

  float: left;
  width: 100%;

  .comment {
    border-top: 1px solid #efefef;
    padding: 20px 0 0;
    margin: 20px 0 0;

    .submitted {
      strong span {
        color: #212020;
      }
    }

    time {
      color: #959191;
    }
  }

  .indented {
    .comment {
      padding: 0 0 0 20px;
      margin: 20px 0 10px;
      border: 0;
      border-left: 2px solid #B4B1B1;
    }
  }
}

.comment {
  .links {
    li {
      padding-left: 0;

      a {
        color: #807F7F;
        font-size: 12px;
      }

      &:before {
        display: none;
      }
    }
  }
}

.comment-add {
  display: none!important;
}

.back-to-overview {
  width: 50%;
  float: left;
}

.pane-sharethis {
  width: 50%;
  float: right;
}

.sharethis-wrapper {
  text-align: right;
  margin: 4em 0 0;
  padding: 0.45em 0;
  @include breakpoint(medium) {
    padding-top: 0.85em;
  }

  span {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-left: 0.5em;
    cursor: pointer;
    opacity: 1;
    @include transition(opacity);

    &:hover {
      opacity: 0.8;
    }
  }
}

.st_facebook_custom {
  background-image: url('../images/social/facebook.svg');
}

.st_twitter_custom {
  background-image: url('../images/social/twitter.svg');
}

.st_linkedin_custom {
  background-image: url('../images/social/linkedin.svg');
}
