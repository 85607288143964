//=================================================
//== MENU =========================================
//=================================================

.region-top-menu {
  float: right;

  .block {
    margin: 0;
    float: left;

    p {
      margin: 0;
      padding: 0;
    }
  }

  ul {
    list-style: none!important;
    margin-bottom: 0;

    li {
      list-style: none!important;
      float: left;

      a {
        color: $text-color;
        padding: 0 1em;
        font-size: 10px;
        display: block;
      }
    }
  }
}

.menus {
  float: right;
}

.language-switcher {
  @extend %clearfix;

  padding: .3em .8em 0 0;

  .language {
    border-right: 1px solid $gray;

    &.last {
      border-right: 0;
    }

    a {
      font-size: 13px;
      padding: 0 1em;
      display: block;
    }

    &.active a {
      color: $primary-color !important;
      text-decoration: none !important;
      cursor: default;
    }
  }
}

.login-btn {
  background: $primary-color;
  border: 1px solid $primary-color;
  border-radius: $button-border-radius 0 0 $button-border-radius;
  color: $white !important;
  border-right: 0 !important;
  padding-left: 1em;
  padding-right: 1em;

  &:before {
    @include extend-icon('lock');
    display: inline-block;
    margin-right: .5em;
  }

  &:hover {
    text-decoration: none;
    color: $white;
    background: darken($primary-color, 10%);
    cursor: pointer;
  }
}

.hoofdmenu {
  z-index: 1;
  position: relative;
  margin: 0;
  float: right;
  width: 100%;

  ul {
    float: right;
    margin: 0;

    li {
      list-style: none!important;
      float: left;
      position: relative;
      margin-left: 20px;

      &:hover ul {
        display: block;
      }

      a {
        display: block;
        float: none;
        color: $menu-link-color;
        font-family: $alternative-font;
        font-weight: $link-text-weight;
        border-right: 0;
        // padding: 10px 15px;
        padding: 10px 0;
        font-size: 15px;
        text-align: center;
        white-space: nowrap;
        text-decoration: none;

        &.active,
        &.is-active-trail {
          color: $menu-link-color-active !important;
        }

        &:hover {
          color: $menu-link-color-hover !important;
        }
      }

      //Submenu
      ul {
        width: auto;
        display: none;
        position: absolute;
        left: 0;
        top: 44px;
        background: $white;

        li {
          display: block;
          float: none;
          width: auto;
          white-space: nowrap;
          border: none;

          a {
            border-radius: 0px!important;
            border: none;
            padding: 10px 15px;
            text-align: center;
            width: 100%;
            text-align: left;

          }
        }
      }

      .i18n-de & {
        margin-left: 10px;
        top: 3px;

        a {
          font-size: 13px;
        }
      }

      .i18n-en & {
        a {
          font-size: 14px;
        }
      }
    }
  }
}

.mobile-menu {
  display: none;
}

.mobile-menu-button {
  font-size: 24px;
  width: 1em;
  height: .85em;
  position: relative;
  margin: 0 auto;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    font-size: 1em;
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $secondary-color;
    border-radius: 4px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: .3333em;
    }

    &:nth-child(3) {
      top: .6666em;
    }

    .mm-opening & {
      &:nth-child(1) {
        top: .3333em;
        transform: rotate(135deg);
      }

      &:nth-child(2) {
        opacity: 0;
        left: -1em;
      }

      &:nth-child(3) {
        top: .3333em;
        transform: rotate(-135deg);
      }
    }
  }
}

.mobile-menu-button:hover {
  text-decoration: none;
}

//RESPONSIVE MOBIEL
@include breakpoint(medium) {
  .hoofdmenu {
    display: none;
  }

  //Toon mobile menu button
  .mobile-menu-button {
    display: block;
  }

  .mm-menu.mm-right {
    background: $white!important;
  }

  .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    margin: 0 !important;
    border-color: $gray !important;
  }

  .mm-list > li > a,
  .mm-list > li > span {
    color: $black !important;
  }

  .search-mobile form {
    padding: 0 20px !important;
  }

  .search-mobile input[type='text'] {
    background: rgba(0, 0, 0, 0.15);
    border: none;
    color: $white;
  }

  .search-mobile input[type='submit'] {
    position: absolute;
    top: 5px;
    right: 5px;
    font-family: $icon-font;
    background: none;
    color: $white;
    border: none;
    font-size: 24px;
  }

  .mm-list > li > a,
  .mm-list > li > span {
    color: $text-color;
  }
}
