//================================================
//=== BREADCRUMB =================================
//================================================

.breadcrumbs a,
.breadcrumbs span {
  font-size: 14px;
  color: $secondary-color;
  font-weight: $fw-semibold;
}

.easy-breadcrumb_segment-separator {
  padding: 0 .8em;
}

// Hide op homepage
.front .breadcrumbs {
  display: none;
}

.breadcrumbs {
  width: $row-width;
  min-width: 0;
  margin: ($column-gutter / 2) auto $column-gutter;
  max-width: 100%;

  .easy-breadcrumb {
    padding: 0 ($column-gutter / 2);
  }
}
