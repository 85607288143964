%clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

%row {
  width: $row-width;
  margin: 0 auto;
  max-width: 95%;
}

%ul-caret-list {
  list-style-type: none;
  padding-left: $ul-icon-size;

  li {
    margin: 0;

    &:before {
      @include extend-icon(caret-right);

      color: $secondary-color;
      display: inline-block;
      margin-left: -$ul-icon-size;
      width: $ul-icon-size;
    }
  }
}
