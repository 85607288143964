/*================================================
=== COOKIES ======================================
================================================*/
.koekjes-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($white, .9);
  border-top: 1px solid $green-dark-gray;
  padding: 10px 0;
  z-index: 110;

  h6,
  p {
    font-size: 12px;
    padding-bottom: 0;

    @media screen and (max-width: 767px) {
      font-size: 10px;
      line-height: 1.2;
    }
  }
}

.koekjes-content {
  padding: 0 20px;

  @media screen and (max-width: 767px) {
    padding: 0 0 20px;
  }
}

.koekjes-actions {
  @include clearfix();
  margin: 10px 0;
  text-align: center;

  .btn {
    font-size: 14px;
    min-width: 140px;
    float: right;

    @media screen and (max-width: 767px) {
      font-size: 12px;
      min-width: 100px;
      padding: 5px 10px;
    }
  }
}
