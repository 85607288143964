.content {
  @extend %clearfix;
}

//========================================
// Overzichten
//========================================
.overzicht {
  &:hover {
    a {
      opacity: .8;
    }
  }

  a {
    display: block;
    margin-bottom: 30px;

    img {
      transition: all 0.4s ease;
    }

    .views-field-field-afbeelding .field-content,
    .views-field-field-afbeeldingen .field-content {
      overflow: hidden;
      display: block;
      line-height: 0;
    }

    .views-field-title .field-content {
      color: $black;
      font-size: 18px;
      padding: 10px 0;
      display: block;
    }

    &:hover {
      text-decoration: none;

      opacity: 1 !important;

      img {
        transform: scale(1.1);
      }
    }
  }
}

//========================================
// Infoblok
//======================================//
.infowrap {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 20px;

  &:hover {
    opacity: .8;
    text-decoration: none !important;
  }

  .icoon {
    text-align: center;
    padding: 15px;

    .icon {
      &:before {
        display: inline-block;
        float: none;
        font-size: 34px;
        vertical-align: middle;

        .large & {
          margin: 0 0 10px;
          clear: both;
          line-height: initial;
          font-size: 86px;

        }
      }
    }

    p {
      display: inline-block;
      margin: 0;
      padding: 0;
      font-size: 19px;
      line-height: 20px;
      vertical-align: middle;

      .front & {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }

  &.border {
    border: 1px solid $gray-dark;
  }

  &.imageleft {
    .image {
      width: 30%;
      float: left;
      min-height: 150px;
      background-size: cover !important;
      background-position: center !important;
    }

    .infoblok {
      width: 70%;
      float: left;
    }
  }

  &.imagetop {
    .image {
      width: 100%;
      float: left;
      min-height: 170px;
      background-size: cover !important;
      background-position: center !important;
    }

    .infoblok {
      width: 100%;
      float: left;
    }
  }
}

div.infoblok {
  float: left;
  width: 100%;
  padding: 30px;
  background-size: cover !important;
  background-position: center !important;

  h2 {
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 25px;
  }

  p {
    padding-bottom: 0;
    font-size: 14px;
    line-height: 20px;
  }

  a.btn {
    margin-bottom: 0;
  }

  // Eeew
  &.kleur-2ba6cb,
  &.kleur-e3e3e3,
  &.kleur-f2f2f2 {
    a,
    blockquote,
    blockquote p:before,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ol,
    p,
    ul {
      color: $white;
    }

    .btn {
      background: $white;
      color: $red;
    }

    .btn.rand {
      background: transparent;
      border: 2px solid $white;
      color: $white;
    }
  }

  .color-e3e3e3 {
    background: #e3e3e3;
  }

  .color-f2f2f2 {
    background: #f2f2f2;
  }

  .color-2ba6cb {
    background: #2ba6cb;
  }
}

.front .kleur-9FDCE7 {
  text-align: center;

  p {
    font-size: 38px;
    line-height: initial;
  }
}

//========================================
// Iconen
//========================================
.icon {
  &:before {
    color: $white;
    float: left;
    margin: 0 15px 0 0;
    line-height: 20px;
  }
}

//========================================
// Isotope
//========================================
.isotope-container,
.isotope-container .isotope-element {
  transition-duration: .8s;
}

.isotope-container {
  transition-property: height, width;
}

.isotope-container .isotope-element {
  transition-property: transform, opacity;
}

.isotope .isotope-item.no-transition,
.isotope.no-transition,
.isotope.no-transition .isotope-item {
  transition-duration: 0s;
}

.isotope-options {
  margin: 0 !important;
}

.isotope {
  margin: 20px 0 0;
  display: inline-block;
  width: 100%;

  > .view-content {
    margin: 0 -15px;
  }
}

.isotope-container {
  margin: 0 !important;
  padding: 0 !important;

  .isotope-element {
    width: 25%;
    padding: 0 15px;

    .drie & {
      width: 33.333333333333333%;
    }
    @include breakpoint(medium) {
      float: left!important;
      width: 100%!important;
    }
  }

}

//========================================
// Taxonomy
//========================================
.taxonomy-widget {
  padding: 30px;
  background: $gray;
  margin-bottom: 30px;

  h2 {
    margin-top: 0;
    color: $header-font-color;
    font-size: 20px;
  }

  ul li {
    list-style-type: square;

    a {
      color: $black;
      text-decoration: none;

      &.active {
        font-weight: 600;
      }

      &:hover {
        opacity: .5;
      }
    }
  }
}

//========================================
// Anchor: correctie fixed header
//========================================
a.anchor {
  padding-top: 180px;
  margin-top: -180px;
  float: left;
  width: 100%;
  display: block!important;
  visibility: hidden!important;
}

//========================================
// Website berichten
//========================================
.messages {
  float: left;
  width: 100%;
  clear: both;
}

//========================================
// Snelmailer
//========================================
.snelmailer-widget {
  float: left;
  width: 100%;
  margin: 15px 0;

  .btn {
    font-size: 12px;
  }

  .container-error {
    display: none!important;
  }
}

//========================================
// Exposed form
//========================================
.views-exposed-form {
  label {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
  }

  .form-radios {
    text-align: center;
  }

  .views-exposed-widget {
    width: 100%;

    input[type='radio'] {
      display: none;
    }

    input[type=radio]:checked + label {
      background: $button-color-active;
      color: $white;
      border: 1px solid $button-color-active;
    }

    .form-item {
      float: none;
      display: inline-block;
    }

    .option {
      float: left;
      padding: 8px 20px;
      min-width: 180px;
      background: $white;
      border-radius: 5px;
      text-align: center;
      margin: 10px 10px 0;
      color: $button-color;
      font-size: 15px;
      font-weight: 400;
      display: inline-block;
      border: 1px solid $button-color;

      &:hover {
        background: $button-color;
        color: $white;
      }
    }
  }

}

//========================================
// Paginering
//========================================
.pager {
  // background: $gray-dark;
  margin: 50px 0 0 !important;
  padding: 20px 0;

  li {
    background: none!important;
    padding-left: 0 !important;
    margin-left: 7px !important;
  }

  .pager-current,
  .pager-item a {
    background: $primary-color !important;
    padding: 0 !important;
    text-align: center;
    border-radius: 2px;
    height: 18px;
    width: 18px;
    display: inline-block;
    line-height: 17px;
    color: $white;
    font-size: 13px;
  }

  .pager-item a {
    color: $secondary-color;
    background: none !important;
  }

  .pager-first a,
  .pager-last a,
  .pager-next a,
  .pager-previous a {
    color: $secondary-color;
    font-weight: bold;
  }

  &.pager--infinite-scroll {
    list-style: none;

    a {
      @extend .btn;
    }
  }
}

//========================================
// Homeblokken
//========================================
.homeblok {
  background: $gray;
  float: left;
  width: 100%;
  padding: 15px;
}

//========================================
// Diversen
//========================================
.ajax-progress {
  position: absolute;
  left: 50%;
  bottom: 0;
}

.ajax-progress .throbber {
  background: url('../images/ajax-loader.gif');
  position: absolute;
  height: 11px;
  width: 16px;
  margin: 2px;
  z-index: 10;
}

// Blauwe rand om menu weghalen
:focus {
  outline: none;
}

.anchor {
  display: block!important;
  content: ' '!important;
  margin-top: -200px !important;
  height: 200px!important;
  visibility: hidden!important;
}

a {
  transition: all .4s ease;
}

//========================================
// Media embed
//========================================
.media_embed {
  position: relative;
  // padding-bottom: 56.25%;
  padding-bottom: 9 / 16 * 100%;
  height: 0;
  margin-bottom: 1rem;
  overflow: hidden;
}

.media_embed.widescreen {
  padding-bottom: 56.34%;
}

.media_embed.vimeo {
  padding-top: 0;
}

.media_embed embed,
.media_embed iframe,
.media_embed object,
.media_embed video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

img {
  height: auto;
}

embed,
img,
object {
  max-width: 100%;
}

img {
  -ms-interpolation-mode: bicubic;
}

#map_canvas img,
.map_canvas img {
  max-width: none!important;
}
