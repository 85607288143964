@import url("https://fonts.googleapis.com/css?family=Merriweather:300,400|Source+Sans+Pro:200,400,600,700,900");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }
  blockquote:before, blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*
	jQuery.mmenu CSS
*/
/*
	jQuery.mmenu panels CSS
*/
.mm-menu.mm-horizontal > .mm-panel {
  -webkit-transition: left 0.4s ease;
  -moz-transition: left 0.4s ease;
  -ms-transition: left 0.4s ease;
  -o-transition: left 0.4s ease;
  transition: left 0.4s ease; }

.mm-menu .mm-hidden {
  display: none; }

.mm-wrapper {
  overflow-x: hidden;
  position: relative; }

.mm-menu {
  background: inherit;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0; }
  .mm-menu > .mm-panel {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    background: inherit;
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 0; }
    .mm-menu > .mm-panel.mm-opened {
      left: 0%; }
    .mm-menu > .mm-panel.mm-subopened {
      left: -40%; }
    .mm-menu > .mm-panel.mm-highest {
      z-index: 1; }
  .mm-menu .mm-list {
    padding: 20px 0; }
  .mm-menu > .mm-list {
    padding: 20px 0 40px 0; }

.mm-panel > .mm-list {
  margin-left: -20px;
  margin-right: -20px; }
  .mm-panel > .mm-list:first-child {
    padding-top: 0; }

.mm-list,
.mm-list > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0; }

.mm-list {
  font: inherit;
  font-size: 14px; }
  .mm-list a,
  .mm-list a:hover {
    text-decoration: none; }
  .mm-list > li {
    position: relative; }
    .mm-list > li > a,
    .mm-list > li > span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: inherit;
      line-height: 20px;
      display: block;
      padding: 10px 10px 10px 20px;
      margin: 0; }
  .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    content: '';
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0; }
  .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    width: auto;
    margin-left: 20px;
    position: relative;
    left: auto; }
  .mm-list a.mm-subopen {
    width: 40px;
    height: 100%;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2; }
    .mm-list a.mm-subopen:before {
      content: '';
      border-left-width: 1px;
      border-left-style: solid;
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }
    .mm-list a.mm-subopen.mm-fullsubopen {
      width: 100%; }
      .mm-list a.mm-subopen.mm-fullsubopen:before {
        border-left: none; }
    .mm-list a.mm-subopen + a,
    .mm-list a.mm-subopen + span {
      padding-right: 5px;
      margin-right: 40px; }
  .mm-list > li.mm-selected > a.mm-subopen {
    background: transparent; }
  .mm-list > li.mm-selected > a.mm-fullsubopen + a,
  .mm-list > li.mm-selected > a.mm-fullsubopen + span {
    padding-right: 45px;
    margin-right: 0; }
  .mm-list a.mm-subclose {
    text-indent: 20px;
    padding-top: 30px;
    margin-top: -20px; }
  .mm-list > li.mm-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 10px;
    text-transform: uppercase;
    text-indent: 20px;
    line-height: 25px;
    padding-right: 5px; }
  .mm-list > li.mm-spacer {
    padding-top: 40px; }
    .mm-list > li.mm-spacer.mm-label {
      padding-top: 25px; }
  .mm-list a.mm-subopen:after,
  .mm-list a.mm-subclose:before {
    content: '';
    border: 2px solid transparent;
    display: block;
    width: 7px;
    height: 7px;
    margin-bottom: -5px;
    position: absolute;
    bottom: 50%;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .mm-list a.mm-subopen:after {
    border-top: none;
    border-left: none;
    right: 18px; }
  .mm-list a.mm-subclose:before {
    border-right: none;
    border-bottom: none;
    margin-bottom: -15px;
    left: 22px; }

.mm-menu.mm-vertical .mm-list .mm-panel {
  display: none;
  padding: 10px 0 10px 10px; }
  .mm-menu.mm-vertical .mm-list .mm-panel li:last-child:after {
    border-color: transparent; }

.mm-menu.mm-vertical .mm-list li.mm-opened > .mm-panel {
  display: block; }

.mm-menu.mm-vertical .mm-list > li.mm-opened > a.mm-subopen {
  height: 40px; }
  .mm-menu.mm-vertical .mm-list > li.mm-opened > a.mm-subopen:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 16px;
    right: 16px; }

html.mm-opened .mm-page {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }

.mm-ismenu {
  background: #333;
  color: rgba(255, 255, 255, 0.6); }

.mm-menu .mm-list > li:after {
  border-color: rgba(0, 0, 0, 0.15); }

.mm-menu .mm-list > li > a.mm-subclose {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.3); }

.mm-menu .mm-list > li > a.mm-subopen:after, .mm-menu .mm-list > li > a.mm-subclose:before {
  border-color: rgba(255, 255, 255, 0.3); }

.mm-menu .mm-list > li > a.mm-subopen:before {
  border-color: rgba(0, 0, 0, 0.15); }

.mm-menu .mm-list > li.mm-selected > a:not(.mm-subopen),
.mm-menu .mm-list > li.mm-selected > span {
  background: rgba(0, 0, 0, 0.1); }

.mm-menu .mm-list > li.mm-label {
  background: rgba(255, 255, 255, 0.05); }

.mm-menu.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
.mm-menu.mm-vertical .mm-list li.mm-opened > ul {
  background: rgba(255, 255, 255, 0.05); }

/*
	jQuery.mmenu offcanvas addon CSS
*/
.mm-page,
.mm-fixed-top,
.mm-fixed-bottom {
  -webkit-transition: none 0.4s ease;
  -moz-transition: none 0.4s ease;
  -ms-transition: none 0.4s ease;
  -o-transition: none 0.4s ease;
  transition: none 0.4s ease;
  -webkit-transition-property: top, right, bottom, left, border;
  -moz-transition-property: top, right, bottom, left, border;
  -ms-transition-property: top, right, bottom, left, border;
  -o-transition-property: top, right, bottom, left, border;
  transition-property: top, right, bottom, left, border; }

html.mm-opened .mm-page,
html.mm-opened #mm-blocker {
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0px solid transparent; }

html.mm-opening .mm-page {
  border: 0px solid #010101; }

.mm-fixed-top,
.mm-fixed-bottom {
  position: fixed;
  left: 0; }

.mm-fixed-top {
  top: 0; }

.mm-fixed-bottom {
  bottom: 0; }

html.mm-opened {
  overflow-x: hidden;
  position: relative; }

html.mm-opened .mm-page {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  position: relative; }

html.mm-background .mm-page {
  background: inherit; }

#mm-blocker {
  background: url(data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==) transparent;
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999; }

html.mm-opened #mm-blocker,
html.mm-blocking #mm-blocker {
  display: block; }

.mm-menu.mm-offcanvas {
  display: none;
  position: fixed; }

.mm-menu.mm-current {
  display: block; }

html.mm-opening .mm-page,
html.mm-opening #mm-blocker,
html.mm-opening .mm-fixed-top,
html.mm-opening .mm-fixed-bottom {
  left: 80%; }

.mm-menu {
  width: 80%; }

@media all and (max-width: 175px) {
  .mm-menu {
    width: 140px; }
  html.mm-opening .mm-page,
  html.mm-opening #mm-blocker,
  html.mm-opening .mm-fixed-top,
  html.mm-opening .mm-fixed-bottom {
    left: 140px; } }

@media all and (min-width: 550px) {
  .mm-menu {
    width: 440px; }
  html.mm-opening .mm-page,
  html.mm-opening #mm-blocker,
  html.mm-opening .mm-fixed-top,
  html.mm-opening .mm-fixed-bottom {
    left: 440px; } }

/*
	jQuery.mmenu counters addon CSS
*/
em.mm-counter {
  font: inherit;
  font-size: 14px;
  font-style: normal;
  text-indent: 0;
  line-height: 20px;
  display: block;
  margin-top: -10px;
  position: absolute;
  right: 40px;
  top: 50%; }
  em.mm-counter + a.mm-subopen {
    padding-left: 40px; }
    em.mm-counter + a.mm-subopen + a,
    em.mm-counter + a.mm-subopen + span {
      margin-right: 80px; }
  em.mm-counter + a.mm-fullsubopen {
    padding-left: 0; }

.mm-vertical em.mm-counter {
  top: 12px;
  margin-top: 0; }

.mm-nosubresults > em.mm-counter {
  display: none; }

.mm-menu em.mm-counter {
  color: rgba(255, 255, 255, 0.3); }

/*
	jQuery.mmenu dragOpen addon CSS
*/
html.mm-opened.mm-dragging .mm-menu,
html.mm-opened.mm-dragging .mm-page,
html.mm-opened.mm-dragging .mm-fixed-top,
html.mm-opened.mm-dragging .mm-fixed-bottom,
html.mm-opened.mm-dragging #mm-blocker {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s; }

/*
	jQuery.mmenu header addon CSS
*/
.mm-header {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  background: inherit;
  border-bottom: 1px solid transparent;
  text-align: center;
  line-height: 20px;
  width: 100%;
  height: 60px;
  padding: 30px 40px 0 40px;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0; }
  .mm-header .mm-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    width: 100%;
    position: relative;
    z-index: 1; }
  .mm-header .mm-prev,
  .mm-header .mm-next {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    display: block;
    width: 40px;
    height: 100%;
    position: absolute;
    bottom: 0; }
    .mm-header .mm-prev:before,
    .mm-header .mm-next:before {
      content: '';
      border: 2px solid transparent;
      display: block;
      width: 7px;
      height: 7px;
      margin-bottom: -5px;
      position: absolute;
      bottom: 50%;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      margin-bottom: -15px; }
  .mm-header .mm-prev {
    left: 0; }
    .mm-header .mm-prev:before {
      border-right: none;
      border-bottom: none;
      left: 22px; }
  .mm-header .mm-next {
    right: 0; }
    .mm-header .mm-next:before {
      border-top: none;
      border-left: none;
      right: 18px; }

.mm-menu.mm-hassearch .mm-header {
  height: 50px;
  padding-top: 20px;
  top: 50px; }
  .mm-menu.mm-hassearch .mm-header .mm-prev:before,
  .mm-menu.mm-hassearch .mm-header .mm-mext:before {
    margin-bottom: -10px; }

.mm-menu.mm-hasheader li.mm-subtitle {
  display: none; }

.mm-menu.mm-hasheader > .mm-panel {
  padding-top: 80px; }
  .mm-menu.mm-hasheader > .mm-panel.mm-list {
    padding-top: 60px; }
  .mm-menu.mm-hasheader > .mm-panel > .mm-list:first-child {
    margin-top: -20px; }

.mm-menu.mm-hasheader.mm-hassearch > .mm-panel {
  padding-top: 120px; }
  .mm-menu.mm-hasheader.mm-hassearch > .mm-panel.mm-list {
    padding-top: 100px; }

.mm-menu .mm-header {
  border-color: rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.3); }
  .mm-menu .mm-header a:before {
    border-color: rgba(255, 255, 255, 0.3); }

/*
	jQuery.mmenu labels addon CSS
*/
.mm-menu.mm-fixedlabels .mm-list {
  background: inherit; }
  .mm-menu.mm-fixedlabels .mm-list > li.mm-label {
    background: inherit !important;
    opacity: 0.97;
    height: 25px;
    overflow: visible;
    position: relative;
    z-index: 1; }
    .mm-menu.mm-fixedlabels .mm-list > li.mm-label > div {
      background: inherit;
      width: 100%;
      position: absolute;
      left: 0; }
      .mm-menu.mm-fixedlabels .mm-list > li.mm-label > div > div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
    .mm-menu.mm-fixedlabels .mm-list > li.mm-label.mm-spacer > div > div {
      padding-top: 25px; }

.mm-list > li.mm-label > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0; }

.mm-list > li.mm-label.mm-opened a.mm-subopen:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 17px; }

.mm-list > li.mm-collapsed {
  display: none; }

.mm-menu .mm-list li.mm-label > div > div {
  background: rgba(255, 255, 255, 0.05); }

/*
	jQuery.mmenu searchfield addon CSS
*/
.mm-search,
.mm-search input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box; }

.mm-list > li.mm-search {
  padding: 10px;
  margin-top: -20px; }

.mm-list > li.mm-subtitle + li.mm-search {
  margin-top: 0; }

div.mm-panel > div.mm-search {
  padding: 0 0 10px 0; }

.mm-menu.mm-hasheader .mm-list > li.mm-search {
  margin-top: 0; }

.mm-search {
  background: inherit;
  width: 100%;
  padding: 10px;
  position: relative;
  top: 0;
  z-index: 2; }
  .mm-search input {
    border: none;
    border-radius: 30px;
    font: inherit;
    font-size: 14px;
    line-height: 30px;
    outline: none;
    display: block;
    width: 100%;
    height: 30px;
    margin: 0;
    padding: 0 10px; }

.mm-menu .mm-noresultsmsg {
  text-align: center;
  font-size: 21px;
  display: none;
  padding: 60px 0; }
  .mm-menu .mm-noresultsmsg:after {
    border: none !important; }

.mm-noresults .mm-noresultsmsg {
  display: block; }

.mm-menu li.mm-nosubresults > a.mm-subopen {
  display: none; }
  .mm-menu li.mm-nosubresults > a.mm-subopen + a,
  .mm-menu li.mm-nosubresults > a.mm-subopen + span {
    padding-right: 10px; }

.mm-menu.mm-hassearch > .mm-panel {
  padding-top: 70px; }
  .mm-menu.mm-hassearch > .mm-panel > .mm-list:first-child {
    margin-top: -20px; }

.mm-menu.mm-hasheader > .mm-panel > div.mm-search:first-child {
  margin-top: -10px; }
  .mm-menu.mm-hasheader > .mm-panel > div.mm-search:first-child + .mm-list {
    padding-top: 0; }

.mm-menu .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6); }

.mm-menu .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.3); }

/*
	jQuery.mmenu toggles addon CSS
*/
label.mm-toggle {
  border-radius: 30px;
  width: 50px;
  height: 30px;
  margin: -15px 0 0 0;
  position: absolute;
  top: 50%;
  z-index: 1; }
  label.mm-toggle div {
    border-radius: 30px;
    width: 28px;
    height: 28px;
    margin: 1px; }

input.mm-toggle {
  position: absolute;
  left: -10000px; }
  input.mm-toggle:checked ~ label.mm-toggle div {
    float: right; }

label.mm-toggle {
  right: 20px; }
  label.mm-toggle + a,
  label.mm-toggle + span {
    margin-right: 70px; }

a.mm-subopen + label.mm-toggle {
  right: 50px; }
  a.mm-subopen + label.mm-toggle + a,
  a.mm-subopen + label.mm-toggle + span {
    margin-right: 100px; }

em.mm-counter + a.mm-subopen + label.mm-toggle {
  right: 90px; }
  em.mm-counter + a.mm-subopen + label.mm-toggle + a,
  em.mm-counter + a.mm-subopen + label.mm-toggle + span {
    margin-right: 140px; }

.mm-menu label.mm-toggle {
  background: rgba(0, 0, 0, 0.15); }
  .mm-menu label.mm-toggle div {
    background: #333; }

.mm-menu input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

/*
	jQuery.mmenu effects extension CSS
*/
html.mm-slide .mm-menu {
  -webkit-transition: -webkit-transform 0.4s ease;
  -moz-transition: -moz-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
  transition: transform 0.4s ease; }

html.mm-slide.mm-opened .mm-menu {
  -webkit-transform: translateX(-40%);
  -moz-transform: translateX(-40%);
  -ms-transform: translateX(-40%);
  -o-transform: translateX(-40%);
  transform: translateX(-40%); }

html.mm-slide.mm-opening .mm-menu {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%); }

html.mm-slide.mm-right.mm-opened .mm-menu {
  -webkit-transform: translateX(40%);
  -moz-transform: translateX(40%);
  -ms-transform: translateX(40%);
  -o-transform: translateX(40%);
  transform: translateX(40%); }

html.mm-slide.mm-right.mm-opening .mm-menu {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%); }

html.mm-slide.mm-top.mm-opened .mm-menu {
  -webkit-transform: translateY(-40%);
  -moz-transform: translateY(-40%);
  -ms-transform: translateY(-40%);
  -o-transform: translateY(-40%);
  transform: translateY(-40%); }

html.mm-slide.mm-top.mm-opening .mm-menu {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%); }

html.mm-slide.mm-bottom.mm-opened .mm-menu {
  -webkit-transform: translateY(40%);
  -moz-transform: translateY(40%);
  -ms-transform: translateY(40%);
  -o-transform: translateY(40%);
  transform: translateY(40%); }

html.mm-slide.mm-bottom.mm-opening .mm-menu {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%); }

html.mm-zoom-menu .mm-menu {
  -webkit-transition: -webkit-transform 0.4s ease;
  -moz-transition: -moz-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
  transition: transform 0.4s ease; }

html.mm-zoom-menu.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateX(-40%);
  -moz-transform: scale(0.7, 0.7) translateX(-40%);
  -ms-transform: scale(0.7, 0.7) translateX(-40%);
  -o-transform: scale(0.7, 0.7) translateX(-40%);
  transform: scale(0.7, 0.7) translateX(-40%);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center; }

html.mm-zoom-menu.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateX(0%);
  -moz-transform: scale(1, 1) translateX(0%);
  -ms-transform: scale(1, 1) translateX(0%);
  -o-transform: scale(1, 1) translateX(0%);
  transform: scale(1, 1) translateX(0%); }

html.mm-zoom-menu.mm-right.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateX(40%);
  -moz-transform: scale(0.7, 0.7) translateX(40%);
  -ms-transform: scale(0.7, 0.7) translateX(40%);
  -o-transform: scale(0.7, 0.7) translateX(40%);
  transform: scale(0.7, 0.7) translateX(40%);
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -ms-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center; }

html.mm-zoom-menu.mm-right.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateX(0%);
  -moz-transform: scale(1, 1) translateX(0%);
  -ms-transform: scale(1, 1) translateX(0%);
  -o-transform: scale(1, 1) translateX(0%);
  transform: scale(1, 1) translateX(0%); }

html.mm-zoom-menu.mm-top.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateY(-40%);
  -moz-transform: scale(0.7, 0.7) translateY(-40%);
  -ms-transform: scale(0.7, 0.7) translateY(-40%);
  -o-transform: scale(0.7, 0.7) translateY(-40%);
  transform: scale(0.7, 0.7) translateY(-40%);
  -webkit-transform-origin: center top;
  -moz-transform-origin: center top;
  -ms-transform-origin: center top;
  -o-transform-origin: center top;
  transform-origin: center top; }

html.mm-zoom-menu.mm-top.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateY(0%);
  -moz-transform: scale(1, 1) translateY(0%);
  -ms-transform: scale(1, 1) translateY(0%);
  -o-transform: scale(1, 1) translateY(0%);
  transform: scale(1, 1) translateY(0%); }

html.mm-zoom-menu.mm-bottom.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateY(40%);
  -moz-transform: scale(0.7, 0.7) translateY(40%);
  -ms-transform: scale(0.7, 0.7) translateY(40%);
  -o-transform: scale(0.7, 0.7) translateY(40%);
  transform: scale(0.7, 0.7) translateY(40%);
  -webkit-transform-origin: center bottom;
  -moz-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  -o-transform-origin: center bottom;
  transform-origin: center bottom; }

html.mm-zoom-menu.mm-bottom.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateY(0%);
  -moz-transform: scale(1, 1) translateY(0%);
  -ms-transform: scale(1, 1) translateY(0%);
  -o-transform: scale(1, 1) translateY(0%);
  transform: scale(1, 1) translateY(0%); }

html.mm-zoom-page .mm-page {
  -webkit-transition-property: -webkit-transform, top, right, bottom, left, border;
  -moz-transition-property: -moz-transform, top, right, bottom, left, border;
  -ms-transition-property: -ms-transform, top, right, bottom, left, border;
  -o-transition-property: -o-transform, top, right, bottom, left, border;
  transition-property: transform, top, right, bottom, left, border; }

html.mm-zoom-page.mm-opened .mm-page {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center; }

html.mm-zoom-page.mm-opening .mm-page {
  -webkit-transform: scale(1.5, 1.5);
  -moz-transform: scale(1.5, 1.5);
  -ms-transform: scale(1.5, 1.5);
  -o-transform: scale(1.5, 1.5);
  transform: scale(1.5, 1.5); }

html.mm-zoom-page.mm-right.mm-opened .mm-page {
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -ms-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center; }

html.mm-zoom-page.mm-top.mm-opened .mm-page {
  -webkit-transform-origin: center top;
  -moz-transform-origin: center top;
  -ms-transform-origin: center top;
  -o-transform-origin: center top;
  transform-origin: center top; }

html.mm-zoom-page.mm-bottom.mm-opened .mm-page {
  -webkit-transform-origin: center bottom;
  -moz-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  -o-transform-origin: center bottom;
  transform-origin: center bottom; }

html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel {
  -webkit-transform: scale(1.5, 1.5);
  -moz-transform: scale(1.5, 1.5);
  -ms-transform: scale(1.5, 1.5);
  -o-transform: scale(1.5, 1.5);
  transform: scale(1.5, 1.5);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition-property: -webkit-transform, left;
  -moz-transition-property: -moz-transform, left;
  -ms-transition-property: -ms-transform, left;
  -o-transition-property: -o-transform, left;
  transition-property: transform, left; }
  html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel.mm-opened {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
    html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel.mm-opened.mm-subopened {
      -webkit-transform: scale(0.7, 0.7);
      -moz-transform: scale(0.7, 0.7);
      -ms-transform: scale(0.7, 0.7);
      -o-transform: scale(0.7, 0.7);
      transform: scale(0.7, 0.7); }

/*
	jQuery.mmenu fullscreen extension CSS
*/
html.mm-opening.mm-fullscreen .mm-page,
html.mm-opening.mm-fullscreen #mm-blocker,
html.mm-opening.mm-fullscreen .mm-fixed-top,
html.mm-opening.mm-fullscreen .mm-fixed-bottom {
  left: 100%; }

.mm-menu.mm-fullscreen {
  width: 100%; }

@media all and (max-width: 140px) {
  .mm-menu.mm-fullscreen {
    width: 140px; }
  html.mm-opening.mm-fullscreen .mm-page,
  html.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-opening.mm-fullscreen .mm-fixed-top,
  html.mm-opening.mm-fullscreen .mm-fixed-bottom {
    left: 140px; } }

@media all and (min-width: 10000px) {
  .mm-menu.mm-fullscreen {
    width: 10000px; }
  html.mm-opening.mm-fullscreen .mm-page,
  html.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-opening.mm-fullscreen .mm-fixed-top,
  html.mm-opening.mm-fullscreen .mm-fixed-bottom {
    left: 10000px; } }

.mm-menu.mm-top.mm-fullscreen {
  height: 100%; }

html.mm-top.mm-opening.mm-fullscreen .mm-page,
html.mm-top.mm-opening.mm-fullscreen #mm-blocker,
html.mm-top.mm-opening.mm-fullscreen .mm-fixed-top {
  top: 100%; }

html.mm-top.mm-opening.mm-fullscreen .mm-fixed-bottom {
  bottom: -100%; }

@media all and (max-height: 140px) {
  .mm-menu.mm-top.mm-fullscreen {
    height: 140px; }
  html.mm-top.mm-opening.mm-fullscreen .mm-page,
  html.mm-top.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-top.mm-opening.mm-fullscreen .mm-fixed-top {
    top: 140px; }
  html.mm-top.mm-opening.mm-fullscreen .mm-fixed-bottom {
    bottom: -140px; } }

@media all and (min-height: 10000px) {
  .mm-menu.mm-top.mm-fullscreen {
    height: 10000px; }
  html.mm-top.mm-opening.mm-fullscreen .mm-page,
  html.mm-top.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-top.mm-opening.mm-fullscreen .mm-fixed-top {
    top: 10000px; }
  html.mm-top.mm-opening.mm-fullscreen .mm-fixed-bottom {
    bottom: -10000px; } }

.mm-menu.mm-right.mm-fullscreen {
  width: 100%; }

html.mm-right.mm-opening.mm-fullscreen .mm-page,
html.mm-right.mm-opening.mm-fullscreen #mm-blocker,
html.mm-right.mm-opening.mm-fullscreen .mm-fixed-top,
html.mm-right.mm-opening.mm-fullscreen .mm-fixed-bottom {
  right: 100%; }

@media all and (max-width: 140px) {
  .mm-menu.mm-right.mm-fullscreen {
    width: 140px; }
  html.mm-right.mm-opening.mm-fullscreen .mm-page,
  html.mm-right.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-right.mm-opening.mm-fullscreen .mm-fixed-top,
  html.mm-right.mm-opening.mm-fullscreen .mm-fixed-bottom {
    right: 140px; } }

@media all and (min-width: 10000px) {
  .mm-menu.mm-right.mm-fullscreen {
    width: 10000px; }
  html.mm-right.mm-opening.mm-fullscreen .mm-page,
  html.mm-right.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-right.mm-opening.mm-fullscreen .mm-fixed-top,
  html.mm-right.mm-opening.mm-fullscreen .mm-fixed-bottom {
    right: 10000px; } }

.mm-menu.mm-bottom.mm-fullscreen {
  height: 100%; }

html.mm-bottom.mm-opening.mm-fullscreen .mm-page,
html.mm-bottom.mm-opening.mm-fullscreen #mm-blocker,
html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-bottom {
  bottom: 100%; }

html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-top {
  top: -100%; }

@media all and (max-height: 140px) {
  .mm-menu.mm-bottom.mm-fullscreen {
    height: 140px; }
  html.mm-bottom.mm-opening.mm-fullscreen .mm-page,
  html.mm-bottom.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-bottom {
    bottom: 140px; }
  html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-top {
    top: -140px; } }

@media all and (min-height: 10000px) {
  .mm-menu.mm-bottom.mm-fullscreen {
    height: 10000px; }
  html.mm-bottom.mm-opening.mm-fullscreen .mm-page,
  html.mm-bottom.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-bottom {
    bottom: 10000px; }
  html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-top {
    top: -10000px; } }

.mm-menu.mm-fullscreen.mm-front, .mm-menu.mm-fullscreen.mm-next {
  left: -100%; }

@media all and (max-width: 140px) {
  .mm-menu.mm-fullscreen.mm-front, .mm-menu.mm-fullscreen.mm-next {
    left: -140px; } }

@media all and (min-width: 10000px) {
  .mm-menu.mm-fullscreen.mm-front, .mm-menu.mm-fullscreen.mm-next {
    left: -10000px; } }

.mm-menu.mm-top.mm-fullscreen.mm-front, .mm-menu.mm-top.mm-fullscreen.mm-next {
  top: -100%; }

@media all and (max-height: 140px) {
  .mm-menu.mm-top.mm-fullscreen.mm-front, .mm-menu.mm-top.mm-fullscreen.mm-next {
    top: -140px; } }

@media all and (min-height: 10000px) {
  .mm-menu.mm-top.mm-fullscreen.mm-front, .mm-menu.mm-top.mm-fullscreen.mm-next {
    top: -10000px; } }

.mm-menu.mm-right.mm-fullscreen.mm-front, .mm-menu.mm-right.mm-fullscreen.mm-next {
  right: -100%; }

@media all and (max-width: 140px) {
  .mm-menu.mm-right.mm-fullscreen.mm-front, .mm-menu.mm-right.mm-fullscreen.mm-next {
    right: -140px; } }

@media all and (min-width: 10000px) {
  .mm-menu.mm-right.mm-fullscreen.mm-front, .mm-menu.mm-right.mm-fullscreen.mm-next {
    right: -10000px; } }

.mm-menu.mm-bottom.mm-fullscreen.mm-front, .mm-menu.mm-bottom.mm-fullscreen.mm-next {
  bottom: -100%; }

@media all and (max-height: 140px) {
  .mm-menu.mm-bottom.mm-fullscreen.mm-front, .mm-menu.mm-bottom.mm-fullscreen.mm-next {
    bottom: -140px; } }

@media all and (min-height: 10000px) {
  .mm-menu.mm-bottom.mm-fullscreen.mm-front, .mm-menu.mm-bottom.mm-fullscreen.mm-next {
    bottom: -10000px; } }

html.mm-front .mm-fixed-top,
html.mm-front .mm-fixed-bottom,
html.mm-opening.mm-front .mm-fixed-top,
html.mm-opening.mm-front .mm-fixed-bottom {
  left: 0;
  right: auto; }

html.mm-front .mm-fixed-top,
html.mm-opening.mm-front .mm-fixed-top {
  top: 0; }

html.mm-front .mm-fixed-bottom,
html.mm-opening.mm-front .mm-fixed-bottom {
  bottom: 0; }

html.mm-opened.mm-fullscreen .mm-page {
  box-shadow: none !important; }

/*
	jQuery.mmenu position extension CSS
*/
.mm-menu.mm-top {
  width: 100%; }

html.mm-top.mm-opened .mm-page,
html.mm-top.mm-opened #mm-blocker {
  top: 0%; }

html.mm-top.mm-opened.mm-opening .mm-page,
html.mm-top.mm-opened.mm-opening #mm-blocker,
html.mm-top.mm-opened.mm-opening .mm-fixed-top,
html.mm-top.mm-opened.mm-opening .mm-fixed-bottom {
  left: 0; }

.mm-menu.mm-right {
  left: auto;
  right: 0; }

html.mm-right.mm-opened .mm-page,
html.mm-right.mm-opened #mm-blocker,
html.mm-right.mm-opened .mm-fixed-top,
html.mm-right.mm-opened .mm-fixed-bottom {
  left: auto;
  right: 0%; }

html.mm-right.mm-opened.mm-opening .mm-page,
html.mm-right.mm-opened.mm-opening #mm-blocker,
html.mm-right.mm-opened.mm-opening .mm-fixed-top,
html.mm-right.mm-opened.mm-opening .mm-fixed-bottom {
  left: auto; }

.mm-menu.mm-bottom {
  width: 100%;
  top: auto;
  bottom: 0; }

html.mm-bottom.mm-opened .mm-page,
html.mm-bottom.mm-opened #mm-blocker {
  bottom: 0%;
  top: auto; }

html.mm-bottom.mm-opened.mm-opening .mm-page,
html.mm-bottom.mm-opened.mm-opening #mm-blocker,
html.mm-bottom.mm-opened.mm-opening .mm-fixed-top,
html.mm-bottom.mm-opened.mm-opening .mm-fixed-bottom {
  top: auto;
  left: 0; }

.mm-menu.mm-top {
  height: 80%; }

html.mm-top.mm-opening .mm-page,
html.mm-top.mm-opening #mm-blocker,
html.mm-top.mm-opening .mm-fixed-top {
  top: 80%; }

html.mm-top.mm-opening .mm-fixed-bottom {
  bottom: -80%; }

@media all and (max-height: 175px) {
  .mm-menu.mm-top {
    height: 140px; }
  html.mm-top.mm-opening .mm-page,
  html.mm-top.mm-opening #mm-blocker,
  html.mm-top.mm-opening .mm-fixed-top {
    top: 140px; }
  html.mm-top.mm-opening .mm-fixed-bottom {
    bottom: -140px; } }

@media all and (min-height: 1100px) {
  .mm-menu.mm-top {
    height: 880px; }
  html.mm-top.mm-opening .mm-page,
  html.mm-top.mm-opening #mm-blocker,
  html.mm-top.mm-opening .mm-fixed-top {
    top: 880px; }
  html.mm-top.mm-opening .mm-fixed-bottom {
    bottom: -880px; } }

.mm-menu.mm-right {
  width: 80%; }

html.mm-right.mm-opening .mm-page,
html.mm-right.mm-opening #mm-blocker,
html.mm-right.mm-opening .mm-fixed-top,
html.mm-right.mm-opening .mm-fixed-bottom {
  right: 80%; }

@media all and (max-width: 175px) {
  .mm-menu.mm-right {
    width: 140px; }
  html.mm-right.mm-opening .mm-page,
  html.mm-right.mm-opening #mm-blocker,
  html.mm-right.mm-opening .mm-fixed-top,
  html.mm-right.mm-opening .mm-fixed-bottom {
    right: 140px; } }

@media all and (min-width: 550px) {
  .mm-menu.mm-right {
    width: 440px; }
  html.mm-right.mm-opening .mm-page,
  html.mm-right.mm-opening #mm-blocker,
  html.mm-right.mm-opening .mm-fixed-top,
  html.mm-right.mm-opening .mm-fixed-bottom {
    right: 440px; } }

.mm-menu.mm-bottom {
  height: 80%; }

html.mm-bottom.mm-opening .mm-page,
html.mm-bottom.mm-opening #mm-blocker,
html.mm-bottom.mm-opening .mm-fixed-bottom {
  bottom: 80%; }

html.mm-bottom.mm-opening .mm-fixed-top {
  top: -80%; }

@media all and (max-height: 175px) {
  .mm-menu.mm-bottom {
    height: 140px; }
  html.mm-bottom.mm-opening .mm-page,
  html.mm-bottom.mm-opening #mm-blocker,
  html.mm-bottom.mm-opening .mm-fixed-bottom {
    bottom: 140px; }
  html.mm-bottom.mm-opening .mm-fixed-top {
    top: -140px; } }

@media all and (min-height: 1100px) {
  .mm-menu.mm-bottom {
    height: 880px; }
  html.mm-bottom.mm-opening .mm-page,
  html.mm-bottom.mm-opening #mm-blocker,
  html.mm-bottom.mm-opening .mm-fixed-bottom {
    bottom: 880px; }
  html.mm-bottom.mm-opening .mm-fixed-top {
    top: -880px; } }

/*
	jQuery.mmenu z-position extension CSS
*/
html.mm-front.mm-opened .mm-page {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important; }

.mm-menu.mm-front,
.mm-menu.mm-next {
  -webkit-transition: none 0.4s ease;
  -moz-transition: none 0.4s ease;
  -ms-transition: none 0.4s ease;
  -o-transition: none 0.4s ease;
  transition: none 0.4s ease;
  -webkit-transition-property: top, right, bottom, left, -webkit-transform;
  -moz-transition-property: top, right, bottom, left, -moz-transform;
  -ms-transition-property: top, right, bottom, left, -o-transform;
  -o-transition-property: top, right, bottom, left, -o-transform;
  transition-property: top, right, bottom, left, transform; }

html.mm-front .mm-page,
html.mm-front #mm-blocker {
  z-index: 0; }

.mm-menu.mm-front {
  z-index: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); }

html.mm-opened.mm-next .mm-page {
  box-shadow: none; }

html.mm-opening .mm-menu.mm-front, html.mm-opening .mm-menu.mm-next {
  left: 0%; }

.mm-menu.mm-top.mm-front, .mm-menu.mm-top.mm-next {
  left: 0; }

html.mm-opening .mm-menu.mm-top.mm-front, html.mm-opening .mm-menu.mm-top.mm-next {
  left: 0;
  top: 0%; }

.mm-menu.mm-right.mm-front, .mm-menu.mm-right.mm-next {
  left: auto; }

html.mm-opening .mm-menu.mm-right.mm-front, html.mm-opening .mm-menu.mm-right.mm-next {
  left: auto;
  right: 0%; }

.mm-menu.mm-bottom.mm-front, .mm-menu.mm-bottom.mm-next {
  top: auto;
  left: 0; }

html.mm-opening .mm-menu.mm-bottom.mm-front, html.mm-opening .mm-menu.mm-bottom.mm-next {
  left: 0;
  bottom: 0%; }

.mm-menu.mm-front, .mm-menu.mm-next {
  left: -80%; }

@media all and (max-width: 175px) {
  .mm-menu.mm-front, .mm-menu.mm-next {
    left: -140px; } }

@media all and (min-width: 550px) {
  .mm-menu.mm-front, .mm-menu.mm-next {
    left: -440px; } }

.mm-menu.mm-top.mm-front, .mm-menu.mm-top.mm-next {
  top: -80%; }

@media all and (max-height: 175px) {
  .mm-menu.mm-top.mm-front, .mm-menu.mm-top.mm-next {
    top: -140px; } }

@media all and (min-height: 1100px) {
  .mm-menu.mm-top.mm-front, .mm-menu.mm-top.mm-next {
    top: -880px; } }

.mm-menu.mm-right.mm-front, .mm-menu.mm-right.mm-next {
  right: -80%; }

@media all and (max-width: 175px) {
  .mm-menu.mm-right.mm-front, .mm-menu.mm-right.mm-next {
    right: -140px; } }

@media all and (min-width: 550px) {
  .mm-menu.mm-right.mm-front, .mm-menu.mm-right.mm-next {
    right: -440px; } }

.mm-menu.mm-bottom.mm-front, .mm-menu.mm-bottom.mm-next {
  bottom: -80%; }

@media all and (max-height: 175px) {
  .mm-menu.mm-bottom.mm-front, .mm-menu.mm-bottom.mm-next {
    bottom: -140px; } }

@media all and (min-height: 1100px) {
  .mm-menu.mm-bottom.mm-front, .mm-menu.mm-bottom.mm-next {
    bottom: -880px; } }

html.mm-front .mm-fixed-top,
html.mm-front .mm-fixed-bottom,
html.mm-opening.mm-front .mm-fixed-top,
html.mm-opening.mm-front .mm-fixed-bottom {
  left: 0;
  right: auto; }

html.mm-front .mm-fixed-top,
html.mm-opening.mm-front .mm-fixed-top {
  top: 0; }

html.mm-front .mm-fixed-bottom,
html.mm-opening.mm-front .mm-fixed-bottom {
  bottom: 0; }

/*
	jQuery.mmenu themes extension CSS
*/
html.mm-opened.mm-light .mm-page {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.mm-ismenu.mm-light {
  background: #f3f3f3;
  color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-light .mm-list > li:after {
  border-color: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-light .mm-list > li > a.mm-subclose {
  background: rgba(255, 255, 255, 0.6);
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light .mm-list > li > a.mm-subopen:after, .mm-menu.mm-light .mm-list > li > a.mm-subclose:before {
  border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light .mm-list > li > a.mm-subopen:before {
  border-color: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-light .mm-list > li.mm-selected > a:not(.mm-subopen),
.mm-menu.mm-light .mm-list > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.6); }

.mm-menu.mm-light .mm-list > li.mm-label {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-light.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
.mm-menu.mm-light.mm-vertical .mm-list li.mm-opened > ul {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-light .mm-search input {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-light .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light em.mm-counter {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light .mm-list li.mm-label > div > div {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-light .mm-header {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.3); }
  .mm-menu.mm-light .mm-header a:before {
    border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light label.mm-toggle {
  background: rgba(0, 0, 0, 0.1); }
  .mm-menu.mm-light label.mm-toggle div {
    background: #f3f3f3; }

.mm-menu.mm-light input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

html.mm-opened.mm-white .mm-page {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.mm-ismenu.mm-white {
  background: #fff;
  color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-white .mm-list > li:after {
  border-color: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-white .mm-list > li > a.mm-subclose {
  background: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white .mm-list > li > a.mm-subopen:after, .mm-menu.mm-white .mm-list > li > a.mm-subclose:before {
  border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white .mm-list > li > a.mm-subopen:before {
  border-color: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-white .mm-list > li.mm-selected > a:not(.mm-subopen),
.mm-menu.mm-white .mm-list > li.mm-selected > span {
  background: rgba(0, 0, 0, 0.06); }

.mm-menu.mm-white .mm-list > li.mm-label {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-white.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
.mm-menu.mm-white.mm-vertical .mm-list li.mm-opened > ul {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-white .mm-search input {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-white .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white em.mm-counter {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white .mm-list li.mm-label > div > div {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-white .mm-header {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.3); }
  .mm-menu.mm-white .mm-header a:before {
    border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white label.mm-toggle {
  background: rgba(0, 0, 0, 0.1); }
  .mm-menu.mm-white label.mm-toggle div {
    background: #fff; }

.mm-menu.mm-white input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

html.mm-opened.mm-black .mm-page {
  box-shadow: none; }

.mm-ismenu.mm-black {
  background: #000;
  color: rgba(255, 255, 255, 0.6); }

.mm-menu.mm-black .mm-list > li:after {
  border-color: rgba(255, 255, 255, 0.2); }

.mm-menu.mm-black .mm-list > li > a.mm-subclose {
  background: rgba(255, 255, 255, 0.25);
  color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black .mm-list > li > a.mm-subopen:after, .mm-menu.mm-black .mm-list > li > a.mm-subclose:before {
  border-color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black .mm-list > li > a.mm-subopen:before {
  border-color: rgba(255, 255, 255, 0.2); }

.mm-menu.mm-black .mm-list > li.mm-selected > a:not(.mm-subopen),
.mm-menu.mm-black .mm-list > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.25); }

.mm-menu.mm-black .mm-list > li.mm-label {
  background: rgba(255, 255, 255, 0.15); }

.mm-menu.mm-black.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
.mm-menu.mm-black.mm-vertical .mm-list li.mm-opened > ul {
  background: rgba(255, 255, 255, 0.15); }

.mm-menu.mm-black .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6); }

.mm-menu.mm-black .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black em.mm-counter {
  color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black .mm-list li.mm-label > div > div {
  background: rgba(255, 255, 255, 0.15); }

.mm-menu.mm-black .mm-header {
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.3); }
  .mm-menu.mm-black .mm-header a:before {
    border-color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black label.mm-toggle {
  background: rgba(255, 255, 255, 0.2); }
  .mm-menu.mm-black label.mm-toggle div {
    background: #000; }

.mm-menu.mm-black input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

/*
 * jQuery FlexSlider v2.2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */
/* Browser Resets
*********************************/
.flex-container a:active,
.flex-container a:focus,
.flexslider a:active,
.flexslider a:focus {
  outline: none; }

.flex-control-nav,
.flex-direction-nav,
.slides {
  margin: 0;
  padding: 0;
  list-style: none; }

/* Icon Fonts
*********************************/
/* Font-face Icons */
@font-face {
  font-family: 'flexslider-icon';
  src: url("fonts/flexslider-icon.eot");
  src: url("fonts/flexslider-icon.eot?#iefix") format("embedded-opentype"), url("fonts/flexslider-icon.woff") format("woff"), url("fonts/flexslider-icon.ttf") format("truetype"), url("fonts/flexslider-icon.svg#flexslider-icon") format("svg");
  font-weight: normal;
  font-style: normal; }

/* FlexSlider Necessary Styles
*********************************/
.flexslider {
  margin: 0;
  padding: 0; }
  .flexslider .slides > li {
    display: none;
    -webkit-backface-visibility: hidden; }
  .flexslider .slides img {
    width: 100%;
    display: block; }

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flex-pauseplay span {
  text-transform: capitalize; }

/* Clearfix for the .slides element */
.slides:after {
  content: '\0020';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

html[xmlns] .slides {
  display: block; }

* html .slides {
  height: 1%; }

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {
  display: block; }

/* FlexSlider Default Theme
*********************************/
.flexslider {
  margin: 0 0 60px;
  background: #fff;
  border: 4px solid #fff;
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  zoom: 1; }

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

.loading .flex-viewport {
  max-height: 300px; }

.flexslider .slides {
  zoom: 1; }

.carousel li {
  margin-right: 5px; }

/* Direction Nav */
.flex-direction-nav {
  *height: 0; }
  .flex-direction-nav a {
    text-decoration: none;
    display: block;
    width: 40px;
    height: 40px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.8);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .flex-direction-nav .flex-prev {
    left: -50px; }
  .flex-direction-nav .flex-next {
    right: -50px;
    text-align: right; }

.flexslider:hover .flex-prev {
  opacity: 0.7;
  left: 10px; }

.flexslider:hover .flex-next {
  opacity: 0.7;
  right: 10px; }
  .flexslider:hover .flex-next:hover {
    opacity: 1; }

.flexslider:hover .flex-prev:hover {
  opacity: 1; }

.flex-direction-nav .flex-disabled {
  opacity: 0 !important;
  filter: alpha(opacity=0);
  cursor: default; }

.flex-direction-nav a:before {
  font-family: 'flexslider-icon';
  font-size: 40px;
  display: inline-block;
  content: '\f001'; }

.flex-direction-nav a.flex-next:before {
  content: '\f002'; }

/* Pause/Play */
.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000; }
  .flex-pauseplay a:before {
    font-family: 'flexslider-icon';
    font-size: 20px;
    display: inline-block;
    content: '\f004'; }
  .flex-pauseplay a:hover {
    opacity: 1; }
  .flex-pauseplay a.flex-play:before {
    content: '\f003'; }

/* Control Nav */
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center; }
  .flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline; }

.flex-control-paging li a {
  width: 11px;
  height: 11px;
  display: block;
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .flex-control-paging li a:hover {
    background: #333;
    background: rgba(0, 0, 0, 0.7); }
  .flex-control-paging li a.flex-active {
    background: #000;
    background: rgba(0, 0, 0, 0.9);
    cursor: default; }

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden; }
  .flex-control-thumbs li {
    width: 25%;
    float: left;
    margin: 0; }
  .flex-control-thumbs img {
    width: 100%;
    display: block;
    opacity: 0.7;
    cursor: pointer; }
    .flex-control-thumbs img:hover {
      opacity: 1; }
  .flex-control-thumbs .flex-active {
    opacity: 1;
    cursor: default; }

@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px; }
  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px; } }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
  filter: alpha(opacity=80); }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }
  .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #fff; }

.mfp-s-ready .mfp-preloader, .mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
  .mfp-arrow:before, .mfp-arrow:after, .mfp-arrow .mfp-b, .mfp-arrow .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after, .mfp-arrow .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before, .mfp-arrow .mfp-b {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after, .mfp-arrow-left .mfp-a {
    border-right: 17px solid #fff;
    margin-left: 31px; }
  .mfp-arrow-left:before, .mfp-arrow-left .mfp-b {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after, .mfp-arrow-right .mfp-a {
    border-left: 17px solid #fff;
    margin-left: 39px; }
  .mfp-arrow-right:before, .mfp-arrow-right .mfp-b {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.mfp-ie7 .mfp-img {
  padding: 0; }

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px; }

.mfp-ie7 .mfp-container {
  padding: 0; }

.mfp-ie7 .mfp-content {
  padding-top: 44px; }

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0; }

/**
 * @file
 * SMACSS Modules
 *
 * Adds modular sets of styles.
 *
 * Additional useful selectors can be found in Zen's online documentation.
 * https://drupal.org/node/1707736
 */
/**
 * Wireframes.
 */
.with-wireframes #header, .with-wireframes #main, .with-wireframes #content, .with-wireframes #navigation, .with-wireframes .region-sidebar-first, .with-wireframes .region-sidebar-second, .with-wireframes #footer, .with-wireframes .region-bottom {
  outline: 1px solid #ccc; }

.lt-ie8 .with-wireframes #header, .lt-ie8 .with-wireframes #main, .lt-ie8 .with-wireframes #content, .lt-ie8 .with-wireframes #navigation, .lt-ie8 .with-wireframes .region-sidebar-first, .lt-ie8 .with-wireframes .region-sidebar-second, .lt-ie8 .with-wireframes #footer, .lt-ie8 .with-wireframes .region-bottom {
  /* IE6/7 do not support the outline property. */
  border: 1px solid #ccc; }

/**
 * Accessibility features.
 */
/* element-invisible as defined by http://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
.element-invisible, .element-focusable {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px); }

#navigation .block-menu .block__title, #navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px); }

/* Turns off the element-invisible effect. */
.element-focusable:active, .element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto; }

/*
 * The skip-link link will be completely hidden until a user tabs to the link.
 */
#skip-link {
  margin: 0; }
  #skip-link a {
    display: block;
    width: 100%;
    padding: 2px 0 3px 0;
    text-align: center;
    background-color: #666;
    color: #fff; }
    #skip-link a:visited {
      display: block;
      width: 100%;
      padding: 2px 0 3px 0;
      text-align: center;
      background-color: #666;
      color: #fff; }

/**
 * Branding header.
 */
/* Wrapping link for logo. */
.header__logo {
  float: left;
  /* LTR */
  margin: 0;
  padding: 0; }

/* Logo image. */
.header__logo-image {
  vertical-align: bottom; }

/* Wrapper for website name and slogan. */
.header__name-and-slogan {
  float: left; }

/* The name of the website. */
.header__site-name {
  margin: 0;
  font-size: 2em;
  line-height: 1.5em; }

/* The link around the name of the website. */
.header__site-link:link, .header__site-link:visited {
  color: #000;
  text-decoration: none; }

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline; }

/* The slogan (or tagline) of a website. */
.header__site-slogan {
  margin: 0; }

/* The secondary menu (login, etc.) */
.header__secondary-menu {
  float: right;
  /* LTR */ }

/* Wrapper for any blocks placed in the header region. */
.header__region {
  /* Clear the logo. */
  clear: both; }

/**
 * Navigation bar.
 */
#navigation {
  /* Sometimes you want to prevent overlapping with main div. */
  /* overflow: hidden; */ }
  #navigation .block {
    margin-bottom: 0; }
  #navigation .links, #navigation .menu {
    margin: 0;
    padding: 0;
    text-align: left;
    /* LTR */ }
  #navigation .links li, #navigation .menu li {
    /* A simple method to get navigation links to appear in one line. */
    float: left;
    /* LTR */
    padding: 0 10px 0 0;
    /* LTR */
    list-style-type: none;
    list-style-image: none; }

/* Main menu and secondary menu links and menu block links. */
/**
 * Breadcrumb navigation.
 */
.breadcrumb ol {
  margin: 0;
  padding: 0; }

.breadcrumb li {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0; }

/**
 * Titles.
 */
.page__title, .node__title, .block__title, .comments__title, .comments__form-title, .comment__title {
  /* Comment title. */
  margin: 0; }

/**
 * Messages.
 */
.messages, .messages--status, .messages--warning, .messages--error {
  margin: 1.5em 0;
  padding: 10px;
  background: #43AC6A;
  color: #fff;
  border: 0; }

.messages *, .messages--status *, .messages--warning *, .messages--error * {
  color: #fff;
  padding: 0;
  margin: 0; }

.messages a, .messages--status a, .messages--warning a, .messages--error a {
  color: #fff;
  text-decoration: underline; }

.messages__list {
  margin: 0; }

.messages__item {
  list-style-image: none; }

/* Core/module installation error messages. */
.messages--error p.error {
  color: #333; }

/* System status report. */
.ok, .messages--status {
  background-color: #43AC6A; }

.warning, .messages--warning {
  background-color: #f08a24; }

.error, .messages--error {
  background-color: #f04124; }

/**
 * Tabs.
 */
/* Basic positioning styles shared by primary and secondary tabs. */
.tabs-primary, .tabs-secondary {
  overflow: hidden;
  *zoom: 1;
  background-color: #bbb;
  /* IE 9 and earlier don't understand gradients. */
  list-style: none;
  border-bottom: 1px solid #bbbbbb \0/ie;
  margin: 1.5em 0;
  padding: 0 2px;
  white-space: nowrap; }

.tabs-primary__tab {
  float: left;
  /* LTR */
  margin: 0 3px; }

.tabs-secondary__tab {
  float: left;
  /* LTR */
  margin: 0 3px; }
  .tabs-secondary__tab.is-active {
    float: left;
    /* LTR */
    margin: 0 3px; }

a.tabs-primary__tab-link, a.tabs-secondary__tab-link {
  border: 1px solid #e9e9e9;
  border-right: 0;
  border-bottom: 0;
  display: block;
  line-height: 1.5em;
  text-decoration: none; }

/* Primary tabs. */
.tabs-primary__tab {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  text-shadow: 1px 1px 0 white;
  border: 1px solid #bbbbbb;
  border-bottom-color: transparent;
  /* IE 9 and earlier don't understand gradients. */
  border-bottom: 0 \0/ie; }
  .tabs-primary__tab.is-active {
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    text-shadow: 1px 1px 0 white;
    border: 1px solid #bbbbbb;
    border-bottom-color: transparent;
    /* IE 9 and earlier don't understand gradients. */
    border-bottom: 0 \0/ie;
    border-bottom-color: white; }

a.tabs-primary__tab-link {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  color: #333;
  background-color: #dedede;
  letter-spacing: 1px;
  padding: 0 1em;
  text-align: center; }
  a.tabs-primary__tab-link.is-active {
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
    color: #333;
    background-color: #dedede;
    letter-spacing: 1px;
    padding: 0 1em;
    text-align: center; }
  a.tabs-primary__tab-link:hover, a.tabs-primary__tab-link:focus {
    background-color: #e9e9e9;
    border-color: #f2f2f2; }
  a.tabs-primary__tab-link:active, a.tabs-primary__tab-link.is-active {
    background-color: transparent;
    *zoom: 1;
    background-color: #e9e9e9;
    border-color: #fff; }

/* Secondary tabs. */
.tabs-secondary {
  font-size: .9em;
  /* Collapse bottom margin of ul.primary. */
  margin-top: -1.5em; }

.tabs-secondary__tab {
  margin: 0.75em 3px; }
  .tabs-secondary__tab.is-active {
    margin: 0.75em 3px; }

a.tabs-secondary__tab-link {
  -webkit-border-radius: 0.75em;
  -moz-border-radius: 0.75em;
  -ms-border-radius: 0.75em;
  -o-border-radius: 0.75em;
  border-radius: 0.75em;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  text-shadow: 1px 1px 0 white;
  background-color: #f2f2f2;
  color: #666;
  padding: 0 .5em; }
  a.tabs-secondary__tab-link.is-active {
    -webkit-border-radius: 0.75em;
    -moz-border-radius: 0.75em;
    -ms-border-radius: 0.75em;
    -o-border-radius: 0.75em;
    border-radius: 0.75em;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
    text-shadow: 1px 1px 0 white;
    background-color: #f2f2f2;
    color: #666;
    padding: 0 .5em; }
  a.tabs-secondary__tab-link:hover, a.tabs-secondary__tab-link:focus {
    background-color: #dedede;
    border-color: #999;
    color: #333; }
  a.tabs-secondary__tab-link:active, a.tabs-secondary__tab-link.is-active {
    text-shadow: 1px 1px 0 #333333;
    background-color: #666;
    border-color: #000;
    color: #fff; }

/**
 * Inline styles.
 */
/* List of links generated by theme_links(). */
.inline {
  display: inline;
  padding: 0; }
  .inline li {
    display: inline;
    list-style-type: none;
    padding: 0 1em 0 0;
    /* LTR */ }

/* The inline field label used by the Fences module. */
span.field-label {
  padding: 0 1em 0 0;
  /* LTR */ }

/**
 * 'More' links.
 */
.more-link {
  text-align: right;
  /* LTR */ }

.more-help-link {
  text-align: right;
  /* LTR */ }
  .more-help-link a {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA7UlEQVR42qWTPQqDQBCFcwSPkCNITpAj5AjeIm1uYpkyR7Cy2Mot7OwsBAsRwUKwmOwLGRle3EIy8PyBfZ/z3J2TiPylz8VWWZZpUB40BonRKyizaxkA88MYYiqCEgv4MTvnZJom0VqWRbz3FlJZgLYtqmEY1Lg9r+sKsIXcLSC3AC019H0vqLquLeC5AfiHYSGkcdAJimKIBQiJ4+CO92OAtm0FNc8zOjkMwE5Q63FAtbeg6zpAYvG8BWR7i5qmQYwY4MIHqYhE2DOPQWcGJBQF2XU72ZzyUeZ5GCNt5/hybJgYdAXsq5sOEE/jG6dC5IOqCXTmAAAAAElFTkSuQmCC");
    *background-image: url("../../images/help.png");
    background-position: 0 50%;
    /* LTR */
    background-repeat: no-repeat;
    padding: 1px 0 1px 20px;
    /* LTR */ }

/**
 * Pager.
 */
/* A list of page numbers when more than 1 page of content is available. */
.pager {
  clear: both;
  padding: 0;
  text-align: center; }

.pager-item, .pager-first, .pager-previous, .pager-next, .pager-last, .pager-ellipsis {
  /* The current page's list item. */
  display: inline;
  padding: 0 0.5em;
  list-style-type: none;
  background-image: none; }

.pager-current {
  /* The current page's list item. */
  display: inline;
  padding: 0 0.5em;
  list-style-type: none;
  background-image: none;
  font-weight: bold; }

/**
 * Blocks.
 */
/* Block wrapper. */
/**
 * Menus.
 */
.menu__item.is-leaf {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHBAMAAAA2fErgAAAAD1BMVEX///+/v7+Li4sAAADAwMBFvsw8AAAAAXRSTlMAQObYZgAAAB1JREFUCFtjYAADYwMGBmYVZSDhKAwkFJWhYiAAAB2+Aa/9ugeaAAAAAElFTkSuQmCC");
  *list-style-image: url("../../images/menu-leaf.png");
  list-style-type: square; }

.menu__item.is-expanded {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
  *list-style-image: url("../../images/menu-expanded.png");
  list-style-type: circle; }

.menu__item.is-collapsed {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
  /* LTR */
  *list-style-image: url("../../images/menu-collapsed.png");
  /* LTR */
  list-style-type: disc; }

/* The active item in a Drupal menu. */
.menu a.active {
  color: #000; }

/**
 * Marker.
 */
/* The 'new' or 'updated' marker. */
.new, .update {
  color: #c00;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent; }

/**
 * Unpublished note.
 */
/* The word 'Unpublished' displayed underneath the content. */
.unpublished {
  height: 0;
  overflow: visible;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent;
  color: #d8d8d8;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, 'Arial Narrow', Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  /* A very nice CSS3 property. */
  word-wrap: break-word; }

.lt-ie8 .node-unpublished > *, .lt-ie8 .comment-unpublished > * {
  /* Otherwise these elements will appear below the 'Unpublished' text. */
  position: relative; }

/**
 * Comments.
 */
/* Wrapper for the list of comments and its title. */
.comments {
  margin: 1.5em 0; }

/* Preview of the comment before submitting new or updated comment. */
.comment-preview {
  /* Drupal core will use a #ffffea background. See #1110842. */
  background-color: #ffffea; }

/* Wrapper for a single comment. */
.comment {
  /* Comment's permalink wrapper. */ }
  .comment ul {
    margin: 0; }
  .comment .permalink {
    text-transform: uppercase;
    font-size: 75%; }

/* Nested comments are indented. */
.indented {
  /* Drupal core uses a 25px left margin. */
  margin-left: 30px;
  /* LTR */ }

/**
 * Forms.
 */
/* Wrapper for a form element (or group of form elements) and its label. */
form .form-item {
  margin: 1.5em 0; }

.block.zoekveld .form-item {
  margin: 0; }

/* Pack groups of checkboxes and radio buttons closer together. */
.form-checkboxes .form-item, .form-radios .form-item {
  /* Drupal core uses '0.4em 0'. */
  margin: 0; }

/* Form items in a table. */
tr.odd .form-item, tr.even .form-item {
  margin: 0; }

/* Highlight the form elements that caused a form submission error. */
.form-item input.error, .form-item textarea.error, .form-item select.error {
  border: 1px solid #c00; }

.form-item .description {
  font-size: 0.85em; }

/* The descriptive help text (separate from the label). */
.form-type-radio .description, .form-type-checkbox .description {
  margin-left: 2.4em; }

/* The part of the label that indicates a required field. */
.form-required {
  color: #c00; }

/* Labels for radios and checkboxes. */
label.option {
  display: inline;
  font-weight: normal; }

/* Buttons used by contrib modules like Media. */
a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button; }

/* Password confirmation. */
.password-parent, .confirm-parent {
  margin: 0; }

/* Drupal's default login form block. */
#user-login-form {
  text-align: left;
  /* LTR */ }

/**
 * OpenID
 *
 * The default styling for the OpenID login link seems to assume Garland's
 * styling of list items.
 */
/* OpenID creates a new ul above the login form's links. */
.openid-links {
  /* Position OpenID's ul next to the rest of the links. */
  margin-bottom: 0; }

/* The 'Log in using OpenID' and 'Cancel OpenID login' links. */
.openid-link, .user-link {
  margin-top: 1.5em; }

html.js #user-login-form li.openid-link, #user-login-form li.openid-link {
  /* Un-do some of the padding on the ul list. */
  margin-left: -20px;
  /* LTR */ }

#user-login ul {
  margin: 1.5em 0; }

/**
 * Drupal admin tables.
 */
form th {
  text-align: left;
  /* LTR */
  padding-right: 1em;
  /* LTR */
  border-bottom: 3px solid #ccc; }

form tbody {
  border-top: 1px solid #ccc; }

form table ul {
  margin: 0; }

tr.even, tr.odd {
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 0.1em 0.6em; }

tr.even {
  background-color: #fff; }

.lt-ie8 tr.even th, .lt-ie8 tr.even td {
  /* IE doesn't display borders on table rows. */
  border-bottom: 1px solid #ccc; }

.lt-ie8 tr.odd th, .lt-ie8 tr.odd td {
  /* IE doesn't display borders on table rows. */
  border-bottom: 1px solid #ccc; }

/* Markup generated by theme_tablesort_indicator(). */
td.active {
  background-color: #ddd; }

td.checkbox {
  text-align: center; }

/* Center checkboxes inside table cell. */
th.checkbox {
  text-align: center; }

/* Drupal core wrongly puts this in system.menus.css. Since we override that, add it back. */
td.menu-disabled {
  background: #ccc; }

/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */
/* Suggestion list. */
#autocomplete .selected {
  background: #0072b9;
  color: #fff; }

/**
 * Collapsible fieldsets.
 *
 * @see collapse.js
 */
html.js .collapsible .fieldset-legend {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
  *background-image: url("../../images/menu-expanded.png");
  background-position: 5px 65%;
  /* LTR */
  background-repeat: no-repeat;
  padding-left: 15px;
  /* LTR */ }

html.js .collapsed .fieldset-legend {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
  /* LTR */
  *background-image: url("../../images/menu-collapsed.png");
  /* LTR */
  background-position: 5px 50%;
  /* LTR */ }

.fieldset-legend .summary {
  color: #999;
  font-size: 0.9em;
  margin-left: 0.5em; }

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
tr.drag {
  background-color: #fffff0; }

tr.drag-previous {
  background-color: #ffd; }

.tabledrag-toggle-weight {
  font-size: 0.9em; }

/**
 * TableSelect behavior.
 *
 * @see tableselect.js
 */
tr.selected td {
  background: #ffc; }

/**
 * Progress bar.
 *
 * @see progress.js
 */
.progress {
  font-weight: bold; }
  .progress .bar {
    background: #ccc;
    border-color: #666;
    margin: 0 0.2em;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px; }
  .progress .filled {
    background-color: #0072b9;
    background-image: url("../../images/progress.gif"); }

.row:after, .language-switcher:after, .content:after, .cards .view-content:after, .page-zoeken .content form:after, .sectie-overview:after {
  content: '';
  display: block;
  clear: both; }

@font-face {
  font-family: "tv-de-schakel";
  src: url("fonts/tv-de-schakel.eot");
  src: url("fonts/tv-de-schakel.eot?#iefix") format("embedded-opentype"), url("fonts/tv-de-schakel.woff") format("woff"), url("fonts/tv-de-schakel.ttf") format("truetype"), url("fonts/tv-de-schakel.svg#tv-de-schakel") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  content: attr(data-icon); }

[data-icon]:before,
[class^='icon-']:before,
.login-btn:before,
.footer ul li a.facebook:before,
.footer ul li a.twitter:before,
.footer ul li a.linkedin:before,
.footer ul li a.googleplus:before,
.footer ul li a.pinterest:before,
.footer ul li a.youtube:before,
blockquote:before,
.btn.btn-arrow:after,
.pager.pager--infinite-scroll a.btn-arrow:after,
.news-filter .views-exposed-widget a.btn-arrow:after,
.btn.btn-back:after,
.pager.pager--infinite-scroll a.btn-back:after,
.news-filter .views-exposed-widget a.btn-back:after,
[class*=' icon-']:before {
  font-family: "tv-de-schakel" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: -15%; }

.icon-lock:before, .login-btn:before {
  content: "\e000"; }

.icon-search:before {
  content: "\e001"; }

.icon-react-online-icon:before {
  content: "\e002"; }

.icon-arrow-right:before, .btn.btn-arrow:after, .pager.pager--infinite-scroll a.btn-arrow:after, .news-filter .views-exposed-widget a.btn-arrow:after {
  content: "\e003"; }

.icon-back:before, .btn.btn-back:after, .pager.pager--infinite-scroll a.btn-back:after, .news-filter .views-exposed-widget a.btn-back:after {
  content: "\e004"; }

.icon-quote:before, blockquote:before {
  content: "\e005"; }

*,
*:after,
*:before {
  box-sizing: border-box; }

.panel-flexible .panels-flexible-row,
.row {
  width: 984px;
  max-width: 100%;
  margin: 0 auto; }
  .panel-flexible .panels-flexible-row .panel-flexible .panels-flexible-row,
  .panel-flexible .panels-flexible-row .row,
  .row .panel-flexible .panels-flexible-row,
  .row .row {
    width: auto;
    max-width: none;
    min-width: 0;
    margin: 0 -20px; }
  .panel-flexible .panels-flexible-row.collapse,
  .row.collapse {
    width: 100%; }
    .panel-flexible .panels-flexible-row.collapse .column,
    .panel-flexible .panels-flexible-row.collapse .columns,
    .row.collapse .column,
    .row.collapse .columns {
      padding: 0; }
  .panel-flexible .panels-flexible-row .row,
  .row .row {
    width: auto;
    max-width: none;
    min-width: 0;
    margin: 0 -20px; }
    .panel-flexible .panels-flexible-row .row.collapse,
    .row .row.collapse {
      margin: 0; }

.column,
.columns,
.panel-flexible .panels-flexible-region.columns {
  float: left;
  padding: 0 20px;
  position: relative; }
  .column.centered,
  .columns.centered,
  .panel-flexible .panels-flexible-region.columns.centered {
    float: none;
    margin: 0 auto; }

[class*='column'] + [class*='column'].end {
  float: left; }

.one, .row .one {
  width: 8.33333%; }

.two, .row .two {
  width: 16.66667%; }

.three, .row .three {
  width: 25%; }

.four, .row .four {
  width: 33.33333%; }

.five, .row .five {
  width: 41.66667%; }

.six, .row .six {
  width: 50%; }

.seven, .row .seven {
  width: 58.33333%; }

.eight, .row .eight {
  width: 66.66667%; }

.nine, .row .nine {
  width: 75%; }

.ten, .row .ten {
  width: 83.33333%; }

.eleven, .row .eleven {
  width: 91.66667%; }

.twelve, .row .twelve {
  width: 100%; }

.row .offset-by-one {
  margin-left: 8.33333%; }

.row .offset-by-two {
  margin-left: 16.66667%; }

.row .offset-by-three {
  margin-left: 25%; }

.row .offset-by-four {
  margin-left: 33.33333%; }

.row .offset-by-five {
  margin-left: 41.66667%; }

.row .offset-by-six {
  margin-left: 50%; }

.row .offset-by-seven {
  margin-left: 58.33333%; }

.row .offset-by-eight {
  margin-left: 66.66667%; }

.row .offset-by-nine {
  margin-left: 75%; }

.row .offset-by-ten {
  margin-left: 83.33333%; }

.panels-flexible-row {
  padding-bottom: 0 !important; }

.row.twelve.columns {
  float: none;
  width: 984px; }

.column.centered {
  margin-left: auto;
  margin-right: auto;
  float: none; }

@media screen and (max-width: 424px) {
  .left,
  .right {
    float: none; }
  body {
    width: 100%;
    min-width: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0; }
  .row {
    width: auto;
    min-width: 0;
    margin-left: 0;
    margin-right: 0; }
  .column,
  .columns {
    width: auto !important;
    float: none; }
  .column:last-child,
  .columns:last-child {
    float: none; }
  [class*='column'] + [class*='column']:last-child {
    float: none; }
  .column:after,
  .column:before,
  .columns:after,
  .columns:before {
    content: '';
    display: table; }
  .column:after,
  .columns:after {
    clear: both; }
  .offset-by-one, .offset-by-two, .offset-by-three, .offset-by-four, .offset-by-five, .offset-by-six, .offset-by-seven, .offset-by-eight, .offset-by-nine, .offset-by-ten {
    margin-left: 0 !important; }
  .push-two, .push-three, .push-four, .push-five, .push-six, .push-seven, .push-eight, .push-nine, .push-ten {
    left: auto; }
  .pull-two, .pull-three, .pull-four, .pull-five, .pull-six, .pull-seven, .pull-eight, .pull-nine, .pull-ten {
    right: auto; }
  /* Mobile 4-column Grid */
  .row .mobile-one {
    width: 25% !important;
    float: left;
    padding: 0 20px; }
    .row .mobile-one:last-child {
      float: right; }
    .row .mobile-one.end {
      float: left; }
  .row.collapse .mobile-one {
    padding: 0; }
  .row .mobile-two {
    width: 50% !important;
    float: left;
    padding: 0 20px; }
    .row .mobile-two:last-child {
      float: right; }
    .row .mobile-two.end {
      float: left; }
  .row.collapse .mobile-two {
    padding: 0; }
  .row .mobile-three {
    width: 75% !important;
    float: left;
    padding: 0 20px; }
    .row .mobile-three:last-child {
      float: right; }
    .row .mobile-three.end {
      float: left; }
  .row.collapse .mobile-three {
    padding: 0; }
  .row .mobile-four {
    width: 100% !important;
    float: left;
    padding: 0 20px; }
    .row .mobile-four:last-child {
      float: right; }
    .row .mobile-four.end {
      float: left; }
  .row.collapse .mobile-four {
    padding: 0; }
  .push-one-mobile {
    left: 25%; }
  .pull-one-mobile {
    right: 25%; }
  .push-two-mobile {
    left: 50%; }
  .pull-two-mobile {
    right: 50%; }
  .push-three-mobile {
    left: 75%; }
  .pull-three-mobile {
    right: 75%; } }

.npl {
  padding-left: 0px !important; }

.npr {
  padding-right: 0px !important; }

@media screen and (max-width: 768px) {
  .col {
    width: 100% !important; } }

.vp {
  display: none !important; }

.vt {
  display: none !important; }

.hd {
  display: none !important; }

.vd {
  display: inherit !important; }

.np,
.npd {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.npl,
.npld {
  padding-left: 0px !important; }

.npr,
.nprd {
  padding-right: 0px !important; }

.npl {
  padding-left: 0px !important; }

.npr {
  padding-right: 0px !important; }

@media screen and (max-width: 1023px) {
  .hd {
    display: inherit !important; }
  .vd {
    display: none !important; }
  .vt {
    display: inherit !important; }
  .ht {
    display: none !important; }
  .npld,
  .nprd {
    padding: 0 20px !important; } }

@media screen and (max-width: 768px) {
  .hd {
    display: inherit !important; }
  .vd {
    display: none !important; }
  .vp {
    display: inherit !important; }
  .hp {
    display: none !important; }
  .npd,
  .npld,
  .nprd {
    padding: 0 20px !important; }
  .nplm,
  .npm {
    padding-left: 0px !important; }
  .npm,
  .nprm {
    padding-right: 0px !important; } }

/* Requires
   globals.css */
/* Standard Forms ---------------------- */
form {
  margin: 0 0 16px; }

.row form .row {
  margin: 0 -8px; }
  .row form .row .column,
  .row form .row .columns {
    padding: 0 8px; }
  .row form .row.collapse {
    margin: 0; }
    .row form .row.collapse .column,
    .row form .row.collapse .columns {
      padding: 0; }

label {
  font-size: ms(0);
  color: #4d4d4d;
  cursor: pointer;
  display: block;
  font-weight: 500;
  margin-bottom: 3px; }
  label.right {
    float: none;
    text-align: right; }
  label.inline {
    line-height: ms(0)24px;
    margin: 0 0 16px; }

.postfix,
.prefix {
  display: block;
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  height: ms(0)24px;
  line-height: ms(0)24px-1; }

a.button.postfix,
a.button.prefix {
  padding-left: 0;
  padding-right: 0;
  text-align: center; }

span.postfix,
span.prefix {
  background: #f2f2f2;
  border: 1px solid #cccccc; }

.prefix {
  left: 2px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  overflow: hidden; }

.postfix {
  right: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

input[type='date'],
input[type='datetime'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
textarea {
  background-color: #fff;
  font-family: inherit;
  border: 1px solid #cccccc;
  border-radius: 7px;
  color: rgba(0, 0, 0, 0.75);
  display: block;
  font-size: 14px;
  margin: 0 0 16px;
  padding: 8px 16px;
  height: ms(0)24px;
  width: 100%;
  transition: all 0.15s linear; }
  input[type='date'].oversize,
  input[type='datetime'].oversize,
  input[type='email'].oversize,
  input[type='number'].oversize,
  input[type='password'].oversize,
  input[type='search'].oversize,
  input[type='tel'].oversize,
  input[type='text'].oversize,
  input[type='time'].oversize,
  input[type='url'].oversize,
  textarea.oversize {
    font-size: ms(1);
    padding: 6px 8px; }
  input[type='date']:focus,
  input[type='datetime']:focus,
  input[type='email']:focus,
  input[type='number']:focus,
  input[type='password']:focus,
  input[type='search']:focus,
  input[type='tel']:focus,
  input[type='text']:focus,
  input[type='time']:focus,
  input[type='url']:focus,
  textarea:focus {
    background: #fff;
    border-color: #579bd9;
    box-shadow: #94b8d1 0 0 6px 0; }
  input[type='date'][disabled],
  input[type='datetime'][disabled],
  input[type='email'][disabled],
  input[type='number'][disabled],
  input[type='password'][disabled],
  input[type='search'][disabled],
  input[type='tel'][disabled],
  input[type='text'][disabled],
  input[type='time'][disabled],
  input[type='url'][disabled],
  textarea[disabled] {
    background-color: #ddd; }

textarea {
  height: auto; }

select {
  width: 100%; }

fieldset {
  border: solid 1px #ddd;
  border-radius: 3px;
  padding: 12px;
  margin: 18px 0; }
  fieldset legend {
    font-weight: bold;
    background: #fff;
    padding: 0 3px;
    margin: 0;
    margin-left: -3px; }

/* Errors */
.error input,
.error input:focus,
.error textarea,
.error textarea:focus,
input.error,
input.error:focus,
textarea.error,
textarea.error:focus {
  border-color: #c60f13;
  background-color: rgba(198, 15, 19, 0.1); }

.error input:focus,
.error textarea:focus,
input.error:focus,
textarea.error:focus {
  outline-color: #f5797c; }

.error label,
label.error {
  color: #c60f13; }

.error small,
small.error {
  display: block;
  padding: 6px 4px;
  margin-top: -17px;
  margin-bottom: 16px;
  background: #c60f13;
  color: #fff;
  font-size: ms(0)-2;
  font-weight: bold;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px; }

/* Correct FF custom dropdown height */
@-moz-document url-prefix() {
  form.custom div.custom.dropdown a.selector {
    height: 28px; } }

.lt-ie9 form.custom div.custom.dropdown a.selector {
  height: 28px; }

/*! Correct link in form label  */
label.option a.privacy-link {
  text-decoration: underline; }

.region-top-menu {
  float: right; }
  .region-top-menu .block {
    margin: 0;
    float: left; }
    .region-top-menu .block p {
      margin: 0;
      padding: 0; }
  .region-top-menu ul {
    list-style: none !important;
    margin-bottom: 0; }
    .region-top-menu ul li {
      list-style: none !important;
      float: left; }
      .region-top-menu ul li a {
        color: #000;
        padding: 0 1em;
        font-size: 10px;
        display: block; }

.menus {
  float: right; }

.language-switcher {
  padding: .3em .8em 0 0; }
  .language-switcher .language {
    border-right: 1px solid #efefef; }
    .language-switcher .language.last {
      border-right: 0; }
    .language-switcher .language a {
      font-size: 13px;
      padding: 0 1em;
      display: block; }
    .language-switcher .language.active a {
      color: #527145 !important;
      text-decoration: none !important;
      cursor: default; }

.login-btn {
  background: #527145;
  border: 1px solid #527145;
  border-radius: 20px 0 0 20px;
  color: #fff !important;
  border-right: 0 !important;
  padding-left: 1em;
  padding-right: 1em; }
  .login-btn:before {
    display: inline-block;
    margin-right: .5em; }
  .login-btn:hover {
    text-decoration: none;
    color: #fff;
    background: #3b5132;
    cursor: pointer; }

.hoofdmenu {
  z-index: 1;
  position: relative;
  margin: 0;
  float: right;
  width: 100%; }
  .hoofdmenu ul {
    float: right;
    margin: 0; }
    .hoofdmenu ul li {
      list-style: none !important;
      float: left;
      position: relative;
      margin-left: 20px; }
      .hoofdmenu ul li:hover ul {
        display: block; }
      .hoofdmenu ul li a {
        display: block;
        float: none;
        color: #000;
        font-family: "Merriweather", "Palatino Linotype", "Book Antiqua", Palatino, serif;
        font-weight: 400;
        border-right: 0;
        padding: 10px 0;
        font-size: 15px;
        text-align: center;
        white-space: nowrap;
        text-decoration: none; }
        .hoofdmenu ul li a.active, .hoofdmenu ul li a.is-active-trail {
          color: #527145 !important; }
        .hoofdmenu ul li a:hover {
          color: #527145 !important; }
      .hoofdmenu ul li ul {
        width: auto;
        display: none;
        position: absolute;
        left: 0;
        top: 44px;
        background: #fff; }
        .hoofdmenu ul li ul li {
          display: block;
          float: none;
          width: auto;
          white-space: nowrap;
          border: none; }
          .hoofdmenu ul li ul li a {
            border-radius: 0px !important;
            border: none;
            padding: 10px 15px;
            text-align: center;
            width: 100%;
            text-align: left; }
      .i18n-de .hoofdmenu ul li {
        margin-left: 10px;
        top: 3px; }
        .i18n-de .hoofdmenu ul li a {
          font-size: 13px; }
      .i18n-en .hoofdmenu ul li a {
        font-size: 14px; }

.mobile-menu {
  display: none; }

.mobile-menu-button {
  font-size: 24px;
  width: 1em;
  height: .85em;
  position: relative;
  margin: 0 auto;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer; }
  .mobile-menu-button span {
    font-size: 1em;
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #94b8d1;
    border-radius: 4px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out; }
    .mobile-menu-button span:nth-child(1) {
      top: 0; }
    .mobile-menu-button span:nth-child(2) {
      top: .3333em; }
    .mobile-menu-button span:nth-child(3) {
      top: .6666em; }
    .mm-opening .mobile-menu-button span:nth-child(1) {
      top: .3333em;
      transform: rotate(135deg); }
    .mm-opening .mobile-menu-button span:nth-child(2) {
      opacity: 0;
      left: -1em; }
    .mm-opening .mobile-menu-button span:nth-child(3) {
      top: .3333em;
      transform: rotate(-135deg); }

.mobile-menu-button:hover {
  text-decoration: none; }

@media screen and (max-width: 768px) {
  .hoofdmenu {
    display: none; }
  .mobile-menu-button {
    display: block; }
  .mm-menu.mm-right {
    background: #fff !important; }
  .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    margin: 0 !important;
    border-color: #efefef !important; }
  .mm-list > li > a,
  .mm-list > li > span {
    color: #000 !important; }
  .search-mobile form {
    padding: 0 20px !important; }
  .search-mobile input[type='text'] {
    background: rgba(0, 0, 0, 0.15);
    border: none;
    color: #fff; }
  .search-mobile input[type='submit'] {
    position: absolute;
    top: 5px;
    right: 5px;
    font-family: "tv-de-schakel";
    background: none;
    color: #fff;
    border: none;
    font-size: 24px; }
  .mm-list > li > a,
  .mm-list > li > span {
    color: #000; } }

.footer {
  margin: 100px 0 0;
  padding: 60px 0;
  background: #527145; }
  @media screen and (max-width: 768px) {
    .footer {
      margin-top: 0; } }
  .footer a,
  .footer p {
    color: #333;
    padding: 0; }
  .footer h2,
  .footer h3,
  .footer h4,
  .footer h5,
  .footer h6 {
    color: #73838f;
    font-size: 16px; }
  .footer ul {
    list-style-position: inside;
    list-style-type: none; }
    .footer ul li {
      color: #333; }
      .footer ul li a {
        color: #333; }
        .footer ul li a:before {
          display: inline-block;
          margin-right: .3em;
          text-decoration: none; }
        .footer ul li a.facebook:before {
          color: #3b5998; }
        .footer ul li a.twitter:before {
          color: #55acee; }
        .footer ul li a.linkedin:before {
          color: #0077b5; }
        .footer ul li a.googleplus:before {
          color: #dd4b39; }
        .footer ul li a.pinterest:before {
          color: #cc2127; }
        .footer ul li a.youtube:before {
          color: #cd201f; }

.footer-bottom {
  padding: 5px 0 65px;
  background: #fff; }
  @media screen and (max-width: 768px) {
    .footer-bottom {
      padding-bottom: 20px; } }
  .footer-bottom .block {
    margin: 0; }
  .footer-bottom ul {
    margin: 7px 0 0;
    list-style-position: inside; }
    .footer-bottom ul li {
      list-style-type: none;
      margin-left: 2em;
      display: inline-block; }
      .footer-bottom ul li:first-child {
        margin: 0;
        padding: 0; }
      .footer-bottom ul li a,
      .footer-bottom ul li a.active {
        color: #73838f;
        font-weight: 400; }
      .footer-bottom ul li a:hover {
        text-decoration: underline; }

.front .footer {
  border: 0; }

.react-online {
  color: #73838f;
  font-size: 20px;
  float: right;
  display: inline-block; }
  .react-online:hover {
    color: #55a514;
    text-decoration: none; }

@media screen and (max-width: 768px) {
  .footer h3,
  .footer h4 {
    padding-bottom: 0; }
  .footer .region-footerblok-1 ul li {
    width: 100%; }
  .footer-bottom .columns.left {
    float: left; }
  .footer-bottom .columns.right {
    float: right; }
  .footer-bottom ul li {
    float: none;
    display: block;
    padding: 0;
    margin: 0;
    border: 0; }
    .footer-bottom ul li a {
      line-height: 24px; }
  .react-online-media {
    text-indent: -9999px;
    line-height: 30px; }
  span.icon-rnm-react {
    text-indent: 0; } }

/*Standaard styling Drupal overwrite*/
.footer-bottom .menu__item.is-leaf,
.footer-bottom ul li a {
  list-style-image: none;
  list-style-type: none; }

.footer .footer-block-half {
  width: 50% !important;
  float: left !important; }

.content-top {
  margin-top: 0; }

.header {
  padding: 20px 0 10px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 110;
  background-color: #fff;
  height: 110px; }
  @media screen and (max-width: 768px) {
    .header {
      height: auto;
      padding: 10px 0 20px; } }

#page {
  padding-top: 110px; }
  #page .slider {
    margin-top: -110px; }
    @media screen and (max-width: 768px) {
      #page .slider {
        margin-top: 0; } }

.logo {
  position: relative; }
  .logo,
  .logo a,
  .logo img {
    display: block; }
  .logo .logo-img {
    opacity: 1; }
  .logo .logo-img-silhouette {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0; }

.block.zoekveld #search-api-page-search-form-search,
.block.zoekveld #search-api-page-search-form-zoeken,
.block.zoekveld #search-api-page-search-form-search-engine {
  position: relative; }

.block.zoekveld input[type='text'] {
  border: 1px solid #e6edf2;
  box-shadow: none;
  border-radius: 0 20px 20px 0;
  padding-left: 10px;
  padding-right: 35px;
  margin-bottom: 0;
  border-left: 0 !important;
  background-color: #fff; }

.block.zoekveld #edit-keys-1,
.block.zoekveld #edit-keys-2,
.block.zoekveld input.form-autocomplete {
  background-image: url("../images/ajax-loader.gif") right 50px no-repeat; }

.block.zoekveld #edit-keys-1.throbbing,
.block.zoekveld #edit-keys-2.throbbing,
.block.zoekveld input.throbbing {
  background-position: 84% 1ll0px; }

.block.zoekveld input[type='submit'] {
  font-family: "tv-de-schakel";
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  color: #527145;
  font-size: 20px;
  background: none;
  padding: 4px 13px;
  margin: 0; }
  .block.zoekveld input[type='submit']:hover {
    cursor: pointer; }

.block.zoekveld .form-text {
  float: right;
  min-width: 130px; }

.block.zoekveld input[type='text'],
.login-btn {
  padding-top: .3em;
  padding-bottom: .3em;
  font-size: 13px;
  font-weight: 400;
  display: inline-block;
  line-height: 1.5;
  height: 2.2em; }
  .block.zoekveld input[type='text'] .btn-text,
  .login-btn .btn-text {
    line-height: inherit; }

.has-slider .header {
  background-color: rgba(255, 255, 255, 0); }

.has-slider .logo .logo-img {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease; }

.has-slider .logo .logo-img-silhouette {
  opacity: 1;
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease; }

.has-slider .block.zoekveld input[type='text'] {
  border: 1px solid #fff;
  transition-property: border-color;
  transition-duration: 0.3s;
  transition-timing-function: ease; }

.has-slider .region-top-menu a,
.has-slider .hoofdmenu ul li a {
  color: #fff;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: ease; }

.has-slider .language-switcher .language.active a {
  color: rgba(255, 255, 255, 0.5) !important; }

#autocomplete {
  border: 1px solid #e6edf2;
  border-top: 0;
  width: 300px !important; }

#autocomplete ul li {
  padding: 5px 10px;
  border-bottom: 1px solid #e6edf2; }

#autocomplete ul li:hover {
  background: #e6edf2;
  cursor: pointer; }

@media screen and (max-width: 768px) {
  .header .row {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .header .row:after {
      content: none; }
  .header .logo-column {
    width: auto;
    max-width: 60%; }
  .header .menus {
    width: auto; }
  .header .top-menu,
  .header .mobile-menu-wrapper {
    display: inline-block; }
  .login-btn span {
    display: none; }
  .login-btn {
    display: block;
    background: none;
    border: 0;
    border-radius: 0;
    color: #94b8d1 !important;
    padding: 0;
    font-size: 25px;
    margin: 0 20px 0 0;
    height: auto; }
    .login-btn:before {
      display: block;
      color: inherit;
      margin: 60% 0 0;
      padding: 0; }
    .login-btn:hover {
      background: none !important; }
  .block.zoekveld input[type='text'] {
    float: none;
    padding-left: 1em;
    border-radius: 7px !important;
    border-left: 1px solid #e6edf2 !important; }
  .language-switcher {
    text-align: center; }
    .language-switcher .language {
      display: inline-block; } }

.header-image-wrapper {
  position: relative;
  max-height: 491px;
  overflow: hidden; }
  .header-image-wrapper:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 48.0427%; }
  .header-image-wrapper > .header-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

@media screen and (min-width: 768px) {
  .top-menu .region-top-menu,
  .hoofdmenu {
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease; }
  .logo-column {
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease; }
  .header {
    transition-property: padding, height, border-bottom-color, background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    border-bottom: 1px solid transparent; }
  .scrolled .top-menu .region-top-menu {
    transform: translateY(-200%); }
  .scrolled .hoofdmenu {
    transform: translateY(-55%); }
  .scrolled .logo-column {
    width: 160px; }
  .scrolled .header {
    padding: 5px 0 10px;
    height: 50px;
    border-bottom-color: #f1fbd3; } }

.overzicht:hover a {
  opacity: .8; }

.overzicht a {
  display: block;
  margin-bottom: 30px; }
  .overzicht a img {
    transition: all 0.4s ease; }
  .overzicht a .views-field-field-afbeelding .field-content,
  .overzicht a .views-field-field-afbeeldingen .field-content {
    overflow: hidden;
    display: block;
    line-height: 0; }
  .overzicht a .views-field-title .field-content {
    color: #000;
    font-size: 18px;
    padding: 10px 0;
    display: block; }
  .overzicht a:hover {
    text-decoration: none;
    opacity: 1 !important; }
    .overzicht a:hover img {
      transform: scale(1.1); }

.infowrap {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 20px; }
  .infowrap:hover {
    opacity: .8;
    text-decoration: none !important; }
  .infowrap .icoon {
    text-align: center;
    padding: 15px; }
    .infowrap .icoon .icon:before {
      display: inline-block;
      float: none;
      font-size: 34px;
      vertical-align: middle; }
      .large .infowrap .icoon .icon:before {
        margin: 0 0 10px;
        clear: both;
        line-height: initial;
        font-size: 86px; }
    .infowrap .icoon p {
      display: inline-block;
      margin: 0;
      padding: 0;
      font-size: 19px;
      line-height: 20px;
      vertical-align: middle; }
      .front .infowrap .icoon p {
        font-size: 22px;
        line-height: 30px; }
  .infowrap.border {
    border: 1px solid #e6edf2; }
  .infowrap.imageleft .image {
    width: 30%;
    float: left;
    min-height: 150px;
    background-size: cover !important;
    background-position: center !important; }
  .infowrap.imageleft .infoblok {
    width: 70%;
    float: left; }
  .infowrap.imagetop .image {
    width: 100%;
    float: left;
    min-height: 170px;
    background-size: cover !important;
    background-position: center !important; }
  .infowrap.imagetop .infoblok {
    width: 100%;
    float: left; }

div.infoblok {
  float: left;
  width: 100%;
  padding: 30px;
  background-size: cover !important;
  background-position: center !important; }
  div.infoblok h2 {
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 25px; }
  div.infoblok p {
    padding-bottom: 0;
    font-size: 14px;
    line-height: 20px; }
  div.infoblok a.btn, div.infoblok .pager.pager--infinite-scroll a, .pager.pager--infinite-scroll div.infoblok a, div.infoblok .news-filter .views-exposed-widget a, .news-filter .views-exposed-widget div.infoblok a {
    margin-bottom: 0; }
  div.infoblok.kleur-2ba6cb a,
  div.infoblok.kleur-2ba6cb blockquote,
  div.infoblok.kleur-2ba6cb blockquote p:before,
  div.infoblok.kleur-2ba6cb h1,
  div.infoblok.kleur-2ba6cb h2,
  div.infoblok.kleur-2ba6cb h3,
  div.infoblok.kleur-2ba6cb h4,
  div.infoblok.kleur-2ba6cb h5,
  div.infoblok.kleur-2ba6cb h6,
  div.infoblok.kleur-2ba6cb ol,
  div.infoblok.kleur-2ba6cb p,
  div.infoblok.kleur-2ba6cb ul, div.infoblok.kleur-e3e3e3 a,
  div.infoblok.kleur-e3e3e3 blockquote,
  div.infoblok.kleur-e3e3e3 blockquote p:before,
  div.infoblok.kleur-e3e3e3 h1,
  div.infoblok.kleur-e3e3e3 h2,
  div.infoblok.kleur-e3e3e3 h3,
  div.infoblok.kleur-e3e3e3 h4,
  div.infoblok.kleur-e3e3e3 h5,
  div.infoblok.kleur-e3e3e3 h6,
  div.infoblok.kleur-e3e3e3 ol,
  div.infoblok.kleur-e3e3e3 p,
  div.infoblok.kleur-e3e3e3 ul, div.infoblok.kleur-f2f2f2 a,
  div.infoblok.kleur-f2f2f2 blockquote,
  div.infoblok.kleur-f2f2f2 blockquote p:before,
  div.infoblok.kleur-f2f2f2 h1,
  div.infoblok.kleur-f2f2f2 h2,
  div.infoblok.kleur-f2f2f2 h3,
  div.infoblok.kleur-f2f2f2 h4,
  div.infoblok.kleur-f2f2f2 h5,
  div.infoblok.kleur-f2f2f2 h6,
  div.infoblok.kleur-f2f2f2 ol,
  div.infoblok.kleur-f2f2f2 p,
  div.infoblok.kleur-f2f2f2 ul {
    color: #fff; }
  div.infoblok.kleur-2ba6cb .btn, div.infoblok.kleur-2ba6cb .pager.pager--infinite-scroll a, .pager.pager--infinite-scroll div.infoblok.kleur-2ba6cb a, div.infoblok.kleur-2ba6cb .news-filter .views-exposed-widget a, .news-filter .views-exposed-widget div.infoblok.kleur-2ba6cb a, div.infoblok.kleur-e3e3e3 .btn, div.infoblok.kleur-e3e3e3 .pager.pager--infinite-scroll a, .pager.pager--infinite-scroll div.infoblok.kleur-e3e3e3 a, div.infoblok.kleur-e3e3e3 .news-filter .views-exposed-widget a, .news-filter .views-exposed-widget div.infoblok.kleur-e3e3e3 a, div.infoblok.kleur-f2f2f2 .btn, div.infoblok.kleur-f2f2f2 .pager.pager--infinite-scroll a, .pager.pager--infinite-scroll div.infoblok.kleur-f2f2f2 a, div.infoblok.kleur-f2f2f2 .news-filter .views-exposed-widget a, .news-filter .views-exposed-widget div.infoblok.kleur-f2f2f2 a {
    background: #fff;
    color: #c60f13; }
  div.infoblok.kleur-2ba6cb .btn.rand, div.infoblok.kleur-2ba6cb .pager.pager--infinite-scroll a.rand, .pager.pager--infinite-scroll div.infoblok.kleur-2ba6cb a.rand, div.infoblok.kleur-2ba6cb .news-filter .views-exposed-widget a.rand, .news-filter .views-exposed-widget div.infoblok.kleur-2ba6cb a.rand, div.infoblok.kleur-e3e3e3 .btn.rand, div.infoblok.kleur-e3e3e3 .pager.pager--infinite-scroll a.rand, .pager.pager--infinite-scroll div.infoblok.kleur-e3e3e3 a.rand, div.infoblok.kleur-e3e3e3 .news-filter .views-exposed-widget a.rand, .news-filter .views-exposed-widget div.infoblok.kleur-e3e3e3 a.rand, div.infoblok.kleur-f2f2f2 .btn.rand, div.infoblok.kleur-f2f2f2 .pager.pager--infinite-scroll a.rand, .pager.pager--infinite-scroll div.infoblok.kleur-f2f2f2 a.rand, div.infoblok.kleur-f2f2f2 .news-filter .views-exposed-widget a.rand, .news-filter .views-exposed-widget div.infoblok.kleur-f2f2f2 a.rand {
    background: transparent;
    border: 2px solid #fff;
    color: #fff; }
  div.infoblok .color-e3e3e3 {
    background: #e3e3e3; }
  div.infoblok .color-f2f2f2 {
    background: #f2f2f2; }
  div.infoblok .color-2ba6cb {
    background: #2ba6cb; }

.front .kleur-9FDCE7 {
  text-align: center; }
  .front .kleur-9FDCE7 p {
    font-size: 38px;
    line-height: initial; }

.icon:before {
  color: #fff;
  float: left;
  margin: 0 15px 0 0;
  line-height: 20px; }

.isotope-container,
.isotope-container .isotope-element {
  transition-duration: .8s; }

.isotope-container {
  transition-property: height, width; }

.isotope-container .isotope-element {
  transition-property: transform, opacity; }

.isotope .isotope-item.no-transition,
.isotope.no-transition,
.isotope.no-transition .isotope-item {
  transition-duration: 0s; }

.isotope-options {
  margin: 0 !important; }

.isotope {
  margin: 20px 0 0;
  display: inline-block;
  width: 100%; }
  .isotope > .view-content {
    margin: 0 -15px; }

.isotope-container {
  margin: 0 !important;
  padding: 0 !important; }
  .isotope-container .isotope-element {
    width: 25%;
    padding: 0 15px; }
    .drie .isotope-container .isotope-element {
      width: 33.333333333333333%; }
    @media screen and (max-width: 768px) {
      .isotope-container .isotope-element {
        float: left !important;
        width: 100% !important; } }

.taxonomy-widget {
  padding: 30px;
  background: #efefef;
  margin-bottom: 30px; }
  .taxonomy-widget h2 {
    margin-top: 0;
    color: #527145;
    font-size: 20px; }
  .taxonomy-widget ul li {
    list-style-type: square; }
    .taxonomy-widget ul li a {
      color: #000;
      text-decoration: none; }
      .taxonomy-widget ul li a.active {
        font-weight: 600; }
      .taxonomy-widget ul li a:hover {
        opacity: .5; }

a.anchor {
  padding-top: 180px;
  margin-top: -180px;
  float: left;
  width: 100%;
  display: block !important;
  visibility: hidden !important; }

.messages {
  float: left;
  width: 100%;
  clear: both; }

.snelmailer-widget {
  float: left;
  width: 100%;
  margin: 15px 0; }
  .snelmailer-widget .btn, .snelmailer-widget .pager.pager--infinite-scroll a, .pager.pager--infinite-scroll .snelmailer-widget a, .snelmailer-widget .news-filter .views-exposed-widget a, .news-filter .views-exposed-widget .snelmailer-widget a {
    font-size: 12px; }
  .snelmailer-widget .container-error {
    display: none !important; }

.views-exposed-form label {
  text-align: center;
  font-size: 20px;
  font-weight: 400; }

.views-exposed-form .form-radios {
  text-align: center; }

.views-exposed-form .views-exposed-widget {
  width: 100%; }
  .views-exposed-form .views-exposed-widget input[type='radio'] {
    display: none; }
  .views-exposed-form .views-exposed-widget input[type=radio]:checked + label {
    background: #8dac7f;
    color: #fff;
    border: 1px solid #8dac7f; }
  .views-exposed-form .views-exposed-widget .form-item {
    float: none;
    display: inline-block; }
  .views-exposed-form .views-exposed-widget .option {
    float: left;
    padding: 8px 20px;
    min-width: 180px;
    background: #fff;
    border-radius: 5px;
    text-align: center;
    margin: 10px 10px 0;
    color: #94b8d1;
    font-size: 15px;
    font-weight: 400;
    display: inline-block;
    border: 1px solid #94b8d1; }
    .views-exposed-form .views-exposed-widget .option:hover {
      background: #94b8d1;
      color: #fff; }

.pager {
  margin: 50px 0 0 !important;
  padding: 20px 0; }
  .pager li {
    background: none !important;
    padding-left: 0 !important;
    margin-left: 7px !important; }
  .pager .pager-current,
  .pager .pager-item a {
    background: #527145 !important;
    padding: 0 !important;
    text-align: center;
    border-radius: 2px;
    height: 18px;
    width: 18px;
    display: inline-block;
    line-height: 17px;
    color: #fff;
    font-size: 13px; }
  .pager .pager-item a {
    color: #94b8d1;
    background: none !important; }
  .pager .pager-first a,
  .pager .pager-last a,
  .pager .pager-next a,
  .pager .pager-previous a {
    color: #94b8d1;
    font-weight: bold; }
  .pager.pager--infinite-scroll {
    list-style: none; }

.homeblok {
  background: #efefef;
  float: left;
  width: 100%;
  padding: 15px; }

.ajax-progress {
  position: absolute;
  left: 50%;
  bottom: 0; }

.ajax-progress .throbber {
  background: url("../images/ajax-loader.gif");
  position: absolute;
  height: 11px;
  width: 16px;
  margin: 2px;
  z-index: 10; }

:focus {
  outline: none; }

.anchor {
  display: block !important;
  content: " " !important;
  margin-top: -200px !important;
  height: 200px !important;
  visibility: hidden !important; }

a {
  transition: all .4s ease; }

.media_embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 1rem;
  overflow: hidden; }

.media_embed.widescreen {
  padding-bottom: 56.34%; }

.media_embed.vimeo {
  padding-top: 0; }

.media_embed embed,
.media_embed iframe,
.media_embed object,
.media_embed video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

img {
  height: auto; }

embed,
img,
object {
  max-width: 100%; }

img {
  -ms-interpolation-mode: bicubic; }

#map_canvas img,
.map_canvas img {
  max-width: none !important; }

.card {
  background-color: #fff;
  border: 0;
  overflow: hidden;
  padding-bottom: 2em;
  margin-bottom: 40px;
  display: block;
  color: #000;
  position: relative;
  transform: translate3d(0, 0, 0);
  transition-property: transform, box-shadow;
  transition-duration: 0.3s;
  transition-timing-function: ease; }
  .card h1,
  .card h2,
  .card h3,
  .card h4 {
    margin: 0;
    padding: 0; }
  .card ul {
    margin: 1em !important; }
    .card ul li {
      margin: 0 !important;
      white-space: nowrap; }
      .card ul li a {
        white-space: normal; }
  .card .card-thumbnail {
    width: 100%;
    height: 150px;
    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: -3.8em; }
    @media screen and (max-width: 768px) {
      .card .card-thumbnail {
        margin-bottom: -4.3em; } }
    .card .card-thumbnail.no-img {
      height: 0; }
    .card .card-thumbnail .card-thumbnail-image {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      display: block;
      overflow: hidden;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transform: translate3d(0, 0, -1) scale(1, 1);
      transition-property: transform;
      transition-duration: 0.3s;
      transition-timing-function: ease; }
      .card .card-thumbnail .card-thumbnail-image .background {
        display: none; }
  .card .card-date {
    display: inline-block;
    background-color: #fff;
    padding: 0 9px;
    line-height: 30px;
    margin: 15px 9px;
    font-size: 13px;
    color: #94b8d1;
    font-weight: 600;
    border: 1px solid #efefef;
    position: relative;
    z-index: 2; }
    .card .card-date em {
      font-style: normal; }
  .card .card-body,
  .card .card-title {
    margin: 1em;
    display: block;
    line-height: 1.3;
    color: initial; }
  .card .card-image {
    margin-bottom: -3.8em;
    overflow: hidden; }
    .card .card-image,
    .card .card-image img {
      display: block; }
    .card .card-image img {
      transform: scale(1);
      backface-visibility: hidden;
      transition-property: transform;
      transition-duration: 0.3s;
      transition-timing-function: ease; }
  .card .card-title {
    color: #94b8d1;
    font-weight: 400; }
  .card .card-body p {
    padding: 0;
    margin: 0; }
  .card .card-label {
    display: block;
    position: absolute;
    bottom: 0;
    left: 10px;
    padding: 0 10px;
    line-height: 25px;
    background-color: #94b8d1;
    color: #fff;
    font-size: 13px; }
  .card:hover {
    text-decoration: none;
    transform: translate(-5px, -5px);
    box-shadow: 5px 5px 25px 0 rgba(100, 100, 100, 0.5); }
    .card:hover .card-title {
      text-decoration: underline; }
    .card:hover .card-thumbnail-image {
      transform: scale(1.1); }

.cards .view-header h2 {
  width: calc(100% - 26px);
  display: inline-block; }

.cards .view-content {
  margin: 0 -20px; }
  @media screen and (max-width: 424px) {
    .front .cards .view-content {
      white-space: nowrap;
      overflow-x: hidden; }
      .front .cards .view-content .card {
        white-space: normal;
        margin-bottom: 0; } }

.card-wrapper {
  width: 33.33333%;
  float: left;
  padding: 0 20px; }
  @media screen and (max-width: 424px) {
    .front .card-wrapper {
      width: 100%;
      float: none;
      display: none; }
      .front .card-wrapper:first-child {
        display: block; } }

.breadcrumbs a,
.breadcrumbs span {
  font-size: 14px;
  color: #94b8d1;
  font-weight: 600; }

.easy-breadcrumb_segment-separator {
  padding: 0 .8em; }

.front .breadcrumbs {
  display: none; }

.breadcrumbs {
  width: 984px;
  min-width: 0;
  margin: 20px auto 40px;
  max-width: 100%; }
  .breadcrumbs .easy-breadcrumb {
    padding: 0 20px; }

/*================================================
=== COOKIES ======================================
================================================*/
.koekjes-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  border-top: 1px solid rgba(82, 113, 69, 0.4);
  padding: 10px 0;
  z-index: 110; }
  .koekjes-wrapper h6,
  .koekjes-wrapper p {
    font-size: 12px;
    padding-bottom: 0; }
    @media screen and (max-width: 767px) {
      .koekjes-wrapper h6,
      .koekjes-wrapper p {
        font-size: 10px;
        line-height: 1.2; } }

.koekjes-content {
  padding: 0 20px; }
  @media screen and (max-width: 767px) {
    .koekjes-content {
      padding: 0 0 20px; } }

.koekjes-actions {
  *zoom: 1;
  margin: 10px 0;
  text-align: center; }
  .koekjes-actions:before, .koekjes-actions:after {
    content: " ";
    display: table; }
  .koekjes-actions:after {
    clear: both; }
  .koekjes-actions .btn, .koekjes-actions .pager.pager--infinite-scroll a, .pager.pager--infinite-scroll .koekjes-actions a, .koekjes-actions .news-filter .views-exposed-widget a, .news-filter .views-exposed-widget .koekjes-actions a {
    font-size: 14px;
    min-width: 140px;
    float: right; }
    @media screen and (max-width: 767px) {
      .koekjes-actions .btn, .koekjes-actions .pager.pager--infinite-scroll a, .pager.pager--infinite-scroll .koekjes-actions a, .koekjes-actions .news-filter .views-exposed-widget a, .news-filter .views-exposed-widget .koekjes-actions a {
        font-size: 12px;
        min-width: 100px;
        padding: 5px 10px; } }

.flexslider {
  margin: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none; }
  .flexslider ol,
  .flexslider ul {
    margin: 0;
    padding: 0; }
  .flexslider li {
    padding-left: 0;
    -webkit-backface-visibility: visible !important; }
  .flexslider .slides > li {
    overflow: hidden; }
  .flexslider .row {
    position: relative;
    margin: 0 auto; }
  .flexslider .flex-caption {
    position: absolute;
    border: 0;
    bottom: 100px; }
    .flexslider .flex-caption .caption-title-1 {
      font-size: 60px;
      font-weight: 900;
      font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
      color: #fff;
      line-height: 1.2; }
      @media screen and (max-width: 768px) {
        .flexslider .flex-caption .caption-title-1 {
          font-size: 30px; } }
    .flexslider .flex-caption .caption-title-2 span {
      font-size: 50px;
      font-weight: 400;
      font-family: "Merriweather", "Palatino Linotype", "Book Antiqua", Palatino, serif;
      color: #527145;
      background-color: #fff;
      padding: 0 .5em;
      margin-left: 1em;
      line-height: 1.5;
      display: inline-block; }
      @media screen and (max-width: 768px) {
        .flexslider .flex-caption .caption-title-2 span {
          font-size: 24px; } }
    .flexslider .flex-caption.bottom-left {
      left: 20px;
      right: auto; }
    .flexslider .flex-caption.bottom-right {
      right: 20px;
      left: auto; }
      .flexslider .flex-caption.bottom-right .caption-title-1,
      .flexslider .flex-caption.bottom-right .caption-title-2 {
        text-align: right; }
      .flexslider .flex-caption.bottom-right .caption-title-2 span {
        margin-left: 0;
        margin-right: 1em; }
  .flexslider .flex-control-nav {
    z-index: 99; }
    .flexslider .flex-control-nav li {
      margin: 0 4px; }
      .flexslider .flex-control-nav li a {
        width: 9px;
        height: 9px;
        background: #fff;
        opacity: .5;
        transition-property: opacity;
        transition-duration: 0.3s;
        transition-timing-function: ease; }
        .flexslider .flex-control-nav li a.flex-active {
          opacity: 1; }
  .flexslider .flex-direction-nav {
    display: none !important; }
    .flexslider .flex-direction-nav a {
      color: #fff;
      top: 0;
      height: 100%;
      margin: 0;
      width: 60px; }
      .flexslider .flex-direction-nav a:before {
        top: 50%;
        position: absolute;
        margin-top: -32px; }
    .flexslider .flex-direction-nav .flex-next {
      right: 0; }
      .flexslider .flex-direction-nav .flex-next:before {
        right: 0; }
    .flexslider .flex-direction-nav .flex-prev {
      left: 0; }
      .flexslider .flex-direction-nav .flex-prev:before {
        left: 0; }
  .flexslider .bg-caption,
  .flexslider .color-caption {
    display: none; }

@media screen and (max-width: 1023px) {
  .flexslider .flex-caption {
    font-size: 20px;
    line-height: 20px;
    padding: 15px; } }

@media screen and (max-width: 424px) {
  .flexslider .flex-caption {
    width: 100%;
    bottom: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 25px;
    right: auto !important;
    left: auto !important;
    border-radius: 0 !important; }
  .flexslider .slides img {
    width: auto;
    max-width: none;
    height: 300px;
    transform: translateX(-50%);
    position: relative;
    left: 50%; }
  .flexslider .flex-direction-nav a:before {
    top: 30%; } }

html body.admin-menu {
  margin-top: 59px !important; }
  html body.admin-menu .header {
    top: 59px; }

#user-login {
  max-width: 270px;
  margin: 50px auto;
  border: 1px solid #e6edf2;
  padding: 40px;
  background: #efefef; }

#user-login .description {
  display: none; }

#user-login .form-submit {
  float: none;
  margin-bottom: 0;
  width: 100%; }

#user-login .form-item {
  margin: 0; }

.panels-flexible-region-inside {
  padding: 0 !important; }

.admin-tabs ul {
  padding: 0;
  margin: 0;
  border: none !important;
  position: fixed;
  top: 29px;
  height: 30px;
  background: #333;
  width: 100%;
  z-index: 999; }

.not-logged-in.section-user {
  padding-top: 30px; }

.not-logged-in .admin-tabs ul {
  top: 0; }

#admin-menu-icon a.active-trail {
  background: none !important;
  border-radius: 0 !important; }

#admin-menu-icon a:hover {
  background-color: #333 !important; }

.admin-tabs .tabs {
  border: none; }

.admin-tabs ul li a {
  height: 22px !important;
  line-height: 19px;
  color: #fff;
  padding: 2px 10px 3px;
  letter-spacing: 0;
  font-size: 13px;
  margin: 4px 6px;
  border-radius: 10px !important; }

.admin-tabs ul li a:hover {
  text-decoration: none;
  border-radius: 10px; }

.admin-tabs ul li a:active,
.admin-tabs ul li a:focus {
  color: #fff;
  font-weight: normal;
  border-radius: 10px; }

#admin-menu {
  background: #55a514;
  box-shadow: none; }

#admin-menu li.admin-menu-account > a {
  background: none; }

#admin-menu-wrapper {
  padding: 5px 10px 0; }

#admin-menu > div > .dropdown > li > a {
  border-radius: 0 !important;
  margin: 0;
  padding: 2px 10px 7px; }

#admin-menu .dropdown .admin-menu-toolbar-category > a.active-trail {
  background: #61bc17; }

#edit-body-format,
.ckeditor_links {
  display: none; }

#admin-menu .dropdown .admin-menu-icon > a {
  padding: 2px 10px 8px; }

.admin-tabs .tabs-primary,
.admin-tabs .tabs-secondary {
  background-image: none; }

.admin-tabs .tabs-primary__tab,
.admin-tabs .tabs-primary__tab.is-active {
  border-radius: 0;
  text-shadow: none;
  border: none; }

.admin-tabs a.tabs-primary__tab-link.is-active,
.admin-tabs a.tabs-primary__tab-link:active {
  border-radius: 0;
  background-image: none;
  border: none; }

.admin-tab a.tabs-primary__tab-link.is-active,
.admin-tabs a.tabs-primary__tab-link {
  background: none;
  border: none;
  color: #fff;
  letter-spacing: 0;
  font-weight: 600; }

.admin-tabs .tabs-primary__tab.is-active a,
.admin-tabs ul li a:hover {
  background: #999 !important;
  padding: 2px 10px 3px;
  border-radius: 10px; }

.admin-tabs h2 {
  display: none; }

#admin-menu .dropdown li li a[href='/admin/structure/block/list/seven_override'] {
  display: none; }

#admin-menu .dropdown .admin-menu-icon > a span,
#admin-menu .dropdown .admin-menu-icon > a span {
  background: url("../images/react-logo.png");
  height: 14px;
  width: 14px; }

#admin-menu {
  background: #55a514;
  box-shadow: none; }

#admin-menu li.admin-menu-account > a {
  background: none; }

#admin-menu * {
  font: 12px 'Lucida Grande', 'Lucida Sans Unicode', sans-serif; }

.agenda-overzicht .item {
  padding: 20px 0 0;
  margin: 20px 0 0;
  border-top: 1px solid #EFEFEF; }
  .agenda-overzicht .item h2 {
    margin-top: 0; }

.agenda-detail .datum,
.agenda-overzicht .datum {
  margin: 0 0 10px; }

.agenda-detail .datum .datum-label,
.agenda-overzicht .datum .datum-label {
  font-weight: bold; }

.agenda-widget .widget-item {
  margin: 0 0 20px; }

.agenda-widget .agenda-title {
  font-weight: bold; }

/*================================================
=== BLOG --=======================================
================================================*/
/* Blog overview */
.section-nieuws.node-type-page,
.section-neuigkeiten.node-type-page,
.section-nachrichten.node-type-page,
.section-nouvelles.node-type-page,
.section-news.node-type-page {
  background: #eef0ec; }

.blog-item {
  float: left;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  border-bottom: 1px solid #ececec;
  padding-bottom: 30px; }
  .blog-item .col {
    width: 100%;
    float: right; }
  .blog-item .titel {
    padding-bottom: 10px;
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 8px;
    margin-top: 0; }
    .blog-item .titel a {
      font-size: 21px;
      line-height: 25px; }
  .blog-item .auteur,
  .blog-item .categorie,
  .blog-item .datum,
  .blog-item .tags {
    font-size: 13px;
    width: auto !important; }
    .blog-item .auteur a,
    .blog-item .categorie a,
    .blog-item .datum a,
    .blog-item .tags a {
      color: #999; }
  .blog-item .thumb-persoon {
    float: left;
    position: relative;
    width: 70px;
    height: 70px; }
  .blog-item .content {
    margin-top: 5px;
    border-top: 1px solid #ececec;
    padding-top: 10px; }
  .blog-item .lees-meer a,
  .blog-item .node-readmore a {
    float: left;
    text-decoration: none;
    font-weight: normal; }
    .blog-item .lees-meer a:hover,
    .blog-item .node-readmore a:hover {
      text-decoration: underline; }
  .blog-item .icon-font {
    font-family: "tv-de-schakel";
    font-size: 15px;
    font-weight: normal;
    float: left;
    padding-right: 5px; }

.view-tags a.active {
  background: #333;
  color: #fff; }

.view-categorie a.active {
  font-weight: bold; }

/* Blog overview met afbeelding*/
.blog-item.has-image .col {
  width: 65%; }

.blog-item.has-image .thumb.col {
  float: left;
  width: 30%;
  margin-right: 5%; }

.news-filter {
  position: absolute;
  right: 20px;
  top: 6px; }
  @media screen and (max-width: 424px) {
    .news-filter {
      position: relative;
      right: auto;
      top: auto; } }
  .news-filter .views-exposed-widget {
    padding: 0; }
    .news-filter .views-exposed-widget a {
      padding: 6px 20px !important;
      font-size: 15px !important;
      margin-left: 10px; }
      @media screen and (max-width: 424px) {
        .news-filter .views-exposed-widget a {
          padding: 6px 10px !important;
          margin-left: 0;
          margin-right: 5px;
          min-width: 0 !important; } }
  .news-filter .views-submit-button {
    padding: 0;
    float: none;
    width: auto; }
  .news-filter .ajax-progress {
    position: absolute;
    left: -20px;
    top: 6px; }

.categorie-teeltactualiteit:before {
  content: '';
  background: url("../images/teeltactualiteit.svg") center/cover no-repeat;
  position: absolute;
  height: 32px;
  width: 32px;
  top: 10px;
  left: 10px; }

.categorie-teeltactualiteit .card-date {
  color: #CF8888; }

.categorie-teeltactualiteit .card-title {
  color: #A53441; }

.categorie-teeltactualiteit .card-label {
  background-color: #CF8888; }

.categorie-algemeen .card-date,
.categorie-nieuws .card-date {
  color: rgba(82, 113, 69, 0.4); }

.categorie-algemeen .card-title,
.categorie-nieuws .card-title {
  color: #527145; }

.categorie-algemeen .card-label,
.categorie-nieuws .card-label {
  background-color: rgba(82, 113, 69, 0.4);
  display: none; }

.teeltactualiteit-icon {
  z-index: 110;
  position: fixed;
  bottom: 10px;
  right: 10px; }
  @media screen and (max-width: 424px) {
    .teeltactualiteit-icon {
      bottom: auto;
      left: auto;
      right: 110px;
      top: 25px; }
      .admin-menu .teeltactualiteit-icon {
        top: 82px; } }
  .teeltactualiteit-icon a,
  .teeltactualiteit-icon img {
    display: block; }
  .teeltactualiteit-icon img {
    animation: 2s infinite alternate pulse; }
    @media screen and (max-width: 424px) {
      .teeltactualiteit-icon img {
        animation: none; } }

@keyframes pulse {
  from {
    transform: scale(1.4); }
  to {
    transform: scale(1); } }

/* Blog Detail */
.blog-detail .news-date .field-item {
  display: inline-block;
  background-color: #fff;
  padding: 0 9px;
  line-height: 30px;
  margin: 0 0 15px;
  font-size: 13px;
  color: #94b8d1;
  font-weight: 600;
  border: 1px solid #efefef;
  position: relative;
  z-index: 2; }
  .blog-detail .news-date .field-item em {
    font-style: normal; }

.blog-detail .blog-item {
  border: none;
  margin: 0 !important;
  padding-bottom: 10px;
  float: left;
  width: 100%; }
  .blog-detail .blog-item h1 {
    margin-top: 0; }
  .blog-detail .blog-item .thumb {
    margin-top: 10px;
    float: left;
    width: 100%; }
    .blog-detail .blog-item .thumb img {
      width: 100%; }
  .blog-detail .blog-item .content {
    margin-top: 0;
    padding-top: 0;
    border: 0; }
  .blog-detail .blog-item .auteur,
  .blog-detail .blog-item .categorie,
  .blog-detail .blog-item .datum,
  .blog-detail .blog-item .tags {
    float: left;
    margin-top: 25px;
    margin-right: 20px;
    color: #999; }

.blog-detail .auteur {
  margin-left: 10px; }

.blog-detail .share-this {
  float: right; }

/* MASONRY */
.view-blog.view-id-blog.view-display-id-block_5,
.view-id-cases {
  margin: -10px -10px 50px; }

.masonry-item {
  float: left;
  margin: 0 0 40px;
  padding: 0; }
  .masonry-item item-wrapper {
    padding: 10px; }
    .masonry-item item-wrapper item-inner {
      padding: 0;
      position: relative;
      background: white;
      overflow: hidden;
      transition: all 200ms ease-in; }
  .masonry-item .item-link {
    display: block;
    width: 100%;
    height: 100%; }
    .masonry-item .item-link:hover,
    .masonry-item .item-link .item-inner {
      box-shadow: 0 0 1px #00a1e5;
      -webkit-box-shadow: 0 0 1px #d9e7ec;
      -moz-box-shadow: 0 0 1px #00a1e5; }
  .masonry-item .datum {
    display: block;
    color: #999; }
  .masonry-item .titel {
    font-size: 32px;
    line-height: 36px;
    font-weight: 100;
    padding: 10px 0;
    display: block;
    padding: 20px 40px 10px; }
  .masonry-item .content {
    padding: 0 40px 20px;
    display: block; }
  .masonry-item .thumb {
    display: block; }
    .masonry-item .thumb .small img {
      float: left; }
  .masonry-item .tags li {
    list-style: none; }
  .masonry-item .tags ul li {
    float: left;
    padding: 0;
    margin-right: 3px;
    border: 1px solid #80b8f1;
    color: #80b8f1;
    padding: 3px 6px;
    border-radius: 5px; }

.masonry-item.extended {
  width: 66.6%; }
  .masonry-item.extended .item-inner {
    background: #fff; }
  .masonry-item.extended .datum {
    color: #fff; }
  .masonry-item.extended .tags li {
    color: #fff;
    border: 1px solid #fff; }

.masonry-item.quotes .item-inner {
  background: #2ccefd; }

.masonry-item.quotes .content {
  font-size: 22px;
  line-height: 25px;
  font-weight: 100;
  color: #fff;
  padding: 20px 40px; }

.masonry-item.quotes .quote-klant {
  float: right;
  clear: both;
  font-size: 18px;
  font-weight: 100;
  color: #fff;
  padding: 0 40px; }

.masonry-item.quotes .quote-functie {
  float: right;
  clear: both;
  font-weight: 100;
  font-size: 13px;
  padding-top: 3px;
  color: #fff;
  padding: 5px 40px 20px; }

@media screen and (max-width: 1023px) {
  .masonry-item {
    width: 50%; }
  .masonry-item.extended {
    width: 100%; } }

@media screen and (max-width: 768px) {
  .masonry-item {
    width: 100%; }
  .masonry-item.extended {
    width: 100%; } }

/*================================================
=== Comments
================================================*/
.comment-form {
  float: left;
  width: 100%;
  border-top: 2px solid #efefef;
  padding: 30px 0 0;
  margin: 15px 0 0; }
  .comment-form .description,
  .comment-form .form-item-homepage {
    display: none; }
  .comment-form ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #7d7979; }
  .comment-form :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #7d7979;
    opacity: 1; }
  .comment-form ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #7d7979;
    opacity: 1; }
  .comment-form :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #7d7979; }
  .comment-form .columns {
    padding: 0 15px !important; }
  .comment-form .row {
    margin: 0 -15px !important; }
  .page-comment-edit .comment-form label {
    display: block; }
  .comment-form .form-item-subject {
    display: none; }

.comments {
  float: left;
  width: 100%; }
  .comments .comment {
    border-top: 1px solid #efefef;
    padding: 20px 0 0;
    margin: 20px 0 0; }
    .comments .comment .submitted strong span {
      color: #212020; }
    .comments .comment time {
      color: #959191; }
  .comments .indented .comment {
    padding: 0 0 0 20px;
    margin: 20px 0 10px;
    border: 0;
    border-left: 2px solid #B4B1B1; }

.comment .links li {
  padding-left: 0; }
  .comment .links li a {
    color: #807F7F;
    font-size: 12px; }
  .comment .links li:before {
    display: none; }

.comment-add {
  display: none !important; }

.back-to-overview {
  width: 50%;
  float: left; }

.pane-sharethis {
  width: 50%;
  float: right; }

.sharethis-wrapper {
  text-align: right;
  margin: 4em 0 0;
  padding: 0.45em 0; }
  @media screen and (max-width: 768px) {
    .sharethis-wrapper {
      padding-top: 0.85em; } }
  .sharethis-wrapper span {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-left: 0.5em;
    cursor: pointer;
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease; }
    .sharethis-wrapper span:hover {
      opacity: 0.8; }

.st_facebook_custom {
  background-image: url("../images/social/facebook.svg"); }

.st_twitter_custom {
  background-image: url("../images/social/twitter.svg"); }

.st_linkedin_custom {
  background-image: url("../images/social/linkedin.svg"); }

/* Blog overview */
.case-item {
  float: left;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px; }
  .case-item .col {
    width: 100%;
    float: right; }
  .case-item .titel {
    padding-bottom: 10px;
    border-top: 1px solid #f1f1f1;
    padding-top: 10px;
    margin-top: 8px; }
    .case-item .titel a {
      font-size: 21px;
      line-height: 25px; }
  .case-item .auteur,
  .case-item .categorie {
    font-size: 11px; }
  .case-item .klant {
    border-top: 1px solid #f1f1f1;
    padding-top: 10px; }
  .case-item .tags {
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 10px; }
  .case-item .lees-meer a {
    float: right;
    margin-top: 10px; }

/* Blog overview met afbeelding*/
.case-item.has-image .col {
  width: 30%; }

.case-item.has-image .thumb.col {
  float: left;
  width: 65%;
  margin-right: 5%; }

.case-detail .case-item {
  margin: 0 !important;
  border: none; }
  .case-detail .case-item .lees-meer a {
    margin-bottom: 10px; }
  .case-detail .case-item.has-image .col {
    width: 100%; }

.vacature-overzicht .sidebar {
  margin-top: 20px; }

.vacature-overzicht .blog-item .meta a {
  color: #000; }

.vacature-overzicht .blog-item .lees-meer {
  margin-top: 20px; }

.vacature-overzicht .blog-item .content-wrap {
  width: 100%; }

.vacature-overzicht .block {
  padding: 40px;
  background: #fff;
  margin: 0 0 30px; }

.vacature-homepage .blog-item .meta a {
  color: #000; }

.vacature-homepage .blog-item .lees-meer {
  margin-top: 20px; }

.vacature-homepage .blog-item .content-wrap {
  width: 100%; }

@media screen and (max-width: 1023px) {
  .vacature-detail .content .first,
  .vacature-detail .content .last {
    width: 50% !important; } }

@media screen and (max-width: 768px) {
  .vacature-detail .content .first,
  .vacature-detail .content .last {
    width: 100% !important; } }

.vacature-detail .sidebar h1,
.vacature-detail .sidebar h2,
.vacature-detail .sidebar h3,
.vacature-detail .sidebar h4,
.vacature-detail .sidebar h5,
.vacature-detail .sidebar h6 {
  font-size: 20px;
  line-height: 20px;
  margin-top: 0; }

.vacature-detail .meta-info {
  font-weight: bold;
  color: #000; }

.vacature-detail form {
  margin: 0; }

.vacature-detail .persoon.info {
  background: #efefef;
  padding: 30px;
  float: left;
  width: 100%;
  margin: 0; }
  .vacature-detail .persoon.info .view-content {
    margin: 10px 0 0;
    padding: 0 0 30px; }
  .vacature-detail .persoon.info .view-header {
    padding: 0; }
    .vacature-detail .persoon.info .view-header p {
      font-size: inherit;
      font-weight: inherit; }
  .vacature-detail .persoon.info .titel {
    font-weight: bold;
    color: #000; }

.vacature-detail .koppeling-persoon {
  padding: 30px 30px 0;
  float: left;
  width: 100%;
  background: #efefef; }

.vacature-detail .field-name-field-tekst {
  background: #565656;
  padding: 30px;
  float: left;
  width: 100%;
  margin: 0 0 30px; }
  .vacature-detail .field-name-field-tekst ol,
  .vacature-detail .field-name-field-tekst ul {
    margin-bottom: 0; }
  .vacature-detail .field-name-field-tekst * {
    color: #fff; }

.vacature-detail .field-name-field-webformulier-invoegen {
  background: #efefef;
  padding: 0 30px 30px;
  float: left;
  width: 100%;
  margin: 0; }

.vacature-detail .container.content {
  background: #fff !important; }
  .vacature-detail .container.content h1 {
    margin-top: 0; }

.vacature-detail .content-top {
  padding-top: 0; }

.vacature-detail .meta {
  background: #efefef;
  padding: 15px;
  color: #fff;
  float: left; }
  .vacature-detail .meta div {
    color: #fff;
    float: left;
    width: 100%;
    font-weight: bold;
    margin-bottom: 3px; }
    .vacature-detail .meta div .views-label {
      float: left;
      margin-right: 3px; }
    .vacature-detail .meta div .field-content {
      float: left;
      width: auto; }
  .vacature-detail .meta .views-field-title span {
    font-size: 18px;
    margin-bottom: 5px; }
  .vacature-detail .meta .views-field-field-evt-extra-opmerking div {
    font-weight: normal; }

.vacature-detail .event-foto {
  float: left;
  width: 100%;
  margin-bottom: 40px; }
  .vacature-detail .event-foto img {
    float: left;
    width: 100%; }

.vragen-overzicht .vraag {
  font-weight: bold; }

.vragen-overzicht .views-field-body p {
  color: #444; }

.vragen-overzicht .ui-accordion-content {
  height: auto !important;
  background: #fff;
  border: 0;
  padding: 10px 10px 10px 15px; }

.vragen-overzicht .ui-accordion-header {
  background: none; }

.vragen-overzicht .ui-icon-triangle-1-e {
  background-position: -37px -15px; }

.vragen-overzicht .ui-icon-triangle-1-s {
  background-position: -68px -15px; }

.vragen-overzicht .ui-accordion .ui-accordion-icons {
  padding-left: 15px; }

.vragen-overzicht .ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  top: 47% !important;
  left: 0; }

.vragen-overzicht .ui-state-default,
.vragen-overzicht .ui-widget-content .ui-state-default,
.vragen-overzicht .ui-widget-header .ui-state-default {
  border: 0; }

/*OVERVIEW PAGINA*/
.fotoalbum-overview ul {
  list-style: none;
  padding: 0;
  margin: 0; }
  .fotoalbum-overview ul li {
    padding: 0 15px;
    float: left;
    margin-bottom: 30px;
    width: 25%;
    list-style: none; }
    .fotoalbum-overview ul li a {
      overflow: hidden; }
    .fotoalbum-overview ul li img {
      width: 100%;
      transition: all 0.4s ease; }
    .fotoalbum-overview ul li:hover img {
      transform: scale(1.1); }

@media screen and (max-width: 1023px) {
  .fotoalbum-overview ul li,
  .fotoalbum-sectie ul li {
    width: 50%; } }

@media screen and (max-width: 768px) {
  .fotoalbum-sectie ul li:nth-child(odd) {
    padding-left: 0px !important; }
  .fotoalbum-sectie ul li:nth-child(even) {
    padding-right: 0px !important; }
  .sectie-item .fotoalbum .content {
    width: 100% !important; }
  .sectie-item .formulier .content {
    width: 100% !important; }
  .sectie-item .webform-component-fieldset {
    width: 100% !important; }
  .sectie-item .view-fotoalbum {
    width: 100%; } }

.fotoalbum-overview .thumb.col a,
.fotoalbum-sectie .thumb.col a,
a.popup {
  position: relative;
  display: block;
  line-height: 0;
  overflow: hidden; }
  .fotoalbum-overview .thumb.col a:hover img,
  .fotoalbum-sectie .thumb.col a:hover img,
  a.popup:hover img {
    transform: scale(1.1); }
  .fotoalbum-overview .thumb.col a img,
  .fotoalbum-sectie .thumb.col a img,
  a.popup img {
    position: relative;
    z-index: 0;
    transform: scale(1);
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease; }
  .fotoalbum-overview .thumb.col a span.icon,
  .fotoalbum-sectie .thumb.col a span.icon,
  a.popup span.icon {
    background: url("../images/enlarge_icon_crop.png") no-repeat;
    position: absolute;
    right: 8px;
    bottom: 8px;
    display: block;
    z-index: 1;
    height: 12px;
    width: 13px; }

/* OVERVIEW */
.persoon {
  margin: 0 -15px; }
  .persoon .view-header {
    padding: 0 15px; }
    .persoon .view-header p {
      font-size: 16px;
      font-weight: bold; }
  .persoon .columns {
    float: left !important; }
  .persoon .thumb {
    float: left;
    width: 100%; }
  .persoon .titel {
    float: left;
    width: 100%; }
  .persoon .functie {
    float: left;
    width: 100%; }
  .persoon .email {
    float: left;
    width: 100%; }
  .persoon .info {
    margin: 0; }

/* DETAIL */
/*Product overview*/
.product-overview .row {
  margin-bottom: 50px; }

.product-overview [class*='column'] + [class*='column']:last-child {
  float: left; }

.product-item .col {
  float: left;
  width: 100%; }

.product-item .thumb img {
  width: 100%; }

.product-item .lees-meer {
  border-top: 1px solid #f1f1f1;
  padding-top: 5px;
  margin-top: 5px; }
  .product-item .lees-meer a {
    float: right; }

/*Product detail*/
.product-detail {
  margin: 0 -15px; }
  .product-detail .col {
    width: 50%;
    float: left;
    padding: 0 15px; }
  .product-detail .thumb img {
    width: 100%; }
  .product-detail .prijs {
    font-size: 30px;
    line-height: 40px;
    border-top: 1px solid #f1f1f1;
    padding-top: 7px;
    margin-top: 10px;
    text-align: right; }

.page-zoeken {
  background: #eef0ec; }
  .page-zoeken .content form {
    background-color: #fff;
    padding: 2em 1em;
    margin-bottom: 4em; }
    .page-zoeken .content form .form-item {
      margin: 0;
      width: calc(100% - 160px - 20px);
      float: left;
      clear: none;
      vertical-align: middle; }
      .page-zoeken .content form .form-item label {
        display: none; }
      .page-zoeken .content form .form-item .form-text {
        margin: 0; }
    .page-zoeken .content form .form-submit {
      float: right;
      clear: none;
      vertical-align: middle;
      margin: 0;
      font-size: 18px; }
  .page-zoeken .breadcrumbs .easy-breadcrumb_segment-separator:last-of-type,
  .page-zoeken .breadcrumbs .easy-breadcrumb_segment:last-of-type {
    display: none; }

.search-results {
  margin: 0 !important; }

.search-info {
  display: none; }

.search-result {
  background-color: #fff;
  padding: 2em 1em;
  margin-bottom: 10px !important; }

.search-results .search-snippet-info {
  padding-left: 0;
  font-size: 13px; }
  .search-results .search-snippet-info p {
    font-size: inherit; }

.search-api-page-results {
  float: left;
  width: 100%; }
  .search-api-page-results h2 + ul {
    display: none; }

.tweets .tweets-item {
  float: left;
  width: 100%;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee; }
  .tweets .tweets-item .datum {
    color: #999;
    font-size: 11px;
    display: block; }
  .tweets .tweets-item .content a {
    text-decoration: underline; }

.webform-progressbar {
  margin-left: 17px;
  margin-bottom: 20px;
  margin-top: 30px;
  display: inline-block; }
  .webform-progressbar .webform-progressbar-inner {
    border-color: #2b2b2b;
    background-color: #2b2b2b; }
  .webform-progressbar .webform-progressbar-page {
    border: 1px solid #2b2b2b; }
  .webform-progressbar .webform-progressbar-page.current {
    background-color: #2b2b2b; }
  .webform-progressbar .webform-progressbar-page.completed {
    background-color: #2b2b2b; }
  .webform-progressbar .webform-progressbar-outer {
    border: 1px solid #2b2b2b; }

.webform-client-form input[type=date],
.webform-client-form input[type=datetime],
.webform-client-form input[type=email],
.webform-client-form input[type=number],
.webform-client-form input[type=password],
.webform-client-form input[type=search],
.webform-client-form input[type=tel],
.webform-client-form input[type=text],
.webform-client-form input[type=time],
.webform-client-form input[type=url],
.webform-client-form textarea {
  margin: 0; }

.webform-client-form .form-item {
  margin: 0 0 10px;
  float: left;
  width: 100%;
  position: relative; }
  .webform-client-form .form-item .form-item {
    margin: 0; }
  .webform-client-form .form-item label {
    font-weight: 600;
    margin-bottom: 0; }

.webform-client-form .captcha {
  *zoom: 1;
  display: block;
  width: 100%;
  clear: both; }
  .webform-client-form .captcha:before, .webform-client-form .captcha:after {
    content: " ";
    display: table; }
  .webform-client-form .captcha:after {
    clear: both; }

.webform-client-form fieldset .form-item {
  padding: 0; }

.webform-client-form.preview .webform-page {
  border-bottom: 1px solid #efefef;
  padding-bottom: 40px;
  text-indent: -9999px;
  display: none; }

.webform-client-form .webform-component-radios.inline .form-type-radio {
  float: left;
  width: auto;
  margin: 0 15px 0 0; }

.webform-client-form .webform-component-radios .form-type-radio label {
  font-weight: normal; }

.webform-client-form .webform-component-checkboxes .form-type-checkbox label {
  font-weight: normal; }

.webform-client-form .webform-component-file .description {
  float: left;
  width: 100%;
  margin: 10px 0 0;
  color: #707A80; }

.webform-client-form .webform-component-file input[type='submit'] {
  float: left;
  width: 106px;
  clear: both;
  background: #707A80;
  margin: 10px 0 0; }

.webform-client-form .webform-layout-box {
  margin: 0 -15px;
  clear: both; }
  .webform-client-form .webform-layout-box > .form-item {
    padding: 0 15px; }
    @media screen and (max-width: 768px) {
      .webform-client-form .webform-layout-box > .form-item {
        width: auto;
        float: none; } }

.webform-client-form .webform-container-inline {
  /*
		input, select, .form-textarea-wrapper {
			width:60%;
			float: left;
			display: block;

			@include breakpoint(medium) {
				float: none;
				width:100%;
			}
		}*/ }
  .webform-client-form .webform-container-inline > label {
    margin: 0;
    width: 40%;
    float: left;
    padding-right: 20px; }
  .webform-client-form .webform-container-inline.webform-component-textfield input {
    width: 60%;
    float: right; }
  .webform-client-form .webform-container-inline > .form-radios,
  .webform-client-form .webform-container-inline > .form-textarea-wrapper,
  .webform-client-form .webform-container-inline > input,
  .webform-client-form .webform-container-inline > .form-checkboxes {
    width: 60%;
    float: right; }
  .webform-client-form .webform-container-inline div.description {
    display: block;
    float: left;
    width: 100%; }
  .webform-client-form .webform-container-inline.beschrijving-links div.description {
    width: 40%;
    line-height: 20px; }

.webform-client-form .webform-component-fieldset {
  width: 50%;
  float: left;
  padding: 0 20px;
  border: none;
  margin: 0; }
  .webform-client-form .webform-component-fieldset .fieldset-legend {
    display: none; }
  .webform-client-form .webform-component-fieldset.links, .webform-client-form .webform-component-fieldset.webform-component--kolom-verdeling-1, .webform-client-form .webform-component-fieldset.webform-component--links, .webform-client-form .webform-component-fieldset.webform-component--links1 {
    padding-left: 0px !important; }
  .webform-client-form .webform-component-fieldset.rechts, .webform-client-form .webform-component-fieldset.webform-component--kolom-verdeling, .webform-client-form .webform-component-fieldset.webform-component--rechts, .webform-client-form .webform-component-fieldset.webform-component--rechts1 {
    padding-right: 0px !important; }
  @media screen and (max-width: 768px) {
    .webform-client-form .webform-component-fieldset {
      padding: 0 !important;
      width: auto;
      float: none; } }

.webform-client-form .btn, .webform-client-form .pager.pager--infinite-scroll a, .pager.pager--infinite-scroll .webform-client-form a, .webform-client-form .news-filter .views-exposed-widget a, .news-filter .views-exposed-widget .webform-client-form a,
.webform-client-form .form-submit {
  float: right;
  margin: 15px 0 0;
  -webkit-appearance: none;
  clear: none; }
  @media screen and (max-width: 768px) {
    .webform-client-form .btn, .webform-client-form .pager.pager--infinite-scroll a, .pager.pager--infinite-scroll .webform-client-form a, .webform-client-form .news-filter .views-exposed-widget a, .news-filter .views-exposed-widget .webform-client-form a,
    .webform-client-form .form-submit {
      margin: 15px 0; } }
  .webform-client-form .btn.webform-next, .webform-client-form .pager.pager--infinite-scroll a.webform-next, .pager.pager--infinite-scroll .webform-client-form a.webform-next, .webform-client-form .news-filter .views-exposed-widget a.webform-next, .news-filter .views-exposed-widget .webform-client-form a.webform-next,
  .webform-client-form .form-submit.webform-next {
    float: right;
    clear: none; }
  .webform-client-form .btn.webform-previous, .webform-client-form .pager.pager--infinite-scroll a.webform-previous, .pager.pager--infinite-scroll .webform-client-form a.webform-previous, .webform-client-form .news-filter .views-exposed-widget a.webform-previous, .news-filter .views-exposed-widget .webform-client-form a.webform-previous,
  .webform-client-form .form-submit.webform-previous {
    float: left;
    clear: none;
    background: #E9EAED; }

.webform-client-form .webform-confirmation {
  padding: 0 15px; }

.webform-client-form .ajax-progress {
  margin-left: 15px; }

.webform-client-form .throbber {
  background: url("../images/ajax-loader.gif");
  position: absolute;
  bottom: 0;
  height: 11px;
  width: 16px;
  margin: 2px;
  z-index: 10; }

.webform-client-form .error input,
.webform-client-form .error input:focus,
.webform-client-form .error textarea,
.webform-client-form .error textarea:focus,
.webform-client-form .error:focus,
.webform-client-form input.error,
.webform-client-form input.error:focus,
.webform-client-form textarea.error {
  background: #fff !important;
  border-color: #db0f48 !important; }

.block-webform .links,
.webform-client-form .links {
  float: left;
  padding: 0 15px; }

.node-type-webform .webform-confirmation {
  padding: 0 !important; }

.webform-client-form-44 {
  margin: 0; }
  .webform-client-form-44 .webform-component-markup h2 {
    margin: 30px 0 20px; }
  @media screen and (max-width: 768px) {
    .webform-client-form-44 .form-item {
      padding: 0; } }

.tooltip {
  background: #e3a26b;
  color: #fff;
  border-radius: 7px;
  display: block;
  font-size: 14px;
  margin: 0 0 16px;
  padding: 8px 24px;
  min-width: 200px;
  font-weight: 400;
  width: auto;
  position: absolute;
  top: 5px;
  left: 1px;
  transform: translateY(-100%); }
  .tooltip:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    background: #e3a26b;
    position: absolute;
    bottom: -4px;
    left: 12px; }

.sectie-item {
  float: left;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px; }
  .sectie-item .row {
    width: 984px;
    max-width: 100%;
    margin: 0 auto; }
    .sectie-item .row .column,
    .sectie-item .row .columns {
      padding: 0 20px !important; }
      @media screen and (max-width: 768px) {
        .sectie-item .row .column,
        .sectie-item .row .columns {
          padding: 20px !important; } }
  .sectie-item img {
    height: auto !important; }
  .sectie-item.achtergrond-afbeelding:first-child {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .sectie-item.achtergrond-afbeelding h1 {
    margin-top: 40px; }
  .sectie-item.geenmarge {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sectie-item.margetop {
    padding-bottom: 0 !important; }
  .sectie-item.margebottom {
    padding-top: 0 !important; }

.sectie-item.border, .sectie-item.border-bottom, .sectie-item.border-top,
.sectie-item .row.border,
.sectie-item .row.border-bottom,
.sectie-item .row.border-top {
  border-color: #eef0ec;
  border-style: solid;
  border-width: 0; }

.sectie-item.border,
.sectie-item .row.border {
  border-top-width: 1px !important;
  padding-top: 5em;
  border-bottom-width: 1px !important;
  padding-bottom: 5em; }

.sectie-item.border-top,
.sectie-item .row.border-top {
  border-top-width: 1px !important;
  padding-top: 5em; }

.sectie-item.border-bottom,
.sectie-item .row.border-bottom {
  border-bottom-width: 1px !important;
  padding-bottom: 5em; }

.fotoalbum-sectie {
  margin-top: 25px; }
  @media screen and (max-width: 768px) {
    .fotoalbum-sectie {
      width: 100%; } }
  .fotoalbum-sectie ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .fotoalbum-sectie ul li {
      padding: 0 10px;
      float: left;
      margin-bottom: 15px;
      width: 25%;
      list-style-type: none; }
      @media screen and (max-width: 1023px) {
        .fotoalbum-sectie ul li {
          width: 50%; } }
      @media screen and (max-width: 768px) {
        .fotoalbum-sectie ul li:nth-child(odd) {
          padding-left: 0px !important; }
        .fotoalbum-sectie ul li:nth-child(even) {
          padding-right: 0px !important; } }
      .fotoalbum-sectie ul li img {
        width: 100%; }
  .fotoalbum-sectie .foto-titel {
    float: left;
    width: 100%;
    text-align: center; }

.sectie-item .fotoalbum .content {
  width: 50% !important; }

.sectie-item .fotoalbum .content.col.nr1 {
  float: left; }

.sectie-item .view-fotoalbum .col {
  padding: 0; }

@media screen and (max-width: 768px) {
  .sectie-item .fotoalbum .content {
    width: 100% !important; } }

.entity-paragraphs-item {
  padding: 40px 0; }

.paragraphs-item-afbeeldingen .field-item {
  width: 50%;
  float: left;
  padding: 0 20px 20px; }
  .paragraphs-item-afbeeldingen .field-item img {
    float: left;
    width: 100%; }

/**
 * @file
 * Print styling
 *
 * We provide some sane print styling for Drupal using Zen's layout method.
 */
/**
 * By importing this CSS file as media 'all', we allow this print file to be
 * aggregated with other stylesheets, for improved front-end performance.
 */
@media print {
  a {
    /* Underline all links. */
    /* Don't underline header. */ }
    a:link, a:visited {
      text-decoration: underline !important; }
    a:link.header__site-link, a:visited.header__site-link {
      text-decoration: none !important; }
  /* Add visible URL after links. */
  #content {
    /* Add visible title after abbreviations. */
    /* Un-float the content. */
    float: none !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    color: #000;
    background-color: transparent !important;
    background-image: none !important; }
    #content a {
      /* Only display useful links. */ }
      #content a[href]:after {
        content: " (" attr(href) ")";
        font-weight: normal;
        font-size: 16px; }
      #content a[href^='#']:after, #content a[href^='javascript:']:after {
        content: ''; }
    #content abbr[title]:after {
      content: " (" attr(title) ")"; }
  /* Turn off any background colors or images. */
  #main,
  #page,
  body {
    color: #000;
    background-color: transparent !important;
    background-image: none !important; }
  /* Hide sidebars and nav elements. */
  #footer,
  #navigation,
  #skip-link,
  #toolbar,
  .action-links,
  .book-navigation,
  .breadcrumb,
  .feed-icons,
  .forum-topic-navigation,
  .links,
  .pager,
  .region-sidebar-first,
  .region-sidebar-second,
  .tabs {
    visibility: hidden;
    display: none; } }

.row:after, .language-switcher:after, .content:after, .cards .view-content:after, .page-zoeken .content form:after, .sectie-overview:after {
  content: '';
  display: block;
  clear: both; }

html {
  -webkit-font-smoothing: antialiased; }

body {
  font-size: 100%;
  margin: 0;
  background: #fff; }

body,
caption,
div,
p,
td,
th {
  color: #000;
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 1.55;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.2px; }
  @media screen and (max-width: 424px) {
    body,
    caption,
    div,
    p,
    td,
    th {
      font-size: 13px;
      line-height: 1.6; } }

strong {
  font-weight: 600; }

hr {
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  margin: 30px 0; }

p {
  padding: 0 0 15px;
  margin: 0; }
  p img[style*='left'] {
    margin: 0 20px 20px 0; }
  p img[style*='right'] {
    margin: 0 0 20px 20px; }
  p .rtecenter {
    text-align: center; }
  @media screen and (max-width: 768px) {
    p {
      font-size: 14px;
      line-height: 20px; } }

p.intro {
  font-weight: 600;
  font-size: 17px;
  line-height: 1.5;
  color: #000; }
  @media screen and (max-width: 768px) {
    p.intro {
      font-weight: 600;
      font-size: 15px;
      line-height: 1.5; } }

em {
  font-style: italic; }

a {
  color: #527145;
  text-decoration: none;
  font-weight: 400; }
  a:hover {
    color: #527145;
    text-decoration: underline; }

.content img {
  height: auto !important; }

.content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container):not(.pager) {
  list-style: disc;
  padding-left: 1.3em;
  margin-bottom: 1em; }
  .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container):not(.pager) p {
    display: inline; }

.content .item-list > ul li {
  list-style: none;
  margin-bottom: 20px;
  padding: 0 10px; }

.content ol:not(.flex-control-nav) {
  list-style-position: outside;
  margin: 0 0 10px 18px;
  list-style: decimal; }
  .content ol:not(.flex-control-nav) p {
    display: inline; }

.content table {
  width: 100% !important; }
  .content table th {
    text-align: left;
    padding: 0 0 5px 10px;
    font-weight: bold; }
  .content table tbody tr td {
    padding: 5px 7px;
    border: 1px solid #f0f0f0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Merriweather", "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-weight: 400;
  margin: .5em 0;
  line-height: 1.3;
  letter-spacing: 0.2px; }
  h1,
  h1 a,
  h2,
  h2 a,
  h3,
  h3 a,
  h4,
  h4 a,
  h5,
  h5 a,
  h6,
  h6 a {
    color: #527145; }
  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    margin-top: 0; }

h1 {
  font-size: 40px; }
  @media screen and (max-width: 424px) {
    h1 {
      font-size: 28px; } }

h2 {
  font-size: 28px; }
  @media screen and (max-width: 424px) {
    h2 {
      font-size: 21px; } }

h3 {
  font-size: 22px; }
  @media screen and (max-width: 424px) {
    h3 {
      font-size: 20px; } }

h4 {
  font-size: 20px; }
  @media screen and (max-width: 424px) {
    h4 {
      font-size: 18px; } }

h5 {
  font-size: 18px; }
  @media screen and (max-width: 424px) {
    h5 {
      font-size: 16px; } }

h6 {
  font-size: 16px; }
  @media screen and (max-width: 424px) {
    h6 {
      font-size: 16px; } }

blockquote {
  position: relative;
  padding: 0 0 0 5.5em;
  width: 100%;
  float: left;
  font-style: italic;
  font-weight: 400;
  margin: 10px 0;
  color: #396B8d;
  font-family: "Merriweather", "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-size: 15px;
  line-height: 23px; }
  blockquote p {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    font-style: inherit;
    font-family: inherit;
    margin: 0;
    padding: 0;
    color: inherit; }
  blockquote:before {
    color: #94b8d1;
    float: left;
    left: 0;
    top: 0;
    position: absolute;
    font-size: 5em; }
  .bg-dark blockquote {
    color: #fff; }
    .bg-dark blockquote:before {
      color: inherit;
      opacity: .3; }
  @media screen and (max-width: 768px) {
    blockquote {
      padding-left: 3.5em; }
      blockquote:before {
        font-size: 3em; } }

.bg-dark a,
.bg-dark blockquote,
.bg-dark blockquote p:before,
.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-dark ol,
.bg-dark p,
.bg-dark ul {
  color: #fff; }

.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-dark p {
  font-weight: 400; }

.bg-dark p.intro {
  font-weight: 600; }

.bg-dark blockquote {
  font-weight: 400; }

#user-login .form-submit,
.form-submit {
  background: #94b8d1;
  font-size: 17px;
  clear: both;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 20px;
  border: 0; }
  .rteright #user-login .form-submit, .rteright
  .form-submit {
    float: right; }
  .rteleft #user-login .form-submit, .rteleft
  .form-submit {
    float: left; }
  .rtecenter #user-login .form-submit, .rtecenter
  .form-submit {
    float: none;
    display: inline-block; }
  #user-login .form-submit:hover,
  .form-submit:hover {
    color: #fff;
    text-decoration: none;
    background: #e3a26b;
    cursor: pointer; }

.btn, .pager.pager--infinite-scroll a, .news-filter .views-exposed-widget a {
  display: inline-block;
  border-radius: 20px;
  background: #94b8d1;
  color: #fff;
  font-size: 20px;
  line-height: 1;
  min-width: 160px;
  text-align: center;
  padding: .5em 1.25em;
  cursor: pointer; }
  .btn.btn-small, .pager.pager--infinite-scroll a.btn-small, .news-filter .views-exposed-widget a.btn-small {
    font-size: 15px;
    min-width: 140px; }
    @media screen and (max-width: 768px) {
      .btn.btn-small, .pager.pager--infinite-scroll a.btn-small, .news-filter .views-exposed-widget a.btn-small {
        margin-bottom: 10px; } }
  .btn.btn-arrow, .pager.pager--infinite-scroll a.btn-arrow, .news-filter .views-exposed-widget a.btn-arrow {
    min-width: 0;
    padding: 0;
    border-radius: 50%; }
    .btn.btn-arrow:after, .pager.pager--infinite-scroll a.btn-arrow:after, .news-filter .views-exposed-widget a.btn-arrow:after {
      display: block;
      width: 26px;
      height: 26px;
      line-height: 27px;
      font-size: 16px; }
  .btn.btn-back, .pager.pager--infinite-scroll a.btn-back, .news-filter .views-exposed-widget a.btn-back {
    margin: 3em 0; }
    .btn.btn-back:after, .pager.pager--infinite-scroll a.btn-back:after, .news-filter .views-exposed-widget a.btn-back:after {
      margin-left: 1em; }
    @media screen and (max-width: 768px) {
      .btn.btn-back, .pager.pager--infinite-scroll a.btn-back, .news-filter .views-exposed-widget a.btn-back {
        min-width: 0;
        padding: .5em; }
        .btn.btn-back .btn-text, .pager.pager--infinite-scroll a.btn-back .btn-text, .news-filter .views-exposed-widget a.btn-back .btn-text {
          display: none; }
        .btn.btn-back:after, .pager.pager--infinite-scroll a.btn-back:after, .news-filter .views-exposed-widget a.btn-back:after {
          margin-left: 0; } }
  .btn:hover, .pager.pager--infinite-scroll a:hover, .news-filter .views-exposed-widget a:hover, .btn:active, .pager.pager--infinite-scroll a:active, .news-filter .views-exposed-widget a:active, .btn.active, .pager.pager--infinite-scroll a.active, .news-filter .views-exposed-widget a.active {
    color: #fff;
    text-decoration: none;
    background: #e3a26b; }
  .btn:active, .pager.pager--infinite-scroll a:active, .news-filter .views-exposed-widget a:active, .btn.active, .pager.pager--infinite-scroll a.active, .news-filter .views-exposed-widget a.active {
    background: #8dac7f; }

p:last-child {
  padding-bottom: 0; }

p.popup {
  padding: 0;
  line-height: 0; }

.cke_editable,
.cke_editable_themed {
  padding: 10px !important; }

.geolocation-map {
  margin: 0; }
