@import 'settings';
@import 'utils/mixins';
@import 'utils/placeholders';

@import url('https://fonts.googleapis.com/css?family=Merriweather:300,400|Source+Sans+Pro:200,400,600,700,900');

html {
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: 100%;
  margin: 0;
  background: $white;
}

body,
caption,
div,
p,
td,
th {
  color: $body-font-color;
  font-family: $body-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: $body-font-weight;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.2px;

  @include breakpoint(small) {
    font-size: $small-base-font-size;
    line-height: $small-base-line-height;
  }
}

strong {
  font-weight: $fw-semibold;
}

hr {
  border: 0;
  border-bottom: 1px solid $gray-light;
  margin: 30px 0;
}

p {
  padding: 0 0 15px;
  margin: 0;

  img[style*='left'] {
    margin: 0 20px 20px 0;
  }

  img[style*='right'] {
    margin: 0 0 20px 20px;
  }

  .rtecenter {
    text-align: center;
  }
  @include breakpoint(medium) {
    font-size: 14px;
    line-height: 20px;
  }
}

// Intro paragraaf
p.intro {
  font-weight: $text-intro-weight;
  font-size: $text-intro-size;
  line-height: $text-intro-line-height;
  color: $text-intro-color;

  @include breakpoint(medium) {
    font-weight: $small-text-intro-weight;
    font-size: $small-text-intro-size;
    line-height: $small-text-intro-line-height;
  }
}

em {
  font-style: italic;
}

a {
  color: $link-color;
  text-decoration: $link-text-decoration;
  font-weight: $link-text-weight;

  &:hover {
    color: $link-color-hover;
    text-decoration: $link-text-hover-decoration;
  }
}

// CONTENT STIJLEN
.content {
  img {
    height: auto !important;
  }

  ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container):not(.pager) {
    // @extend %ul-caret-list;
    list-style: disc;
    padding-left: 1.3em;

    margin-bottom: 1em;

    p {
      display: inline;
    }
  }

  .item-list > ul li {
    list-style: none;
    margin-bottom: $column-gutter / 2;
    padding: 0 $column-gutter / 4;
  }

  ol:not(.flex-control-nav) {
    list-style-position: outside;
    margin: 0 0 10px 18px;
    list-style: decimal;

    p {
      display: inline;
    }
  }

  table {
    width: 100% !important;

    th {
      text-align: left;
      padding: 0 0 5px 10px;
      font-weight: bold;
    }

    tbody tr td {
      padding: 5px 7px;
      border: 1px solid $gray-light;
    }
  }
}

// Headers
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font-family;
  font-weight: $header-font-weight;
  margin: .5em 0;
  line-height: $header-line-height;
  letter-spacing: 0.2px;

  &,
  & a {
    color: $header-font-color;
  }

  &:first-child {
    margin-top: 0;
  }
}

// Set headers size (h1-h6)
// See settings/_typography.scss for sizes
@for $i from 1 through length($header-size) {
  $_el: h#{$i};
  $_size: map-get($header-size, $_el);
  $_size-small: map-get($small-header-size, $_el);

  #{$_el} {
    font-size: #{$_size}px;
    @include breakpoint(small) {
      font-size: #{$_size-small}px;
    }
  }
}

// Blockquote
blockquote {
  position: relative;
  padding: 0 0 0 5.5em;
  width: 100%;
  float: left;
  font-style: italic;
  font-weight: $fw-light;
  margin: 10px 0;
  color: $blue-dark;
  font-family: $alternative-font;
  font-size: 15px;
  line-height: 23px;

  p {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    font-style: inherit;
    font-family: inherit;
    margin: 0;
    padding: 0;
    color: inherit;

  }

  &:before {
    @include extend-icon('quote');

    color: $secondary-color;
    float: left;
    left: 0;
    top: 0;
    position: absolute;
    font-size: 5em;
  }

  .bg-dark & {
    color: $white;

    &:before {
      color: inherit;
      opacity: .3;
    }
  }

  @include breakpoint(medium) {
    padding-left: 3.5em;

    &:before {
      font-size: 3em;
    }
  }
}

.bg-dark {
  a,
  blockquote,
  blockquote p:before,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ol,
  p,
  ul {
    color: $white;
  }

  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-weight: $fw-regular;
  }

  p.intro {
    font-weight: $fw-semibold;
  }

  blockquote {
    font-weight: $fw-light;
  }
}

#user-login .form-submit,
.form-submit {
  background: $button-color;
  font-size: 17px;
  clear: both;
  font-weight: 400;
  color: $white;
  text-decoration: none;
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: $button-border-radius;

  //float: left;
  border: 0;

  .rteright & {
    float: right;
  }

  .rteleft & {
    float: left;
  }

  .rtecenter & {
    float: none;
    display: inline-block;
  }

  &:hover {
    color: $white;
    text-decoration: none;
    background: $button-color-hover;
    cursor: pointer;
  }
}

.btn {
  display: inline-block;
  border-radius: $button-border-radius;
  background: $button-color;
  color: $white;
  font-size: 20px;
  line-height: 1;
  min-width: 160px;
  text-align: center;
  padding: .5em 1.25em;
  cursor: pointer;

  &.btn-small {
    font-size: 15px;
    min-width: 140px;
    @include breakpoint(medium) {
      margin-bottom: 10px;
    }
  }

  &.btn-arrow {
    min-width: 0;
    padding: 0;
    border-radius: 50%;

    &:after {
      display: block;
      width: 26px;
      height: 26px;
      line-height: 27px;
      font-size: 16px;

      @include extend-icon('arrow-right');
    }
  }

  &.btn-back {
    margin: 3em 0;

    &:after {
      margin-left: 1em;
      @include extend-icon('back');
    }

    @include breakpoint(medium) {
      min-width: 0;
      padding: .5em;

      .btn-text {
        display: none;
      }

      &:after {
        margin-left: 0;
      }
    }
  }

  &:hover,
  &:active,
  &.active {
    color: $white;
    text-decoration: none;
    background: $button-color-hover;
  }

  &:active,
  &.active {
    background: $button-color-active;
  }
}


p {
  &:last-child {
    padding-bottom: 0;
  }

  &.popup {
    padding: 0;
    line-height: 0;
  }
}

// Diversen
.cke_editable,
.cke_editable_themed {
  padding: 10px !important;
}

.geolocation-map {
  margin: 0;
}
