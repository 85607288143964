$form-spacing: 16px !default;

$label-font-weight: 500 !default;
$label-font-color: lighten($black, 30%) !default;
$label-bottom-margin: 3px !default;

$input-font-color: rgba(0, 0, 0, .75) !default;
$input-font-size: 14px !default;
$input-bg-color: #fff !default;
$input-focus-bg-color: $white !default;
$input-border-color: darken($white, 20%) !default;
$input-focus-border-color: $blue-blue !default;
$input-focus-shadow: $secondary-color 0 0 6px 0 !default;
$input-border-style: solid !default;
$input-border-width: 1px !default;
$input-border-radius: 7px !default;
$button-border-radius: 20px !default;

$fieldset-border-radius: 3px !default;
