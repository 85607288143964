.card {
  background-color: $white;
  border: 0;
  overflow: hidden;
  padding-bottom: 2em;
  margin-bottom: $column-gutter;
  display: block;
  color: $text-color;
  position: relative;
  transform: translate3d(0, 0, 0);

  @include transition(transform, box-shadow);

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    padding: 0;
  }

  ul {
    margin: 1em !important;

    li {
      margin: 0 !important;
      white-space: nowrap;

      a {
        white-space: normal;
      }
    }
  }

  .card-thumbnail {
    width: 100%;
    height: 150px;
    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: -3.8em;

    @include breakpoint(medium) {
      margin-bottom: -4.3em;
    }

    &.no-img {
      height: 0;
    }

    .card-thumbnail-image {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      display: block;
      overflow: hidden;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transform: translate3d(0, 0, -1) scale(1, 1);

      @include transition(transform);

      .background {
        display: none;
      }
    }
  }

  .card-date {
    display: inline-block;
    background-color: $white;
    padding: 0 9px;
    line-height: 30px;
    margin: 15px 9px;
    font-size: 13px;
    color: $secondary-color;
    font-weight: $fw-semibold;
    border: 1px solid $gray;
    position: relative;
    z-index: 2;

    em {
      font-style: normal;
    }
  }

  .card-body,
  .card-title {
    margin: 1em;
    display: block;
    line-height: 1.3;
    color: initial;
  }

  .card-image {
    margin-bottom: -3.8em;
    overflow: hidden;

    &,
    img {
      display: block;
    }

    img {
      transform: scale(1);
      backface-visibility: hidden;

      @include transition(transform);
    }
  }

  .card-title {
    color: $secondary-color;
    font-weight: $fw-regular;
  }

  .card-body p {
    padding: 0;
    margin: 0;
  }

  .card-label {
    display: block;
    position: absolute;
    bottom: 0;
    left: 10px;
    padding: 0 10px;
    line-height: 25px;
    background-color: $secondary-color;
    color: $white;
    font-size: 13px;
  }

  &:hover {
    text-decoration: none;
    transform: translate(-5px, -5px);
    box-shadow: 5px 5px 25px 0 rgba(100, 100, 100, .5);

    .card-title {
      text-decoration: underline;
    }

    .card-thumbnail-image {
      transform: scale(1.1);
    }
  }
}

.cards {
  .view-header h2 {
    width: calc(100% - 26px);
    display: inline-block;
  }

  .view-content {
    @extend %clearfix;
    margin: 0 ($column-gutter / -2);

    @include breakpoint(small) {
      .front & {
        white-space: nowrap;
        overflow-x: hidden;

        .card {
          white-space: normal;
          margin-bottom: 0;
        }
      }
    }
  }
}

.card-wrapper {
  width: 100 / 3 * 1%;
  float: left;
  padding: 0 ($column-gutter / 2);

  @include breakpoint(small) {
    .front & {
      width: 100%;
      float: none;
      display: none;

      &:first-child {
        display: block;
      }
    }
  }
}
