//================================================
//=== WEBFORM ====================================
//================================================
.webform-progressbar {

  margin-left: 17px;
  margin-bottom: 20px;
  margin-top: 30px;
  display: inline-block;

  .webform-progressbar-inner {
    border-color: #2b2b2b;
    background-color: #2b2b2b;
  }

  .webform-progressbar-page {
    border: 1px solid #2b2b2b;
  }

  .webform-progressbar-page.current {
    background-color: #2b2b2b;
  }

  .webform-progressbar-page.completed {
    background-color: #2b2b2b;
  }

  .webform-progressbar-outer {
    border: 1px solid #2b2b2b;
  }
}

.webform-client-form {
  input[type=date],
  input[type=datetime],
  input[type=email],
  input[type=number],
  input[type=password],
  input[type=search],
  input[type=tel],
  input[type=text],
  input[type=time],
  input[type=url],
  textarea {
    margin: 0;
  }

  .form-item {
    margin: 0 0 10px;
    float: left;
    width: 100%;
    position: relative;

    .form-item {
      margin: 0;
    }

    label {
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  .captcha {
    @include clearfix();
    display: block;
    width: 100%;
    clear: both;
  }

  fieldset .form-item {
    padding: 0;
  }

  &.preview .webform-page {
    border-bottom: 1px solid #efefef;
    padding-bottom: 40px;
    text-indent: -9999px;
    display: none;
  }

  // Type: radio
  .webform-component-radios {
    &.inline {
      .form-type-radio {
        float: left;
        width: auto;
        margin: 0 15px 0 0;
      }
    }

    .form-type-radio label {
      font-weight: normal;
    }
  }

  // Type: checkbox
  .webform-component-checkboxes {
    .form-type-checkbox label {
      font-weight: normal;
    }
  }

  // Type: file
  .webform-component-file {
    .description {
      float: left;
      width: 100%;
      margin: 10px 0 0;
      color: #707A80;
    }

    input[type='submit'] {
      float: left;
      width: 106px;
      clear: both;
      background: #707A80;
      margin: 10px 0 0;
    }
  }

  // Layout: Layout Box
  .webform-layout-box {
    margin: 0 -15px;
    clear: both;
    //float:left;

    > .form-item {
      padding: 0 15px;
      @include breakpoint(medium) {
        width: auto;
        float: none;
      }
    }
  }

  // Inline velden
  .webform-container-inline {
    > label {
      margin: 0;
      width: 40%;
      float: left;
      padding-right: 20px;
    }

    &.webform-component-textfield {
      input {
        width: 60%;
        float: right;
      }
    }

     > .form-radios,
     > .form-textarea-wrapper,
     > input,
    > .form-checkboxes {
      width: 60%;
      float: right;
    }

    div.description {
      display: block;
      float: left;
      width: 100%;
    }

    &.beschrijving-links div.description {
      width: 40%;
      line-height: 20px;
    }
    /*
		input, select, .form-textarea-wrapper {
			width:60%;
			float: left;
			display: block;

			@include breakpoint(medium) {
				float: none;
				width:100%;
			}
		}*/
  }

  // Veldengroep
  .webform-component-fieldset {
    width: 50%;
    float: left;
    padding: 0 ($column-gutter/2);
    border: none;
    margin: 0;

    //&:nth-child(even) { padding-right: 0; }
    //&:nth-child(odd) { padding-left: 0; }

    .fieldset-legend {
      display: none;
    }

    // Links en Rechts
    &.links,
    &.webform-component--kolom-verdeling-1,
    &.webform-component--links,
    &.webform-component--links1 {
      padding-left: 0px !important;
    }

    &.rechts,
    &.webform-component--kolom-verdeling,
    &.webform-component--rechts,
    &.webform-component--rechts1 {
      padding-right: 0px !important;
    }
    @include breakpoint(medium) {
      padding: 0 !important;
      width: auto;
      float: none;
    }
  }

  // Submit
  .btn,
  .form-submit {

    float: right;
    margin: 15px 0 0;
    @include breakpoint(medium) {
      margin: 15px 0;
    }
    -webkit-appearance: none;
    clear: none;

    &.webform-next {
      float: right;
      clear: none;
    }

    &.webform-previous {
      float: left;
      clear: none;
      background: #E9EAED;
    }
  }

  //Voltooid bericht
  .webform-confirmation {
    padding: 0 15px;
  }

  //AJAX loader
  .ajax-progress {
    margin-left: 15px;
  }

  .throbber {
    background: url('../images/ajax-loader.gif');
    position: absolute;
    bottom: 0;
    height: 11px;
    width: 16px;
    margin: 2px;
    z-index: 10;
  }

  // Error
  .error input,
  .error input:focus,
  .error textarea,
  .error textarea:focus,
  .error:focus,
  input.error,
  input.error:focus,
  textarea.error {
    background: $white!important;
    border-color: #db0f48!important;
  }
}

.block-webform .links,
.webform-client-form .links {
  float: left;
  padding: 0 15px;
}

.node-type-webform .webform-confirmation {
  padding: 0 !important;
}

.webform-client-form-44 {
  margin: 0;

  .webform-component-markup {
    h2 {
      margin: 30px 0 20px;
    }
  }

  .form-item {
    @include breakpoint(medium) {
      padding: 0;
    }
  }
}

.tooltip {
  background: $orange;
  color: $white;
  border-radius: $input-border-radius;
  display: block;
  font-size: $input-font-size;
  margin: 0 0 $form-spacing;
  padding: ($form-spacing / 2) ($form-spacing * 1.5);
  min-width: 200px;
  font-weight: $fw-regular;
  width: auto;
  position: absolute;
  top: 5px;
  left: 1px;
  transform: translateY(-100%);

  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    background: $orange;
    position: absolute;
    bottom: -4px;
    left: 12px;
  }
}
