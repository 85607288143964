//================================================
//=== SLIDER =====================================
//================================================
.flexslider {
// .flexslider-external {
  // margin: 0 0 20px;
  margin: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;

  ol,
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    padding-left: 0;
    -webkit-backface-visibility: visible!important;
  }

  .slides > li {
    overflow: hidden;
  }

  .row {
    position: relative;
    margin: 0 auto;
    // max-width: $row-width;
  }

  //Caption
  .flex-caption {
    position: absolute;
    border: 0;
    bottom: 100px;

    .caption-title-1 {
      font-size: 60px;
      font-weight: $fw-black;
      font-family: $default-font;
      color: $white;
      line-height: 1.2;

      @include breakpoint(medium) {
        font-size: 30px;
      }
      // @include breakpoint(small) {
      //   font-size: 26px;
      // }
    }

    .caption-title-2 span {
      font-size: 50px;
      font-weight: $fw-light;
      font-family: $alternative-font;
      color: $primary-color;
      background-color: $white;
      padding: 0 .5em;
      margin-left: 1em;
      line-height: 1.5;
      display: inline-block;

      @include breakpoint(medium) {
        font-size: 24px;
      }
      // @include breakpoint(small) {
      //   font-size: 20px;
      // }
    }

    //Caption posities
    &.bottom-left {
      left: $column-gutter / 2;
      right: auto;
    }

    &.bottom-right {
      right: $column-gutter / 2;
      left: auto;

      .caption-title-1,
      .caption-title-2 {
        text-align: right;
      }

      .caption-title-2 span {
        margin-left: 0;
        margin-right: 1em;
      }
    }
  }

  //Bolletjes navigatie
  .flex-control-nav {
    z-index: 99;

    li {
      margin: 0 4px;

      a {
        width: 9px;
        height: 9px;
        background: $white;
        opacity: .5;

        @include transition(opacity);

        &.flex-active {
          // background: $white;
          opacity: 1;
        }
      }
    }

  }

  // Pijltjes navigatie
  .flex-direction-nav {
    display: none !important;

    a {
      color: $white;
      top: 0;
      height: 100%;
      margin: 0;
      width: 60px;

      &:before {
        top: 50%;
        position: absolute;
        margin-top: -32px;
      }
    }

    .flex-next {
      right: 0;

      &:before {
        right: 0;
      }
    }

    .flex-prev {
      left: 0;

      &:before {
        left: 0;
      }
    }
  }

  .bg-caption,
  .color-caption {
    display: none;
  }
}

@include breakpoint(large) {
  .flexslider {
    .flex-caption {
      font-size: 20px;
      line-height: 20px;
      padding: 15px;
    }
  }
}

@include breakpoint(small) {
  .flexslider {
    .flex-caption {
      width: 100%;
      bottom: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 25px;
      right: auto!important;
      left: auto!important;
      border-radius: 0!important;
    }

    .slides img {
      width: auto;
      max-width: none;
      height: 300px;
      transform: translateX(-50%);
      position: relative;
      left: 50%;
    }

    .flex-direction-nav a:before {
      top: 30%;
    }
  }
}
